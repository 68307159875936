import { Box, styled } from '@mui/material';

export const EmotionCardWrapper = styled(Box)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    padding: '32px 20px',
    minWidth: '38vw',
    borderRadius: '10px',
    backgroundColor: theme.palette.semantic.theme.containerFirst,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    ...(selected && {
      boxShadow: '0px 0px 8px 0px rgba(255, 255, 255, 0.80)',
    }),
  })
);
