export const POST_SURVEY_CONSTANT = [
  {
    id: 'bedtime',
    title: '몇 시에 주무셨나요?',
    type: 'time',
    detailMessage: '오전 오후/ 시/분 입력',
  },
  {
    id: 'wake_up_time',
    title: '몇 시에 기상하셨나요?',
    type: 'time',
    detailMessage: '오전 오후/ 시/분 입력',
  },
  {
    id: 'sleep_quality',
    title: '지난 밤, 전반적으로 잘 주무셨나요?',
    type: 'likert',
    likertItems: [
      {
        id: 'sleep_quality_-5',
        name: '-5점',
        value: -5,
        description: '전혀 잠을 못 잤어요',
      },
      {
        id: 'sleep_quality_-4',
        name: '-4점',
        value: -4,
        description: '아주 못 잤어요',
      },
      {
        id: 'sleep_quality_-3',
        name: '-3점',
        value: -3,
        description: '많이 못 잤어요',
      },
      {
        id: 'sleep_quality_-2',
        name: '-2점',
        value: -2,
        description: '꽤 못 잤어요',
      },
      {
        id: 'sleep_quality_-1',
        name: '-1점',
        value: -1,
        description: '조금 못 잤어요',
      },
      {
        id: 'sleep_quality_0',
        name: '0점',
        value: 0,
        description: '보통이었어요',
      },
      {
        id: 'sleep_quality_1',
        name: '+1점',
        value: 1,
        description: '조금 잘 잤어요',
      },
      {
        id: 'sleep_quality_2',
        name: '+2점',
        value: 2,
        description: '꽤 잘 잤어요',
      },
      {
        id: 'sleep_quality_3',
        name: '+3점',
        value: 3,
        description: '많이 잘 잤어요',
      },
      {
        id: 'sleep_quality_4',
        name: '+4점',
        value: 4,
        description: '아주 잘 잤어요',
      },
      {
        id: 'sleep_quality_5',
        name: '+5점',
        value: 5,
        description: '최고로 잘 잤어요',
      },
    ],
  },
  {
    id: 'sleep_continuity',
    title: '지난 밤, 잠에서 잘 깨지 않고 계속 주무셨나요?',
    type: 'likert',
    likertItems: [
      {
        id: 'sleep_continuity_-5',
        name: '-5점',
        value: -5,
        description: '셀 수 없이 많이 깼어요',
      },
      {
        id: 'sleep_continuity_-4',
        name: '-4점',
        value: -4,
        description: '자주 깼어요',
      },
      {
        id: 'sleep_continuity_-3',
        name: '-3점',
        value: -3,
        description: '몇 시간마다 깼어요',
      },
      {
        id: 'sleep_continuity_-2',
        name: '-2점',
        value: -2,
        description: '여러 번 깼어요',
      },
      {
        id: 'sleep_continuity_-1',
        name: '-1점',
        value: -1,
        description: '가끔 깼어요',
      },
      {
        id: 'sleep_continuity_0',
        name: '0점',
        value: 0,
        description: '간간이 깼어요',
      },
      {
        id: 'sleep_continuity_1',
        name: '+1점',
        value: 1,
        description: '한두 번 깼어요',
      },
      {
        id: 'sleep_continuity_2',
        name: '+2점',
        value: 2,
        description: '거의 안 깼어요',
      },
      {
        id: 'sleep_continuity_3',
        name: '+3점',
        value: 3,
        description: '많이 잘 잤어요',
      },
      {
        id: 'sleep_continuity_4',
        name: '+4점',
        value: 4,
        description: '매우 잘 잤어요',
      },
      {
        id: 'sleep_continuity_5',
        name: '+5점',
        value: 5,
        description: '최고로 잘 잤어요, 한 번도 안 깼어요',
      },
    ],
  },
  {
    id: 'sleep_recovery',
    title: '지난 밤, 잠에서 깨신 후 어떠셨나요?',
    type: 'likert',
    likertItems: [
      {
        id: 'sleep_recovery_-5',
        name: '-5점',
        value: -5,
        description: '밤새 깨어 있었어요',
      },
      {
        id: 'sleep_recovery_-4',
        name: '-4점',
        value: -4,
        description: '다시 잠들기까지 매우 어려웠어요',
      },
      {
        id: 'sleep_recovery_-3',
        name: '-3점',
        value: -3,
        description: '다시 잠들기까지 오랜 시간이 걸렸어요',
      },
      {
        id: 'sleep_recovery_-2',
        name: '-2점',
        value: -2,
        description: '다시 잠들기까지 꽤 힘들었어요',
      },
      {
        id: 'sleep_recovery_-1',
        name: '-1점',
        value: -1,
        description: '다시 잠들기까지 조금 힘들었어요',
      },
      {
        id: 'sleep_recovery_0',
        name: '0점',
        value: 0,
        description: '다시 잠들기까지 약간 시간이 걸렸어요',
      },
      {
        id: 'sleep_recovery_1',
        name: '+1점',
        value: 1,
        description: '비교적 빨리 다시 잠들었어요',
      },
      {
        id: 'sleep_recovery_2',
        name: '+2점',
        value: 2,
        description: '꽤 빨리 다시 잠들었어요',
      },
      {
        id: 'sleep_recovery_3',
        name: '+3점',
        value: 3,
        description: '많이 빨리 다시 잠들었어요',
      },
      {
        id: 'sleep_recovery_4',
        name: '+4점',
        value: 4,
        description: '매우 빨리 다시 잠들었어요',
      },
      {
        id: 'sleep_recovery_5',
        name: '+5점',
        value: 5,
        description: '곧바로 다시 잠들었어요',
      },
    ],
  },
  {
    id: 'sleep_onset',
    title: '지난 밤, 테라피 사용 얼마 후에 잠드셨나요?',
    type: 'radio',
    radioItems: [
      { id: 'sleep_onset_1', name: '바로 잠들었어요', value: 1 },
      { id: 'sleep_onset_2', name: '5분 정도 걸렸어요', value: 2 },
      { id: 'sleep_onset_3', name: '10분 이상 걸렸어요', value: 3 },
      { id: 'sleep_onset_4', name: '30분은 안걸렸어요', value: 4 },
      { id: 'sleep_onset_5', name: '60분 가까이 걸렸어요', value: 5 },
      { id: 'sleep_onset_6', name: '60분 이상 걸렸어요', value: 6 },
    ],
  },
  {
    id: 'sleep_depth',
    title: '지난 밤, 얼마나 깊이 잠드셨나요?',
    type: 'likert',
    likertItems: [
      {
        id: 'sleep_depth_-5',
        name: '-5점',
        value: -5,
        description: '거의 잠들지 않은 것 같아요.',
      },
      {
        id: 'sleep_depth_-4',
        name: '-4점',
        value: -4,
        description: '잠이 매우 얕았어요',
      },
      {
        id: 'sleep_depth_-3',
        name: '-3점',
        value: -3,
        description: '잠이 얕았어요',
      },
      {
        id: 'sleep_depth_-2',
        name: '-2점',
        value: -2,
        description: '잠이 꽤 얕았어요',
      },
      {
        id: 'sleep_depth_-1',
        name: '-1점',
        value: -1,
        description: '잠이 조금 얕았어요',
      },
      {
        id: 'sleep_depth_0',
        name: '0점',
        value: 0,
        description: '그닥 깊이 잠들지는 않았어요',
      },
      {
        id: 'sleep_depth_1',
        name: '+1점',
        value: 1,
        description: '비교적 깊이 잠들었어요',
      },
      {
        id: 'sleep_depth_2',
        name: '+2점',
        value: 2,
        description: '꽤 깊이 잠들었어요',
      },
      {
        id: 'sleep_depth_3',
        name: '+3점',
        value: 3,
        description: '많이 깊이 잠들었어요',
      },
      {
        id: 'sleep_depth_4',
        name: '+4점',
        value: 4,
        description: '매우 깊이 잠들었어요',
      },
      {
        id: 'sleep_depth_5',
        name: '+5점',
        value: 5,
        description: '아주 깊이 잠들었어요',
      },
    ],
  },
];
