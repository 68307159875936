export class AutomaticBreathingController {
  private initialInhale: number;
  private initialExhale: number;
  private currentInhale: number;
  private currentExhale: number;
  private isInhaling: boolean;
  private timeoutId: NodeJS.Timeout | null = null;
  private intervalId: NodeJS.Timeout | null = null;
  private onStateChangeCallback: ((isInhaling: boolean) => void) | null = null;
  private lastSwitchTime: number = 0;
  private inhaleCount: number = 0;
  private exhaleCount: number = 0;
  private targetBreathsPerMinute: number;
  private sessionDuration: number;
  private sessionStartTime: number = 0;
  private maxIncreaseDuration: number;
  private recentBreathRates: number[] = [];
  private isStabilized: boolean = false;

  constructor(
    sessionDuration: number,
    initialAvgInhale: number = 2000,
    initialAvgExhale: number = 3000,
    targetBreathsPerMinute: number = 5
  ) {
    this.sessionDuration = sessionDuration;
    this.maxIncreaseDuration =
      sessionDuration >= 30 ? 15 * 60 * 1000 : 5 * 60 * 1000;
    this.initialInhale = this.currentInhale = initialAvgInhale;
    this.initialExhale = this.currentExhale = initialAvgExhale;
    this.isInhaling = false;
    this.targetBreathsPerMinute = targetBreathsPerMinute;
  }

  updateAverages(newAvgInhale: number, newAvgExhale: number): void {
    console.log({ newAvgInhale, newAvgExhale });
    this.initialInhale = this.currentInhale = Math.max(newAvgInhale, 1000);
    this.initialExhale = this.currentExhale = Math.max(newAvgExhale, 1000);
    this.inhaleCount = 0;
    this.exhaleCount = 0;
    this.sessionStartTime = Date.now();
    this.recentBreathRates = [];
    this.isStabilized = false;
  }

  automaticBreathStart(
    onStateChangeCallback: (isInhaling: boolean) => void,
    startWithInhale: boolean = true
  ): void {
    this.onStateChangeCallback = onStateChangeCallback;
    this.isInhaling = startWithInhale;
    this.lastSwitchTime = Date.now();
    this.sessionStartTime = Date.now();

    if (this.onStateChangeCallback) {
      this.onStateChangeCallback(this.isInhaling);
    }

    this.scheduleNextBreath();
  }

  private scheduleNextBreath(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    const duration = this.isInhaling ? this.currentInhale : this.currentExhale;

    this.timeoutId = setTimeout(() => {
      this.switchState();
      this.scheduleNextBreath();
    }, duration);
  }

  private switchState(): void {
    this.isInhaling = !this.isInhaling;
    if (this.onStateChangeCallback) {
      this.onStateChangeCallback(this.isInhaling);
    }
    this.lastSwitchTime = Date.now();

    if (this.isInhaling) {
      this.inhaleCount++;
    } else {
      this.exhaleCount++;
    }

    this.adjustBreathDuration();
    this.checkBreathStabilization();
  }

  private adjustBreathDuration(): void {
    const elapsedTime = Date.now() - this.sessionStartTime;
    if (elapsedTime > this.maxIncreaseDuration) {
      return;
    }

    const k = 0.05;
    const c = 1;

    const newInhaleDuration = this.calculateNewDuration(
      this.initialInhale,
      this.inhaleCount,
      k,
      c
    );
    const newExhaleDuration = this.calculateNewDuration(
      this.initialExhale,
      this.exhaleCount,
      k,
      c
    );

    const targetSum = 60000 / this.targetBreathsPerMinute;
    const currentSum = newInhaleDuration + newExhaleDuration;

    if (currentSum <= targetSum) {
      this.currentInhale = newInhaleDuration;
      this.currentExhale = newExhaleDuration;
    } else {
      const ratio =
        this.currentInhale / (this.currentInhale + this.currentExhale);
      this.currentInhale = targetSum * ratio;
      this.currentExhale = targetSum * (1 - ratio);
    }

    this.updateRecentBreathRates();
  }

  private calculateNewDuration(
    initialDuration: number,
    count: number,
    k: number,
    c: number
  ): number {
    return initialDuration + Math.log(1 + k * Math.pow(count, c)) * 1000;
  }

  private updateRecentBreathRates(): void {
    const currentBreathRate = 60000 / (this.currentInhale + this.currentExhale);
    this.recentBreathRates.push(currentBreathRate);
    if (this.recentBreathRates.length > 10) {
      this.recentBreathRates.shift();
    }
  }

  private checkBreathStabilization(): void {
    if (this.recentBreathRates.length < 10) {
      return;
    }

    const mean =
      this.recentBreathRates.reduce((a, b) => a + b) /
      this.recentBreathRates.length;
    const variance =
      this.recentBreathRates.reduce((a, b) => a + Math.pow(b - mean, 2), 0) /
      this.recentBreathRates.length;
    const standardDeviation = Math.sqrt(variance);
    const coefficientOfVariation = standardDeviation / mean;

    this.isStabilized = coefficientOfVariation < 0.1;
  }

  stop(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    this.onStateChangeCallback = null;
  }

  isRunning(): boolean {
    return this.timeoutId !== null || this.intervalId !== null;
  }

  getBreathDurations(): { inhale: number; exhale: number } {
    return { inhale: this.currentInhale, exhale: this.currentExhale };
  }

  getBreathsPerMinute(): number {
    return 60000 / (this.currentInhale + this.currentExhale);
  }

  isBreathStabilized(): boolean {
    return this.isStabilized;
  }
}
