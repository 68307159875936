import moment from 'moment';
import apiManager from '../lib/api/apiManager';

type BreathingData = {
  created_at: string;
  interval: number;
  phase: number;
  mode: number;
};

export class BreathingSession {
  public static readonly INHALE_PHASE = 1;
  public static readonly EXHALE_PHASE = 2;
  public static readonly CONSCIOUS_BREATH = 0;
  public static readonly UNCONSCIOUS_BREATH = 1;

  private data: BreathingData[] = [];
  private sessionLength: number;
  private userId: string;
  private sessionId: string;
  private sessionTimeId?: NodeJS.Timeout;
  private newRecordToSendToServer: BreathingData[] = [];
  private pendingBreathData: BreathingData | undefined;

  constructor(sessionLength: number, userId: string, sessionId?: string) {
    this.sessionLength = sessionLength;
    this.userId = userId;
    this.sessionId = sessionId || '';
    this.sessionTimeId = undefined;
  }

  async startSession(sessionTimeoutCallback: () => void): Promise<void> {
    if (this.sessionTimeId) {
      clearTimeout(this.sessionTimeId);
    }
    const sessionTimeInMilliseconds = this.sessionLength * 60 * 1000;

    this.sessionTimeId = setTimeout(() => {
      sessionTimeoutCallback();
    }, sessionTimeInMilliseconds);
  }

  async endSession() {
    try {
      clearTimeout(this.sessionTimeId);
    } catch (error) {
      console.error('Error while ending session:', error);
    }
  }

  recordBreath(phase: number, mode: number, skipServer?: boolean): void {
    const newData: BreathingData = {
      created_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
      phase: phase,
      interval: 0,
      mode,
    };

    if (!this.pendingBreathData) {
      this.pendingBreathData = newData;
      return;
    }

    this.pendingBreathData.interval = moment(newData.created_at).diff(
      moment(this.pendingBreathData.created_at),
      'milliseconds'
    );

    this.newRecordToSendToServer.push(this.pendingBreathData);
    this.data.push(this.pendingBreathData);

    this.pendingBreathData = newData;

    if (this.data.length >= 18) {
      this.data.shift();
    }

    // 다음 유저 액션을 기다리게 하면 안되기에 async 로 처리하지 않음
    if (
      !skipServer &&
      this.newRecordToSendToServer.length === 2 &&
      this.sessionId
    ) {
      apiManager.insertSessionData(
        this.userId,
        this.sessionId,
        `${this.sessionLength}`,
        this.newRecordToSendToServer
      );

      this.newRecordToSendToServer = [];
    }
  }

  calculateAvg(): { avgInhale: number; avgExhale: number } {
    const relevantBreaths = this.data
      .filter(
        (breath) =>
          breath.mode === BreathingSession.CONSCIOUS_BREATH &&
          breath.interval >= 200
      )
      .slice(-5); // 최근 5회의 호흡 고려. 나머지 1개의 호흡은, pendingData에 interval 을 업데이트 한 후 추가.

    if (this.pendingBreathData) {
      this.pendingBreathData.interval = moment().diff(
        moment(this.pendingBreathData?.created_at)
      );
      relevantBreaths.push(this.pendingBreathData);
    }

    console.log({ relevantBreaths });

    const inhaleBreaths = relevantBreaths.filter(
      (breath) => breath.phase === BreathingSession.INHALE_PHASE
    );
    const exhaleBreaths = relevantBreaths.filter(
      (breath) => breath.phase === BreathingSession.EXHALE_PHASE
    );

    const calculateAvgForPhase = (breaths: typeof relevantBreaths) => {
      if (breaths.length === 0) return 0;
      const totalInterval = breaths.reduce(
        (sum, breath) => sum + breath.interval,
        0
      );
      return totalInterval / breaths.length;
    };

    const avgInhale = calculateAvgForPhase(inhaleBreaths) || 2000; // 기본값 2000ms
    const avgExhale = calculateAvgForPhase(exhaleBreaths) || 3000; // 기본값 3000ms

    return { avgInhale, avgExhale };
  }

  private calculateInterval(): number {
    if (this.data.length === 0) {
      return 0;
    }

    const previousBreathData = this.data[this.data.length - 1];
    const previousBreathTime = moment(previousBreathData.created_at);
    const thisBreathTime = moment();

    return thisBreathTime.diff(previousBreathTime, 'milliseconds');
  }
}
