import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import theme from '../styles/theme';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../hooks/auth/useUser';

const cloudEffect = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    20%, 80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
        visibility: hidden;
    }
`;

const textFadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
`;

const WelcomePage = () => {
  const { user } = useUser();
  const [fadeText, setFadeText] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fadeTimer = setTimeout(() => setFadeText(true), 2800);
    const navigationTimer = setTimeout(() => {
      if (user.access_count && user.access_count > 0) {
        navigate('/');
        return;
      }

      navigate('/onboarding');
    }, 4500);

    return () => {
      clearTimeout(fadeTimer);
      clearTimeout(navigationTimer);
    };
  }, [navigate, user.access_count]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Typography
        variant="title2"
        color="#fff"
        sx={{
          animation: fadeText ? `${textFadeOut} 1s ease-out forwards` : 'none',
        }}
      >
        환영합니다
      </Typography>
      <Typography
        sx={{
          mt: '16px',
          color: theme.palette.semantic.font.textSub,
          whiteSpace: 'pre-line',
          textAlign: 'center',
          animation: fadeText ? `${textFadeOut} 1s ease-out forwards` : 'none',
        }}
      >
        {'음악과 함께 호흡하고\n 깊은 휴식과 수면을 경험해보세요'}
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '-50%',
          right: '-50%',
          height: '200%',
          background:
            'radial-gradient(ellipse at center, rgba(137, 183, 198, 0.6) 0%, rgba(255, 255, 255, 0.2) 60%, rgba(255, 255, 255, 0) 70%)',
          filter: 'blur(40px)',
          animation: `${cloudEffect} 3s ease-in-out forwards`,
          transform: 'rotate(10deg)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '-50%',
          right: '-50%',
          height: '180%',
          background:
            'radial-gradient(ellipse at center, rgba(137, 183, 198, 0.4) 0%, rgba(255, 255, 255, 0.1) 70%, rgba(255, 255, 255, 0) 80%)',
          filter: 'blur(50px)',
          animation: `${cloudEffect} 3.5s ease-in-out forwards`,
          transform: 'rotate(-5deg)',
        }}
      />
    </Box>
  );
};

export default WelcomePage;
