import React, { useEffect, useState } from 'react';
import AppHeaderBar from 'component/new/AppHeaderBar/AppHeaderBar';
import { Box, Typography } from '@mui/material';
import { ReactComponent as LeftArrow } from 'assets/icon/arrow_left.svg';
import { ReactComponent as ArrowBack } from 'assets/icon/arrow-back.svg';
import { ReactComponent as ArrowForward } from 'assets/icon/arrow-forward.svg';
import Button from '../../component/new/Button/Button';
import { useNavigate } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PageContainer } from '../../styles/globalStyled/PageContainer';
import ModePracticeDrawer from './_components/ModePracticeDrawer/ModePracticeDrawer';
import { TouchModeName } from '../../../types/model/inputMode.type';
import { getSearchPram } from '../../utils/getSearchPram';
import apiManager from '../../lib/api/apiManager';
import { useUser } from '../../hooks/auth/useUser';
import CircleNumber from './_components/CircleNumber';

const modes: {
  title: string;
  videoSrc: string;
  description: string;
  mode: TouchModeName;
}[] = [
  {
    title: '들이쉬며 누르기',
    videoSrc: '/videos/inhale-press.mp4',
    description: '호흡에 맞춰 화면을 지그시 누르며\n 진행하는 방식이에요',
    mode: 'inhale-press',
  },
  {
    title: '내쉬며 누르기',
    videoSrc: '/videos/inhale-release.mp4',
    description: '숨을 내쉴 때 화면을 눌러\n 호흡 주기를 입력하는 방식이에요',
    mode: 'exhale-press',
  },
  {
    title: '호흡하며 움직이기',
    videoSrc: '/videos/inhale-slide.mp4',
    description:
      '호흡에 맞춰 화면을 드래그해\n 호흡 주기를 입력하는 방식이에요',
    mode: 'inhale-slide-up',
  },
];

const InputModeSelectPage = () => {
  const { user, setUser } = useUser();
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const [{ isPracticeOpen, practiceMode }, setPracticeModalState] = useState<{
    isPracticeOpen: boolean;
    practiceMode?: TouchModeName;
  }>({
    isPracticeOpen: false,
    practiceMode: undefined,
  });

  const [selectedSlide, setSelectedSlide] = useState<undefined | number>();

  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '6px',
    className: 'center',
    arrows: true,
    prevArrow: <ArrowBack />,
    nextArrow: <ArrowForward />,
    beforeChange(currentSlide: number, nextSlide: number) {
      setCurrentSlide(nextSlide);
    },
  };

  const cameFrom = getSearchPram('from');

  const onSelectSlide = (currentSlide: number) => {
    setSelectedSlide(currentSlide);
  };

  const saveTouchMode = async (currentSlide: number) => {
    const touchMode = currentSlide + 1;

    try {
      await apiManager.updateUserInfo({
        userId: user?.user_id || '',
        touch_mode: touchMode,
      });

      setUser({ ...user, touch_mode: touchMode });

      navigate('/mypage', { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedSlide === undefined && user.touch_mode) {
      setSelectedSlide(user.touch_mode - 1);
    }
  }, [user.touch_mode]);

  return (
    <PageContainer>
      <AppHeaderBar left={<LeftArrow onClick={() => navigate(-1)} />} />

      <Typography
        margin="16px 0"
        variant="title3"
        whiteSpace="pre-line"
        color="#fff"
      >
        {'의식모드를 진행할 방법을\n 선택해주세요'}
      </Typography>

      <Typography
        variant="body1"
        color={(theme) => theme.palette.semantic.font.textSub}
      >
        테스트를 해보고 가장 편안한 방법을 골라주세요
      </Typography>

      <Box
        sx={{
          margin: '38px -24px 0',
          padding: '0 24px',
          position: 'relative', // 화살표의 위치 기준점
          '.slick-slide': {
            padding: '0 8px',
          },
          '.slick-list': {
            overflow: 'visible',
          },
          '.slick-track': {
            display: 'flex',
            gap: '0',
          },
          '.slick-arrow': {
            zIndex: 1,
          },
          '.slick-prev': {
            left: '-8px',
            visibility: currentSlide === 0 ? 'hidden' : 'visible',
          },
          '.slick-next': {
            right: '-8px',
            visibility:
              currentSlide === modes.length - 1 ? 'hidden' : 'visible',
          },
          '.slick-dots': {
            bottom: '-36px', // dot 의 위치
            '& li': {
              width: '10px',
              height: '10px',
              margin: '0 5px', // dot 간 간격
            },
            '& li button': {
              width: '10px',
              height: '10px',
              padding: 0,
              '&:before': {
                content: '""',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                background: '#919CC4', // inactive dot 색상
                opacity: 1,
                transition: 'background-color 0.3s ease',
              },
            },
            '& li.slick-active button:before': {
              background: '#fff', // active dot 색상
              opacity: 1,
            },
          },
        }}
      >
        <Slider {...settings}>
          {modes.map((mode, index) => (
            <Box
              onClick={() => onSelectSlide(index)}
              key={index}
              sx={{
                borderRadius: '10px',
                padding: '20px',
                textAlign: 'center',
                background: 'rgba(255, 255, 255, 0.12);',
                ...(selectedSlide === index && {
                  border: '1px solid #fff',
                  boxShadow: '0px 0px 15px 0px rgba(255, 255, 255, 0.70)',
                }),
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircleNumber number={index + 1} />
                <Typography
                  variant="headline2"
                  color="#fff"
                  textAlign="center"
                  whiteSpace="pre-line"
                >
                  {mode.title}
                </Typography>
              </Box>

              <video
                style={{
                  margin: '16px 0',
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                }}
                preload="none"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src={mode.videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <Typography
                mt="23px"
                variant="subheadline"
                color="#fff"
                textAlign="center"
                whiteSpace="pre-line"
              >
                {mode.description}
              </Typography>
              <Button
                buttonShape="rounded"
                buttonSize="small"
                buttonStyle="secondary"
                sx={{ marginTop: '10px' }}
                onClick={() => {
                  setPracticeModalState({
                    practiceMode: mode.mode,
                    isPracticeOpen: true,
                  });
                }}
              >
                테스트 해보기
              </Button>
            </Box>
          ))}
        </Slider>
      </Box>

      <Box mt="auto">
        <Button
          disabled={selectedSlide === undefined}
          buttonShape="rounded"
          buttonSize="large"
          sx={{ marginTop: '50px' }}
          onClick={() => {
            if (selectedSlide === undefined) return;
            setUser({ ...user, touch_mode: selectedSlide + 1 });
            const currentMode = modes[currentSlide].mode;

            cameFrom === 'mypage'
              ? saveTouchMode(currentSlide)
              : navigate(`/posture-recommend?mode=${currentMode}`);
          }}
        >
          {cameFrom === 'mypage' ? '완료' : '다음으로'}
        </Button>
      </Box>

      <ModePracticeDrawer
        index={currentSlide}
        open={isPracticeOpen}
        mode={practiceMode}
        onClose={() => {
          setPracticeModalState((prevState) => ({
            ...prevState,
            isPracticeOpen: false,
          }));
        }}
        onOpen={() => {
          setPracticeModalState((prevState) => ({
            ...prevState,
            isPracticeOpen: true,
          }));
        }}
      />
    </PageContainer>
  );
};

export default InputModeSelectPage;
export type { TouchModeName };
