import apiUrl from 'lib/api/apiUrl';
import { getRequest, patchRequest, postRequest } from 'lib/api/axios';
import { Moment } from 'moment';

export type DateString =
  `${number}${number}${number}${number}-${number}${number}-${number}${number}`;

export interface LoginResponse {
  result_code: number;
  token: string;
  name: string;
  user_id: string;
  access_start: string;
  access_end: string;
  access_count: number;
  touch_mode: number;
  push_yn: 'Y' | 'N';
  remark?: string;
}

export interface GetInfoResponse {
  result_code: number;
  name: string;
  user_id: string;
  access_start: string;
  access_end: string;
  access_count: number;
  touch_mode: number;
  push_yn: 'Y' | 'N';
  latest_avg_inhale?: number;
  latest_avg_exhale?: number;
  st_score?: number;
  sleep_score?: number;
  breathe_score?: number;
  last_session_day?: DateString;
  remark?: string;
}

const apiManager = {
  login: (
    userId: string,
    password: string
  ): Promise<LoginResponse | undefined> => {
    return postRequest<LoginResponse>(apiUrl.login(), { userId, password });
  },
  qrLogin: (userId: string): Promise<LoginResponse | undefined> => {
    return postRequest(apiUrl.qrLogin(), { userId });
  },
  getUserInfo: () => {
    return getRequest<GetInfoResponse>(apiUrl.getUserInfo());
  },
  updateUserInfo: ({
    userId,
    push_yn,
    touch_mode,
    last_session_day,
    remark,
  }: {
    userId: string;
    push_yn?: string;
    touch_mode?: number;
    last_session_day?: DateString;
    remark?: string;
  }) => {
    return patchRequest<{ result_code: number }>(apiUrl.updateUserInfo(), {
      user_id: userId,
      push_yn,
      touch_mode,
      last_session_day,
      remark,
    });
  },
  getUserSurvey: (userId: string, from: Moment, to: Moment) => {
    return getRequest(
      apiUrl.getUserSurvey(
        userId,
        from.format('YYYY-MM-DD'),
        to.format('YYYY-MM-DD')
      )
    );
  },
  upsertSurvey: ({
    session_day,
    session_id,
    userId,
    type,
    data,
  }: {
    session_day: string;
    session_id: string;
    userId: string;
    type: 'pre' | 'post';
    data: Data.Survey[];
  }) => {
    return postRequest(apiUrl.upsertSurvey(), {
      session_day,
      session_id,
      type,
      data,
    });
  },
  insertSessionData: (
    userId: string,
    session_id: string,
    session_type: string,
    data: Data.Session[]
  ) => {
    return postRequest(apiUrl.insertSessionData(), {
      user_id: userId,
      session_id,
      session_type,
      data,
    });
  },
  increaseAccessCount: (userId: string, session_id: string) => {
    return postRequest(apiUrl.increaseAccessCount(), {
      userId,
      session_id,
    });
  },
  insertSessionReview: ({
    userId,
    session_id,
    review_question,
    review_answer,
  }: {
    userId: string;
    session_id: string;
    review_question: string;
    review_answer: string;
  }) => {
    return postRequest(apiUrl.insertSessionReview(), {
      user_id: userId,
      session_id,
      review_question,
      review_answer,
    });
  },
  notifySessionEnd: (userId: string, session_id: string) => {
    return postRequest(apiUrl.notifySessionEnd(), {
      user_id: userId,
      session_id,
    });
  },
};

export default apiManager;
