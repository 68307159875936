import React from 'react';
import { EmotionCardWrapper } from '../SoundReview.styles';
import { Box, Typography } from '@mui/material';
import { FaceSad, FaceSleeping } from '../../../component/new/icons';

type EmotionCardProps = {
  type: 'good' | 'bad';
  selected?: boolean;
  onChange?: (type: 'good' | 'bad') => void;
};

const EmotionCard = ({ type, selected, onChange }: EmotionCardProps) => {
  return (
    <EmotionCardWrapper selected={selected} onClick={() => onChange?.(type)}>
      <Typography variant="bodyBold" color="rgba(255, 255, 255, 0.8)">
        {type === 'good' ? '잠에 도움되었어요' : '잘 모르겠어요'}
      </Typography>

      <Box mt="9px">{type === 'good' ? <FaceSleeping /> : <FaceSad />}</Box>
    </EmotionCardWrapper>
  );
};

export default EmotionCard;
