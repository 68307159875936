import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import CheckWithLabel from './CheckWithLabel';
import Button from 'component/new/Button/Button';
import TermsOfService from 'component/new/terms/TermsOfService';
import PrivacyPolicy from 'component/new/terms/PrivacyPolicy';
import ThirdPartyConsent from '../../../component/new/terms/ThirdPartyConsent';

type Term = {
  name: string;
  label: string;
  isRequired: boolean;
  termsContent?: ReactNode;
};

const TERMS_DATA: Term[] = [
  {
    name: 'required1',
    label: '(필수) 이용약관',
    isRequired: true,
    termsContent: <TermsOfService />,
  },
  {
    name: 'required2',
    label: '(필수) 개인정보처리방침',
    isRequired: true,
    termsContent: <PrivacyPolicy />,
  },
  {
    name: 'required3',
    label: '(필수) 개인정보 제3자 제공 동의',
    isRequired: true,
    termsContent: <ThirdPartyConsent />,
  },
  { name: 'required4', label: '(선택) 푸시알림 동의', isRequired: false },
];
type CheckedItems = {
  [key: string]: boolean;
};

type TermsAgreeFormProps = {
  onSubmit: (pushAgreed?: boolean) => void;
};

const TermsAgreeForm = ({ onSubmit }: TermsAgreeFormProps) => {
  const [termsData, setTermsData] = useState<Term[]>(TERMS_DATA);
  const [checkedItems, setCheckedItems] = useState<CheckedItems>(() =>
    termsData.reduce((acc, term) => ({ ...acc, [term.name]: false }), {
      all: false,
    })
  );

  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);

  const handleCheckboxChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (name === 'all') {
        const newCheckedItems: CheckedItems = Object.keys(checkedItems).reduce(
          (acc, key) => ({ ...acc, [key]: event.target.checked }),
          {}
        );
        setCheckedItems(newCheckedItems);
      } else {
        setCheckedItems((prev) => ({
          ...prev,
          [name]: event.target.checked,
          all: false,
        }));
      }
    };

  const handleSubmit = async () => {
    const pushAgreed = checkedItems.required4;

    if (pushAgreed) {
      try {
        localStorage.setItem('pushAgreed', 'true');
      } catch (error) {
        console.error('푸시알림 동의 설정 저장 에러', error);
      }
    }

    onSubmit(pushAgreed);
  };

  useEffect(() => {
    const requiredTerms = termsData.filter((term) => term.isRequired);
    const allRequiredChecked = requiredTerms.every(
      (term) => checkedItems[term.name]
    );
    setIsButtonEnabled(allRequiredChecked);

    const allChecked = Object.entries(checkedItems)
      .filter(([key]) => key !== 'all')
      .every(([, value]) => value);

    if (allChecked && !checkedItems.all) {
      setCheckedItems((prev) => ({ ...prev, all: true }));
    }
  }, [checkedItems]);

  useEffect(() => {
    const pushAgreedBefore = localStorage.getItem('pushAgreed');
    if (pushAgreedBefore) {
      setTermsData((prevState) => {
        return prevState.filter((term) => term.name !== 'required4');
      });
    }
  }, []);

  return (
    <Box sx={{ padding: '16px 0' }}>
      <Typography variant="title3">약관에 동의해 주세요</Typography>

      <Box
        mt="32px"
        display="flex"
        flexDirection="column"
        gap="17px"
        overflow="scroll"
      >
        <CheckWithLabel
          checkboxName="all"
          label={<Typography variant="bodyBold">모두 동의</Typography>}
          checkboxProps={{
            checked: checkedItems.all,
            onChange: handleCheckboxChange('all'),
          }}
        />

        <Divider />

        {termsData.map((term) => (
          <CheckWithLabel
            key={term.name}
            checkboxName={term.name}
            label={term.label}
            checkboxProps={{
              checked: checkedItems[term.name],
              onChange: handleCheckboxChange(term.name),
            }}
            termsContent={term.termsContent}
          />
        ))}
      </Box>

      <Button
        sx={{ mt: '56px' }}
        size="large"
        buttonShape="rounded"
        buttonStyle="violet"
        disabled={!isButtonEnabled}
        onClick={handleSubmit}
      >
        로그인
      </Button>
    </Box>
  );
};

export default TermsAgreeForm;
