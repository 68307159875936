import { Button as MuiButton, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ButtonProps } from './Button';

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    prop !== 'buttonShape' && prop !== 'buttonSize' && prop !== 'buttonStyle',
})<ButtonProps>(({ theme, buttonShape, buttonSize, buttonStyle }) => {
  const {
    palette: { semantic, primary },
  } = theme as Theme;

  return {
    borderRadius: buttonShape === 'round' ? '50px' : '10px',
    textTransform: 'none',
    fontWeight: 600,
    letterSpacing: '-0.1px',

    ...(buttonSize === 'large' && {
      padding: '20px',
      height: '60px',
      fontSize: '16px',
      lineHeight: '19px',
    }),
    ...(buttonSize === 'medium' && {
      padding: '16px',
      height: '50px',
      fontSize: '16px',
      lineHeight: '19px',
    }),
    ...(buttonSize === 'medium-narrow' && {
      padding: '12px',
      height: '42px',
      fontSize: '16px',
      lineHeight: '19px',
    }),
    ...(buttonSize === 'small' && {
      padding: '12px',
      height: '41px',
      fontSize: '14px',
      lineHeight: '17px',
    }),

    ...(buttonStyle === 'primary' && {
      backgroundColor: semantic.theme.buttonDefault,
      color: semantic.font.textOnLight,
      '&:hover': {
        backgroundColor: semantic.theme.buttonDefault,
      },
      '&:disabled': {
        opacity: 0.5,
        backgroundColor: semantic.theme.buttonInactive,
        color: semantic.font.textOnLightSecondary,
      },
    }),

    ...(buttonStyle === 'secondary' && {
      border: `1px solid ${semantic.theme.stroke}`,
      backgroundColor: semantic.theme.containerFirst,
      color: semantic.font.textSecondary,
      '&:hover': {
        backgroundColor: semantic.theme.containerFirst,
      },
      '&:disabled': {
        border: `1px solid ${semantic.theme.strokeMuted}`,
        opacity: 0.3,
        backgroundColor: semantic.theme.containerFirst,
        color: semantic.font.textMuted,
        textAlign: 'center',
      },
    }),

    ...(buttonStyle === 'violet' && {
      backgroundColor: semantic.theme.buttonViolet,
      color: semantic.font.textOnLight,
      '&:hover': {
        backgroundColor: semantic.theme.buttonViolet,
      },
      '&:disabled': {
        opacity: 0.5,
        backgroundColor: semantic.theme.buttonViolet,
        color: semantic.font.textOnLight,
      },
    }),
  };
});

export { StyledButton };
