import React from 'react';
import styled from 'styled-components';

const ThirdPartyConsent = () => {
  return (
    <StyledConsent>
      <Section>
        <SectionContent>
          <ConsentItem>
            <ItemTitle>제공받는 자:</ItemTitle>
            <ItemContent>
              이용자가 수면장애와 관련하여 진료를 받고 아이디 및 패스워드를
              제공해주는 의료기관(병원)
            </ItemContent>
          </ConsentItem>
          <ConsentItem>
            <ItemTitle>제공항목:</ItemTitle>
            <ItemContent>수면 기록, 수면의 질, 호흡 주기</ItemContent>
          </ConsentItem>
          <ConsentItem>
            <ItemTitle>제공 목적:</ItemTitle>
            <ItemContent>의료기관(병원)에 대한 전달, 의료진의 열람</ItemContent>
          </ConsentItem>
          <ConsentItem>
            <ItemTitle>보유기간:</ItemTitle>
            <ItemContent>5년</ItemContent>
          </ConsentItem>
          <DisclaimerText>
            귀하는 개인정보 제3자 제공 동의를 거부할 수 있습니다. 다만 이에
            동의하지 않는 경우에는 회사가 제공하는 모바일 앱 서비스 이용을 할 수
            없습니다.
          </DisclaimerText>
        </SectionContent>
      </Section>
    </StyledConsent>
  );
};

const StyledConsent = styled.div`
  font-family: 'Pretendard', sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
`;

const Title = styled.h1`
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
  font-size: 24px;
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-bottom: 15px;
  background-color: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
`;

const SectionContent = styled.div`
  padding: 15px;
  background-color: #ffffff;
  font-size: 14px;
`;

const ConsentItem = styled.div`
  margin-bottom: 10px;
`;

const ItemTitle = styled.span`
  font-weight: bold;
  color: #2980b9;
`;

const ItemContent = styled.span`
  margin-left: 5px;
`;

const DisclaimerText = styled.p`
  margin-top: 20px;
  font-style: italic;
  color: #e74c3c;
`;

export default ThirdPartyConsent;
