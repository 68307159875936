import { Box, styled } from '@mui/material';

export const PAGE_CONTAINER_PADDING_HEIGHT = '0';
export const PageContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: `${PAGE_CONTAINER_PADDING_HEIGHT} 24px 48px`,
  overflowY: 'scroll',
  overflowX: 'hidden',
}));
