import { Box, Typography } from '@mui/material';

export const After10BreathTooltip = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="caption1">호흡이 안정되었다면</Typography>
      <Typography variant="caption1Bold">화면을 두번 터치하거나</Typography>
      <Typography variant="caption1">
        버튼을 눌러 무의식모드로 전환할 수 있어요
      </Typography>
    </Box>
  );
};

export const EnteredUnconsciousTooltip = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="caption1">무의식 모드로 전환되었어요</Typography>
      <Typography variant="caption1Bold">음악과 함께 호흡해보세요</Typography>
    </Box>
  );
};

export const Under10NotificationTooltip = ({
  breathCount,
}: {
  breathCount: number;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="caption1">호흡을 10회 이상 입력해야만</Typography>
      <Typography variant="caption1">무의식모드로 전환할 수 있어요</Typography>
      <Typography variant="caption1Bold">{`현재 호흡 수: ${breathCount}회`}</Typography>
    </Box>
  );
};
