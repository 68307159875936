import { Howl, Howler } from 'howler';
// import { SoundNameEnum } from '../../enums/sound-name.enum';
import csv5min from '../../assets/csv/5min.csv';
import csv10min from '../../assets/csv/10min.csv';
import csv30minDay1And2 from 'assets/csv/30min/day1-2.csv';
import csv30minDay3And4 from 'assets/csv/30min/day3-4.csv';
import csv30minDay5And6 from 'assets/csv/30min/day5-6.csv';
import csv30minDay7 from 'assets/csv/30min/day7.csv';
import csv60minDay1And2 from 'assets/csv/60min/day1-2.csv';
import csv60minDay3And4 from 'assets/csv/60min/day3-4.csv';
import csv60minDay5And6 from 'assets/csv/60min/day5-6.csv';
import csv60minDay7 from 'assets/csv/60min/day7.csv';

// import csv30min from '../../assets/csv/30min.csv';
// import csv60min from '../../assets/csv/60min.csv';

import { fetchCsvData } from '../../utils/fetchCSVData';
import { getDayGroup } from '../../utils/getDayGroup';

class AudioPlayer {
  private static instance: AudioPlayer | null = null;
  private tracks: Map<string, Howl> = new Map();
  private loadedSessions: Set<number> = new Set();
  private totalDuration: number;
  private volumeChangeInterval?: NodeJS.Timer;
  private currentSecond: number = 0;
  public isSampleAudio = false;

  private constructor(totalDuration: number) {
    this.totalDuration = totalDuration * 60;
  }

  public static getInstance(totalDuration: number): AudioPlayer {
    if (!AudioPlayer.instance) {
      AudioPlayer.instance = new AudioPlayer(totalDuration);
    }
    return AudioPlayer.instance;
  }

  public setTotalDuration(duration: number) {
    this.totalDuration = duration * 60;
    this.reset();
  }

  public reset() {
    this.tracks.forEach((track) => {
      track.fade(track.volume(), 0, 2 * 1000);
      track.once('fade', () => track.stop());
      this.tracks.clear();
    });

    if (this.volumeChangeInterval) {
      clearInterval(this.volumeChangeInterval);
      this.volumeChangeInterval = undefined;
    }
    this.currentSecond = 0;
  }

  public isSessionLoaded(sessionLength: number): boolean {
    return this.loadedSessions.has(sessionLength);
  }

  public markSessionAsLoaded(sessionLength: number): void {
    this.loadedSessions.add(sessionLength);
  }

  public createTrack(name: string, src: string) {
    if (this.tracks.has(name)) {
      console.warn(`Track ${name} already exists. Skipping creation.`);
      return;
    }
    const howl = new Howl({
      src: [src],
      preload: true,
      loop: false,
      volume: 0.001,
      onload: () => console.log(`Track ${name} loaded successfully`),
      onloaderror: (id, error) =>
        console.error(`Error loading track ${name}:`, error),
    });
    console.log({
      name,
      src,
      howl,
    });
    this.tracks.set(name, howl);
  }

  public async loadVolumeData(sessionLength: number, day: number) {
    let csvUrl = '';
    switch (sessionLength) {
      case 5:
        csvUrl = csv5min;
        break;
      case 10:
        csvUrl = csv10min;
        break;
      case 30:
        switch (day) {
          case 1:
          case 2:
            csvUrl = csv30minDay1And2;
            break;
          case 3:
          case 4:
            csvUrl = csv30minDay3And4;
            break;
          case 5:
          case 6:
            csvUrl = csv30minDay5And6;
            break;
          case 7:
            csvUrl = csv30minDay7;
            break;
          default:
            console.error('Invalid day');
            return;
        }
        break;
      case 60:
        switch (day) {
          case 1:
          case 2:
            csvUrl = csv60minDay1And2;
            break;
          case 3:
          case 4:
            csvUrl = csv60minDay3And4;
            break;
          case 5:
          case 6:
            csvUrl = csv60minDay5And6;
            break;
          case 7:
            csvUrl = csv60minDay7;
            break;
          default:
            console.error('Invalid day');
            return;
        }
        break;
      default:
        console.error('Invalid session length');
        return;
    }

    const csvData = await fetchCsvData(csvUrl);
    console.log({ csvData });
    const lines = csvData.split('\n');
    const headers = lines[0].split(',').map((header) => header.trim());
    console.log({ headers });

    headers.slice(1).forEach((trackName, index) => {
      const track = this.tracks.get(
        `${trackName.toUpperCase()}_${sessionLength}MIN`
      );

      console.log({ track });
      if (track) {
        const volumeData = lines
          .slice(1)
          .map((line) => parseFloat(line.split(',')[index + 1]));
        (track as any).volumeData = volumeData;
      }
    });

    // 볼륨 데이터 로드 후 바로 스케줄링 시작
    this.startVolumeChangeInterval();
  }

  private startVolumeChangeInterval() {
    if (this.volumeChangeInterval) {
      clearInterval(this.volumeChangeInterval);
    }

    this.volumeChangeInterval = setInterval(() => {
      this.updateTrackVolumes();
      this.currentSecond++;
      if (this.currentSecond >= this.totalDuration) {
        this.pauseVolumeScheduling(); // 세션 길이에 도달하면 스케줄링 일시 중지
      }
    }, 1000); // 1초마다 실행
  }

  private updateTrackVolumes() {
    this.tracks.forEach((track) => {
      const volumeData = (track as any).volumeData;
      if (volumeData) {
        const volume = volumeData[this.currentSecond % volumeData.length] || 0;
        track.volume(volume);
      }
    });
  }

  public changeAllTracksVolume(volume: number) {
    this.tracks.forEach((howl) => {
      howl.volume(volume);
    });
  }

  public playTrack(trackName: string) {
    this.checkAndSetGlobalVolume();
    this.checkAudioContext();

    const track = this.tracks.get(trackName);

    if (track) {
      console.log(`Playing track: ${trackName}, Volume: ${track.volume()}`);
      track.stop();
      track.play();
    } else {
      console.error(`Track not found: ${trackName}`);
    }
  }

  public stopTrack(trackName: string, fadeOutDuration = 2) {
    const track = this.tracks.get(trackName);
    if (track) {
      track.fade(track.volume(), 0, fadeOutDuration * 1000);
      track.once('fade', () => {
        track.stop();
      });
    }
  }

  public playAllInhaleTracks() {
    this.tracks.forEach((track, trackName) => {
      if (trackName.includes('INHALE')) {
        this.playTrack(trackName);
      }
    });
  }

  public playAllExhaleTracks() {
    this.tracks.forEach((track, trackName) => {
      if (trackName.includes('EXHALE')) {
        this.playTrack(trackName);
      }
    });
  }

  public stopAllInhaleTracks() {
    this.tracks.forEach((track, trackName) => {
      if (trackName.includes('INHALE')) {
        this.stopTrack(trackName);
      }
    });
  }

  public stopAllExhaleTracks() {
    this.tracks.forEach((track, trackName) => {
      if (trackName.includes('EXHALE')) {
        this.stopTrack(trackName);
      }
    });
  }

  public stopAll = () => {
    this.tracks.forEach((track) => {
      track.stop();
    });
    this.reset();
  };

  public createSilentTrack() {
    const silentHowl = new Howl({
      src: ['../../assets/sound/exhale1_5min.mp3'], // 매우 짧은 무음 파일
      volume: 0.0001,
      onend: () => {
        silentHowl.unload();
      },
    });
    silentHowl.play();
    return silentHowl;
  }

  public checkAndSetGlobalVolume() {
    console.log('Current global volume:', Howler.volume());
    if (Howler.volume() === 0) {
      console.log('Global volume was 0, setting to 1');
      Howler.volume(1);
    }
  }

  public checkAudioContext() {
    const ctx = Howler.ctx;
    console.log('Audio context state:', ctx.state);
    if (ctx.state === 'suspended') {
      ctx.resume().then(() => console.log('Audio context resumed'));
    }
  }

  // 새로운 메서드: 볼륨 스케줄링 일시 중지
  public pauseVolumeScheduling() {
    if (this.volumeChangeInterval) {
      clearInterval(this.volumeChangeInterval);
      this.volumeChangeInterval = undefined;
    }
  }

  // 새로운 메서드: 볼륨 스케줄링 재개
  public resumeVolumeScheduling() {
    this.currentSecond = 0; // 시작 시점으로 리셋
    this.startVolumeChangeInterval();
  }

  // 새로운 메서드: 현재 스케줄링 상태 확인
  public isVolumeSchedulingActive(): boolean {
    return this.volumeChangeInterval !== undefined;
  }
}

export const getAudioPlayer = (sessionLength: number): AudioPlayer => {
  return AudioPlayer.getInstance(sessionLength);
};

export const updateTotalDuration = (duration: number) => {
  const instance = AudioPlayer.getInstance(0);
  instance.setTotalDuration(duration);
};
