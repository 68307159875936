export const PRE_SURVEY_CONSTANT = [
  {
    id: 'bedtime',
    title: '침대에 누운 시간을 알려주세요',
    type: 'time',
    detailMessage: '침대에 누운 시간을 알려주세요',
  },
  {
    id: 'pre_sleep_activities',
    title: '오늘 해당하는 수면 전 활동이 있나요?',
    type: 'checkbox',
    detailMessage: '오늘 해당하는 수면 전 활동이 있나요?',
    checkboxItems: [
      { id: 'caffeine', value: 1, name: '카페인 섭취' },
      { id: 'alcohol', value: 2, name: '음주' },
      { id: 'sleeping_pill', value: 3, name: '수면제 복용' },
      { id: 'smoking', value: 4, name: '흡연' },
      { id: 'nap', value: 5, name: '선잠' },
    ],
  },
  {
    id: 'mood',
    title: '지금 기분은 어떠신가요?',
    type: 'likert',
    radioItems: [
      { id: 'mood_-5', name: '-5점', value: -5, description: '매우 안 좋아요' },
      { id: 'mood_-4', name: '-4점', value: -4, description: '많이 안 좋아요' },
      { id: 'mood_-3', name: '-3점', value: -3, description: '꽤 안 좋아요' },
      { id: 'mood_-2', name: '-2점', value: -2, description: '조금 안 좋아요' },
      { id: 'mood_-1', name: '-1점', value: -1, description: '약간 안 좋아요' },
      { id: 'mood_0', name: '0점', value: 0, description: '그저 그래요' },
      { id: 'mood_1', name: '+1점', value: 1, description: '약간 좋아요' },
      { id: 'mood_2', name: '+2점', value: 2, description: '조금 좋아요' },
      { id: 'mood_3', name: '+3점', value: 3, description: '꽤 좋아요' },
      { id: 'mood_4', name: '+4점', value: 4, description: '많이 좋아요' },
      { id: 'mood_5', name: '+5점', value: 5, description: '매우 좋아요' },
    ],
  },
  {
    id: 'mental_state',
    title: '지금 정신적으로 평온하신가요?',
    type: 'likert',
    radioItems: [
      {
        id: 'mental_-5',
        name: '-5점',
        value: -5,
        description: '생각이 무척 많아요',
      },
      {
        id: 'mental_-4',
        name: '-4점',
        value: -4,
        description: '생각이 매우 많아요',
      },
      {
        id: 'mental_-3',
        name: '-3점',
        value: -3,
        description: '생각이 많이 들어요',
      },
      {
        id: 'mental_-2',
        name: '-2점',
        value: -2,
        description: '꽤 생각이 들어요',
      },
      {
        id: 'mental_-1',
        name: '-1점',
        value: -1,
        description: '조금 생각이 들어요',
      },
      {
        id: 'mental_0',
        name: '0점',
        value: 0,
        description: '마음이 그저 그래요',
      },
      {
        id: 'mental_1',
        name: '+1점',
        value: 1,
        description: '마음이 비교적 편안해요',
      },
      {
        id: 'mental_2',
        name: '+2점',
        value: 2,
        description: '생각이 많지는 않아요',
      },
      { id: 'mental_3', name: '+3점', value: 3, description: '생각이 적어요' },
      {
        id: 'mental_4',
        name: '+4점',
        value: 4,
        description: '생각이 거의 없어요',
      },
      {
        id: 'mental_5',
        name: '+5점',
        value: 5,
        description: '생각 없이 편안해요',
      },
    ],
  },
  {
    id: 'stress',
    title: '지금 스트레스는 어떠신가요?',
    type: 'likert',
    radioItems: [
      {
        id: 'stress_-5',
        name: '-5점',
        value: -5,
        description: '매우 극심해요',
      },
      { id: 'stress_-4', name: '-4점', value: -4, description: '많이 심해요' },
      { id: 'stress_-3', name: '-3점', value: -3, description: '꽤 심해요' },
      { id: 'stress_-2', name: '-2점', value: -2, description: '조금 심해요' },
      { id: 'stress_-1', name: '-1점', value: -1, description: '약간 심해요' },
      { id: 'stress_0', name: '0점', value: 0, description: '보통이에요' },
      { id: 'stress_1', name: '+1점', value: 1, description: '약간 낮아요' },
      { id: 'stress_2', name: '+2점', value: 2, description: '조금 낮아요' },
      { id: 'stress_3', name: '+3점', value: 3, description: '꽤 낮아요' },
      { id: 'stress_4', name: '+4점', value: 4, description: '많이 낮아요' },
      { id: 'stress_5', name: '+5점', value: 5, description: '전혀 없어요' },
    ],
  },
  {
    id: 'fatigue',
    title: '지금 피곤하신가요?',
    type: 'likert',
    radioItems: [
      {
        id: 'fatigue_-5',
        name: '-5점',
        value: -5,
        description: '매우 피곤해요',
      },
      {
        id: 'fatigue_-4',
        name: '-4점',
        value: -4,
        description: '많이 피곤해요',
      },
      { id: 'fatigue_-3', name: '-3점', value: -3, description: '꽤 피곤해요' },
      {
        id: 'fatigue_-2',
        name: '-2점',
        value: -2,
        description: '조금 피곤해요',
      },
      {
        id: 'fatigue_-1',
        name: '-1점',
        value: -1,
        description: '약간 피곤해요',
      },
      {
        id: 'fatigue_0',
        name: '0점',
        value: 0,
        description: '피곤함은 보통이에요',
      },
      {
        id: 'fatigue_1',
        name: '+1점',
        value: 1,
        description: '약간 덜 피곤해요',
      },
      {
        id: 'fatigue_2',
        name: '+2점',
        value: 2,
        description: '조금 덜 피곤해요',
      },
      {
        id: 'fatigue_3',
        name: '+3점',
        value: 3,
        description: '많이 덜 피곤해요',
      },
      {
        id: 'fatigue_4',
        name: '+4점',
        value: 4,
        description: '거의 피곤하지 않아요',
      },
      {
        id: 'fatigue_5',
        name: '+5점',
        value: 5,
        description: '전혀 피곤하지 않아요',
      },
    ],
  },
];
