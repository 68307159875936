import { ButtonProps as MuiButtonProps } from '@mui/material';
import { StyledButton } from './Button.styles';

type ButtonShape = 'rounded' | 'round';
type ButtonSize = 'large' | 'medium' | 'medium-narrow' | 'small';
type ButtonStyle = 'primary' | 'secondary' | 'violet';

type CustomButtonProps = {
  buttonShape?: ButtonShape;
  buttonSize?: ButtonSize;
  buttonStyle?: ButtonStyle;
};

type ButtonProps = MuiButtonProps & CustomButtonProps;

const Button = ({
  children,
  buttonShape = 'rounded',
  buttonSize = 'medium',
  buttonStyle = 'primary',
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      fullWidth
      buttonShape={buttonShape}
      buttonSize={buttonSize}
      buttonStyle={buttonStyle}
      disableTouchRipple
      disableRipple
      disableFocusRipple
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
export type { ButtonProps };
