import React from 'react';
import AppHeaderBar from '../component/new/AppHeaderBar/AppHeaderBar';
import { Box, Typography } from '@mui/material';
import { PageContainer } from '../styles/globalStyled/PageContainer';
import { LeftArrow } from 'component/new/icons';
import { useNavigate } from 'react-router-dom';
import Button from '../component/new/Button/Button';
import { getSearchPram } from '../utils/getSearchPram';

const PostureRecommendationPage = () => {
  const navigate = useNavigate();
  const sessionMode = getSearchPram('mode');

  return (
    <PageContainer>
      <AppHeaderBar left={<LeftArrow onClick={() => navigate(-1)} />} />

      <Typography
        margin="16px 0"
        variant="title3"
        whiteSpace="pre-line"
        color="#fff"
      >
        {'소닉테라피에서는\n 다음과 같은 자세를 추천해요\n 함께 연습해볼까요?'}
      </Typography>

      <Typography
        variant="body1"
        color={(theme) => theme.palette.semantic.font.textSub}
        whiteSpace="pre-line"
      >
        {'자세는 권장 사항일뿐,\n 자신에게 가장 편한 자세로 진행해주세요'}
      </Typography>

      <Box
        sx={{
          mt: '32px',
          width: '100%',
          borderRadius: '10px',
          overflow: 'hidden',
          backgroundColor: '#605991',
        }}
      >
        <img
          src="/images/posture-recommend.png"
          alt="추천 자세: 하늘을 보고 바르게 누운 상태로, 검지로 화면을 누르며 사용해 보세요."
          loading="lazy"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>

      <Typography
        mt="10px"
        variant="body1"
        color={(theme) => theme.palette.semantic.font.textSub}
        whiteSpace="pre-line"
      >
        {'하늘을 보고 바르게 누운 상태로 검지로 화면을 누르며 사용해보세요'}
      </Typography>

      <Box mt="auto">
        <Button
          buttonShape="rounded"
          buttonSize="large"
          sx={{ marginTop: '50px' }}
          onClick={() => {
            navigate(`/tutorial?mode=${sessionMode}`);
          }}
        >
          다음
        </Button>
      </Box>
    </PageContainer>
  );
};

export default PostureRecommendationPage;
