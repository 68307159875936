import React from 'react';
import SwipeableDrawer, {
  SwipeableDrawerProps,
} from '@mui/material/SwipeableDrawer';
import { SxProps, Theme } from '@mui/material';
import { StyledBox } from './Drawer.styles';

type CustomDrawerProps = SwipeableDrawerProps & {
  children?: React.ReactNode;
  contentSx?: SxProps<Theme>;
};

function CustomDrawer({
  children,
  PaperProps,
  sx,
  contentSx,
  ...rest
}: CustomDrawerProps) {
  return (
    <SwipeableDrawer
      disableSwipeToOpen
      disableBackdropTransition
      anchor="bottom"
      PaperProps={{
        ...PaperProps,
        sx: {
          ...PaperProps?.sx,
          height: 'clamp(50%, 80%, 100%)',
          borderRadius: '20px 20px 0 0',
          ...sx,
        } as SxProps<Theme>,
      }}
      {...rest}
    >
      <StyledBox sx={contentSx}>{children}</StyledBox>
    </SwipeableDrawer>
  );
}

export default CustomDrawer;
export type { CustomDrawerProps };
