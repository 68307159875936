import {
  PAGE_CONTAINER_PADDING_HEIGHT,
  PageContainer,
} from '../../styles/globalStyled/PageContainer';
import { useNavigate } from 'react-router-dom';
import AppHeaderBar from '../../component/new/AppHeaderBar/AppHeaderBar';
import { LeftArrow } from '../../component/new/icons';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import Button from 'component/new/Button/Button';

const StatisticsPage = () => {
  const navigate = useNavigate();
  return (
    <PageContainer sx={{ padding: `${PAGE_CONTAINER_PADDING_HEIGHT} 0px 0px` }}>
      <Box
        sx={{
          paddingX: '24px',
        }}
      >
        <AppHeaderBar
          left={<LeftArrow onClick={() => navigate(-1)} />}
          center={
            <Typography variant="button1" color="#fff">
              수면 통계
            </Typography>
          }
        />
      </Box>

      <Typography
        mt="12px"
        variant="subheadlineBold"
        color="#fff"
        textAlign="center"
      >
        {moment().format('M월 D일')}
      </Typography>

      <Box
        sx={{
          mt: '40px',
          padding: '0 24px 28px',
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        <Button
          buttonShape="rounded"
          buttonSize="large"
          buttonStyle="secondary"
          onClick={() => navigate('/pre-survey?from=statistics')}
        >
          수면환경 기록하기
        </Button>

        <Button
          buttonShape="rounded"
          buttonSize="large"
          buttonStyle="secondary"
          onClick={() => navigate('/post-survey?from=statistics')}
        >
          수면의 질 기록하기
        </Button>
      </Box>

      <Box
        sx={{
          borderTop: '10px solid rgba(255, 255, 255, 0.1)',
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backdropFilter: 'blur(16px)',
        }}
      >
        <Typography
          variant="headline1"
          color="#fff"
          fontWeight={600}
          lineHeight={1.5}
          whiteSpace="pre-line"
        >
          {'준비 중인 기능이에요\n 조금만 기다려주세요!'}
        </Typography>
      </Box>
    </PageContainer>
  );
};

export default StatisticsPage;
