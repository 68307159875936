import React from 'react';
import { TextField, styled, TextFieldProps, Theme } from '@mui/material';

type InputProps = TextFieldProps;

const Input = ({ ...props }: InputProps) => {
  return <StyledTextField variant="standard" fullWidth {...props} />;
};

export default Input;

const StyledTextField = styled(TextField)(({ theme, error }) => {
  const {
    palette: { semantic },
  } = theme as Theme;
  return {
    height: '50px',
    '& .MuiInputBase-root': {
      height: '34px',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '-0.1px',
      color: semantic.font.textPrimary,
    },
    '& .MuiInputBase-input': {
      padding: '0 0 8px',
      '&::placeholder': {
        color: semantic.font.textSecondary,
        opacity: 1,
      },
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderBottomColor: error
          ? semantic.font.textError
          : semantic.theme.stroke,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottomColor: error
          ? semantic.font.textError
          : semantic.theme.stroke,
      },
      '&:after': {
        borderBottomColor: error
          ? semantic.font.textError
          : semantic.font.textFocused,
      },
    },
    '& .MuiInputLabel-root': {
      height: '34px',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '-0.1px',
      color: semantic.font.textPrimary,
    },
  };
});
