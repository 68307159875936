import React, { useState } from 'react';
import AppHeaderBar from '../component/new/AppHeaderBar/AppHeaderBar';
import { ReactComponent as Logo } from 'assets/icon/logo.svg';
import { ReactComponent as LeftArrow } from 'assets/icon/arrow_left.svg';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from 'component/new/Button/Button';
import Dialog from 'component/new/Dialog/Dialog';

const OnBoardingPage = () => {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const dialogHandle = {
    open: () => setIsDialogOpen(true),
    close: () => setIsDialogOpen(false),
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '50px 24px 48px',
      }}
    >
      <AppHeaderBar
        left={<LeftArrow onClick={() => navigate(-1)} />}
        center={<Logo />}
      />

      <Typography mt="16px" variant="title3" whiteSpace="pre-line" color="#fff">
        {'첫 접속이시군요!\n 함께 사용법을 알아볼까요?'}
      </Typography>

      <Box mt="auto">
        <Typography
          sx={{
            textDecorationLine: 'underline',
            textAlign: 'center',
            lineHeight: 1.5,
            color: '#fff',
            opacity: 0.9,
          }}
          onClick={dialogHandle.open}
        >
          생략하기
        </Typography>
        <Button
          buttonShape="rounded"
          buttonSize="large"
          sx={{ marginTop: '18px' }}
          onClick={() => navigate('/pressguide')}
        >
          확인
        </Button>
      </Box>
      <Dialog
        showCloseIcon
        open={isDialogOpen}
        onClose={dialogHandle.close}
        primaryButtonText="생략하기"
        secondaryButtonText="취소"
        onCloseIconClick={dialogHandle.close}
        title="생략하시겠어요?"
        headline={
          '사용법을 잘 알면 뮤지토닌을\n 더 효과적으로 이용할 수 있어요'
        }
        onPrimaryButtonClick={() => {
          setIsDialogOpen(false);
          navigate('/');
        }}
        onSecondaryButtonClick={dialogHandle.close}
      />
    </Box>
  );
};

export default OnBoardingPage;
