import React, { TouchEvent, useCallback, useState } from 'react';
import CustomDrawer, { CustomDrawerProps } from 'component/new/Drawer/Drawer';
import { Box, Typography } from '@mui/material';
import { Close } from 'component/new/icons';
import { CircleNum, Description, ModeLabel } from './ModePracticeDrawer.styles';
import InteractionEffect from 'component/new/InteractionEffect/InteractionEffect';
import Nipple from 'component/Nipple';
import { TouchModeName } from '../../InputModeSelect.page';
import CircleNumber from '../CircleNumber';

const MODE_PRACTICE_CONSTANTS: Record<
  TouchModeName,
  {
    title: string;
    description1: string;
    description2: string;
  }
> = {
  'inhale-press': {
    title: '들이쉬며 누르기',
    description1: '화면을 지긋이 누르며',
    description2: '숨을 깊게 들이 마십니다',
  },
  'exhale-press': {
    title: '내쉬며 누르기',
    description1: '화면을 지긋이 누르며',
    description2: '숨을 깊게 내쉽니다',
  },
  'inhale-slide-up': {
    title: '호흡하며 움직이기',
    description1: '화면을 누른채로 위로 움직이며',
    description2: '숨을 깊게 들이마십니다',
  },
};

type ModePracticeDrawerProps = CustomDrawerProps & {
  mode?: TouchModeName;
  index: number;
};

const ModePracticeDrawer = ({
  mode = 'inhale-slide-up',
  index,
  ...props
}: ModePracticeDrawerProps) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleTouchStart = useCallback(() => {
    setIsPressed(true);
  }, []);

  const handleTouchEnd = useCallback(() => {
    setIsPressed(false);
  }, []);

  // nippleJS onDown시 Mui SwipeableDrawer가 같이 아래로 움직이는 현상 방지
  const stopPropagationHandlers =
    mode === 'inhale-slide-up'
      ? {
          onTouchStart: (e: TouchEvent) => e.stopPropagation(),
          onTouchMove: (e: TouchEvent) => e.stopPropagation(),
          onTouchEnd: (e: TouchEvent) => e.stopPropagation(),
        }
      : {};

  return (
    <CustomDrawer
      sx={{
        height: '90%',
        background:
          'linear-gradient(180deg, #28234F 0%, #685FA9 65.1%, #7787B6 85.42%, #89B7C6 100%) fixed',
        overflow: 'hidden',
      }}
      contentSx={{
        background: 'transparent',
        '& WebkitScrollbar': {
          display: 'none',
        },
      }}
      {...props}
      onTouchStart={mode !== 'inhale-slide-up' ? handleTouchStart : undefined}
      onTouchEnd={mode !== 'inhale-slide-up' ? handleTouchEnd : undefined}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
        onClick={props.onClose}
      >
        <Close />
      </Box>

      <ModeLabel>
        <CircleNumber
          number={index + 1}
          backgroundColor="#41474D"
          color="#fff"
        />
        <Typography ml="2px" variant="bodyBold">
          {MODE_PRACTICE_CONSTANTS[mode].title}
        </Typography>
        <Typography variant="body1">방식 테스트 중</Typography>
      </ModeLabel>

      <Box mt="16px" overflow="hidden" {...stopPropagationHandlers}>
        <Description>
          <CircleNum>1</CircleNum>
          <Typography variant="title3" color="#fff">
            {MODE_PRACTICE_CONSTANTS[mode].description1}
          </Typography>
        </Description>
        <Description>
          <CircleNum>2</CircleNum>
          <Typography variant="title3" color="#fff">
            {MODE_PRACTICE_CONSTANTS[mode].description2}
          </Typography>
        </Description>
        <Typography
          mt="8px"
          variant="body1"
          color={(theme) => theme.palette.semantic.font.textSub}
        >
          화면과 폐의 움직임을 함께 느껴보세요
        </Typography>

        {mode === 'inhale-slide-up' ? (
          <Nipple onUp={handleTouchStart} onDown={handleTouchEnd} />
        ) : null}
        <InteractionEffect isActive={isPressed} />
      </Box>
    </CustomDrawer>
  );
};

export default ModePracticeDrawer;
