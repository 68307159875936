import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '../hooks/auth/useUser';
import apiManager from '../lib/api/apiManager';

function QRLogin() {
  const { setUser } = useUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const userId = searchParams.get('id');
    if (!userId) {
      throw new Error('Cannot Find User Id');
    }

    const qrCodeData = apiManager.qrLogin(userId);
    qrCodeData.then((res) => {
      try {
        switch (res?.result_code) {
          case 1:
            const accessToken = res?.token || '';
            console.log({ res });
            setUser({
              ...res,
              accessToken,
              isAuthorized: true,
            });

            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('userId', res?.user_id);

            navigate('/', { replace: true });
            break;
          default:
            break;
        }
      } catch (e) {}
    });
  }, [navigate, searchParams]);

  return <div style={{ width: '100%', height: '100%' }}></div>;
}

export default QRLogin;
