import { ComponentType, SyntheticEvent } from 'react';
import { Box, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { baseColors } from '../../../styles/theme';

type TabItem<T = {}> = {
  label: string;
  content: ComponentType<T>;
};

type CustomTabsProps<T = {}> = {
  items: TabItem<T>[];
  contentProps: T;
  variant?: 'fullWidth' | 'scrollable' | 'standard';
  orientation?: 'horizontal' | 'vertical';
  value: number;
  onChange: (newValue: number) => void;
};

const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  borderRadius: '8px',
  border: `1px solid ${baseColors.neutral.bw['white-alpha-60']}`,
  minHeight: '34px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const StyledTab = styled(MuiTab)(({ theme }) => ({
  padding: '0',
  color: theme.palette.semantic.font.textMuted,
  opacity: 0.7,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: 1.2,
  minHeight: '34px',
  '&.Mui-selected': {
    backgroundColor: baseColors.neutral.bw['white-alpha-30'],
    color: '#fff',
    opacity: 1,
  },
}));

const Tabs = <T extends object>({
  items,
  contentProps,
  variant = 'fullWidth',
  orientation = 'horizontal',
  value,
  onChange,
}: CustomTabsProps<T>) => {
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    onChange(newValue);
  };

  const CurrentTabContent = items[value].content;

  return (
    <Box height="100%">
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant={variant}
        orientation={orientation}
      >
        {items.map((item, index) => (
          <StyledTab key={index} label={item.label} />
        ))}
      </StyledTabs>
      <Box mt={2} height="100%">
        <CurrentTabContent {...contentProps} />
      </Box>
    </Box>
  );
};

export default Tabs;
