import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './auth/RequireAuth';
import SignInPage from 'pages/signin/SignIn.page';
import WelcomePage from 'pages/Welcome.page';
import IntroductionPage from 'pages/introduction/Introduction.page';
import OnBoardingPage from 'pages/OnBoarding.page';
import PressGuidePage from 'pages/press-guide/PressGuide.page';
import InputModeSelectPage from 'pages/input-mode-select/InputModeSelect.page';
import PostureRecommendationPage from 'pages/PostureRecommendation.page';
import TutorialPage from 'pages/tutorial/Tutorial.page';
import HomePage from 'pages/home/home.page';
import TherapySelectionPage from 'pages/therapy-selection/TherapySelection.page';
import PreSurveyPage from '../../pages/survey/pre-survey/PreSurvey.page';
import TherapyPage from '../../pages/therapy/therapy.page';
import TutorialConfirmPage from '../../pages/tutorial/confirm/TutorialConfirm.page';
import TutorialDonePage from '../../pages/tutorial/done/TutorialDone.page';
import SoundReviewPage from '../../pages/sound-review/SoundReview.page';
import PostSurveyPage from '../../pages/survey/post-survey/PostSurvey.page';
import MyPage from '../../pages/my-page/MyPage.page';
import AppInfoPage from '../../pages/AppInfo.page';
import StatisticsPage from '../../pages/statistics/Statistics.page';
import Layout from '../Layout';
import QRLogin from '../../pages/QRLogin';
import LoadingTherapyPage from '../../pages/LoadingTherapy.page';

const PageRouter = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/qrcode" element={<QRLogin />} />
        <Route path="/" element={<RequireAuth />}>
          <Route index element={<HomePage />} />
          <Route path="welcome" element={<WelcomePage />} />
          {/*<Route path="introduction" element={<IntroductionPage />} />*/}
          <Route path="onboarding" element={<OnBoardingPage />} />
          <Route path="pressguide" element={<PressGuidePage />} />
          <Route path="input-mode-select" element={<InputModeSelectPage />} />
          <Route
            path="posture-recommend"
            element={<PostureRecommendationPage />}
          />

          <Route path="tutorial" element={<TutorialPage />} />
          <Route path="tutorial/confirm" element={<TutorialConfirmPage />} />
          <Route path="tutorial/done" element={<TutorialDonePage />} />
          <Route path="therapy-selection" element={<TherapySelectionPage />} />
          <Route path="pre-survey" element={<PreSurveyPage />} />
          <Route path="loading-therapy" element={<LoadingTherapyPage />} />
          <Route path="therapy" element={<TherapyPage />} />
          <Route path="sound-review" element={<SoundReviewPage />} />
          <Route path="post-survey" element={<PostSurveyPage />} />
          <Route path="mypage" element={<MyPage />} />
          {/*<Route path="app-info" element={<AppInfoPage />} />*/}
          <Route path="statistics" element={<StatisticsPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default PageRouter;
