import React, { useEffect, useRef } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  styled,
  Typography,
} from '@mui/material';

const ScaleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
  marginBottom: '10px',
  gap: '5px',
});

const ScaleButton = styled(Box)<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    padding: '10px',
    width: '26px',
    height: '56px',
    backgroundColor: isSelected
      ? theme.palette.semantic.theme.containerMain
      : theme.palette.semantic.theme.containerOnLight,
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  })
);

const ScaleLabel = styled(Typography)({
  textAlign: 'center',
  marginTop: '5px',
});

type LikertItem = {
  id: string;
  name: string;
  value: number;
  description: string;
};

type LikertScaleProps = {
  items: LikertItem[];
  onChange: (value: number | null, isNoAwake: boolean) => void;
  value: number | null;
  showNoWakeCheckbox?: boolean;
  isNoAwake?: boolean;
  isNotWakeDefaultTrue?: boolean;
};

const LikertScale = ({
  items,
  onChange,
  value,
  showNoWakeCheckbox = false,
  isNoAwake,
  isNotWakeDefaultTrue,
}: LikertScaleProps) => {
  const isDefaultSet = useRef(false);

  useEffect(() => {
    if (showNoWakeCheckbox && isNotWakeDefaultTrue && !isDefaultSet.current) {
      console.log('작동?');
      onChange(null, true);
      isDefaultSet.current = true;
    }
  }, [showNoWakeCheckbox, isNotWakeDefaultTrue, onChange]);

  const handleNoWakeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIsNoAwake = event.target.checked;
    onChange(newIsNoAwake ? null : value, newIsNoAwake);
  };

  const handleScaleChange = (newValue: number) => {
    onChange(newValue, false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {value !== null ? (
        <Box minHeight="200px">
          <Typography fontSize="60px" fontWeight={700} textAlign="center">
            {items.find((item) => item.value === value)?.value}
          </Typography>
          <Typography variant="title2" textAlign="center">
            {items.find((item) => item.value === value)?.description}
          </Typography>
        </Box>
      ) : (
        <Box minHeight="200px" />
      )}

      {showNoWakeCheckbox && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isNoAwake}
              onChange={handleNoWakeChange}
              name="noWake"
            />
          }
          label="잠에서 깨지 않았어요"
        />
      )}

      <ScaleContainer>
        {items.map((item) => (
          <ScaleButton
            key={item.id}
            isSelected={value === item.value}
            onClick={() => handleScaleChange(item.value)}
            sx={{
              opacity: isNoAwake ? 0.5 : 1,
              pointerEvents: isNoAwake ? 'none' : 'auto',
            }}
          >
            <Typography
              color={(theme) =>
                theme.palette.semantic.font.textOnLightSecondary
              }
            >
              {item.value}
            </Typography>
          </ScaleButton>
        ))}
      </ScaleContainer>
      <Box display="flex" justifyContent="space-between">
        <ScaleLabel variant="caption">{items[0].description}</ScaleLabel>
        <ScaleLabel variant="caption">
          {items[items.length - 1].description}
        </ScaleLabel>
      </Box>
    </Box>
  );
};

export default LikertScale;
