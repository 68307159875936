import React from 'react';
import {
  ListItem,
  ListItemProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowRight2 } from '../../../component/new/icons';

type MyPageLinkProps = ListItemProps & {
  name: string;
  to?: string;
  typographyVariant?: TypographyProps['variant'];
  noIcon?: boolean;
  noBorder?: boolean;
  onClick?: () => void;
};

const MyPageLink = ({
  name,
  to = '/',
  typographyVariant = 'bodyBold',
  noIcon,
  noBorder,
  onClick,
  ...props
}: MyPageLinkProps) => {
  const navigate = useNavigate();

  return (
    <ListItem
      sx={{
        padding: '18px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        ...(noBorder
          ? {}
          : { borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }),
      }}
      onClick={onClick ? onClick : () => navigate(to)}
      {...props}
    >
      <Typography variant={typographyVariant} color="#fff">
        {name}
      </Typography>

      {noIcon ? null : <ArrowRight2 />}
    </ListItem>
  );
};

export default MyPageLink;
