import { ReactNode } from 'react';
import styled from 'styled-components';
import {
  CenterSection,
  HeaderContainer,
  LeftSection,
  RightSection,
} from './AppHeaderBar.styles';

type SectionProps = {
  children?: ReactNode;
};

type AppHeaderBarProps = {
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
};

const Left = ({ children }: SectionProps) => (
  <LeftSection>{children}</LeftSection>
);
const Center = ({ children }: SectionProps) => (
  <CenterSection>{children}</CenterSection>
);
const Right = ({ children }: SectionProps) => (
  <RightSection>{children}</RightSection>
);

const AppHeaderBar = ({ left, center, right }: AppHeaderBarProps) => {
  return (
    <HeaderContainer>
      <Left>{left}</Left>
      <Center>{center}</Center>
      <Right>{right}</Right>
    </HeaderContainer>
  );
};

export default AppHeaderBar;
