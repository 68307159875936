import React from 'react';
import {
  Box,
  ListItem,
  ListItemProps,
  Switch,
  SwitchProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

type PushAgreeProps = ListItemProps & {
  noIcon?: boolean;
  noBorder?: boolean;
  switchProps?: SwitchProps;
};

const PushAgree = ({
  noIcon,
  noBorder,
  onClick,
  switchProps,
  ...props
}: PushAgreeProps) => {
  const navigate = useNavigate();

  return (
    <ListItem
      sx={{
        padding: '18px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        ...(noBorder
          ? {}
          : { borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }),
      }}
      {...props}
    >
      <Box>
        <Typography variant="bodyBold" color="#fff">
          푸시 알림 허용
        </Typography>
        <Typography
          mt="4px"
          variant="caption2"
          color={(theme) => theme.palette.semantic.font.textSecondary}
        >
          소닉 테라피에 도움이 될 알림만을 보내드릴게요
        </Typography>
      </Box>

      {noIcon ? null : <Switch {...switchProps} />}
    </ListItem>
  );
};

export default PushAgree;
