type Listener = (...args: any[]) => void;

const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

class EventBus {
  private events: { [key: string]: Function[] } = {};

  on(event: string, callback: Listener) {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].push(callback);
  }

  emit(event: string, ...args: any[]) {
    if (this.events[event]) {
      this.events[event].forEach((callback) => callback(...args));
    }
  }

  off(event: string, callback: Listener) {
    if (this.events[event]) {
      this.events[event] = this.events[event].filter((cb) => cb !== callback);
    }
  }
}

export const eventBus = new EventBus();
export { TOKEN_EXPIRED };
