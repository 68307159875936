import { styled } from '@mui/material';
import Box from '@mui/material/Box';

const GridContainer = styled(Box)(({ theme }) => ({
  marginTop: '34px',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto auto',
  gap: '18px',
  width: '100%',
  '& > :first-of-type': {
    gridColumn: '1 / -1',
  },
}));

const ModeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '11px',
  flexDirection: 'column',
  gap: '10px',
  // alignSelf: 'stretch',
  borderRadius: '20px',
  border: `1px solid ${theme.palette.semantic.theme.stroke}`,
}));

export { GridContainer, ModeContainer };
