import React from 'react';
import { PageContainer } from '../../../styles/globalStyled/PageContainer';
import { Box, Typography } from '@mui/material';
import Button from 'component/new/Button/Button';
import { useNavigate } from 'react-router-dom';
import { getSearchPram } from '../../../utils/getSearchPram';
import { TouchModeName } from '../../input-mode-select/InputModeSelect.page';
import { useUser } from '../../../hooks/auth/useUser';
import apiManager from '../../../lib/api/apiManager';

const TutorialConfirmPage = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const onConfirm = async () => {
    try {
      await apiManager.updateUserInfo({
        userId: user?.user_id || '',
        touch_mode: user.touch_mode,
      });

      navigate('/tutorial/done');
    } catch (error) {
      console.error('업데이트 유저 실패:', error);
    }
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.semantic.background.deem,
        width: '100%',
        height: '100%',
      }}
    >
      <PageContainer
        sx={{
          display: 'grid',
          gridTemplateRows: '1fr auto auto',
          alignItems: 'center',
        }}
      >
        <Box gridRow="1/2">
          <Typography
            variant="title2"
            color="#fff"
            whiteSpace="pre-line"
            textAlign="center"
          >
            {'방금 선택하신 방법으로\n 테라피가 진행돼요'}
          </Typography>
          <Typography
            mt="16px"
            variant="body"
            textAlign="center"
            color={(theme) => theme.palette.semantic.font.textSub}
          >
            {'방금 선택하신 방법으로\n 테라피가 진행돼요'}
          </Typography>
        </Box>

        <Box gridRow="2/3">
          <Button
            buttonSize="large"
            buttonStyle="secondary"
            onClick={() => navigate('/input-mode-select')}
          >
            방식 다시 선택하기
          </Button>
          <Button buttonSize="large" sx={{ mt: '15px' }} onClick={onConfirm}>
            이대로 진행하기
          </Button>
        </Box>
      </PageContainer>
    </Box>
  );
};

export default TutorialConfirmPage;
