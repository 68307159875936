import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useUser } from '../hooks/auth/useUser';

const Layout = () => {
  const { user } = useUser();

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        paddingTop: `${user.insetsTop || 50}px`,
      }}
    >
      <Outlet />
    </Box>
  );
};

export default Layout;
