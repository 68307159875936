import { useRef, useCallback, useEffect, MouseEvent, TouchEvent } from 'react';
import { TouchModeName } from '../pages/input-mode-select/InputModeSelect.page';

interface UseLongPressOptions {
  delay?: number;
  preventDefault?: boolean;
  inputMode: TouchModeName;
}

type LongPressEvent = MouseEvent | TouchEvent;

interface UseLongPressReturn {
  onMouseDown: (e: MouseEvent) => void;
  onTouchStart: (e: TouchEvent) => void;
  onMouseUp: (e: MouseEvent) => void;
  onMouseLeave: (e: MouseEvent) => void;
  onTouchEnd: (e?: TouchEvent) => void;
  onSlideUp?: () => void;
  onSlideDown?: () => void;
}

const useLongPress = (
  onLongPress: (event: LongPressEvent) => void, // 유저의 들숨(모드2에서는 날숨)때 발생할 콜백
  onEnd: (event?: LongPressEvent) => void, // 유저의 날숨때 (모드 2에서는 들숨때) 발생할 콜백
  onDoubleClick: (event: LongPressEvent) => void, // 더블 클릭 때 발생했으면 하는 콜백 예를들면 의식 <-> 무의식 모드 전환
  { delay = 200, preventDefault = true, inputMode }: UseLongPressOptions
): UseLongPressReturn => {
  const timerRef = useRef<number | null>(null);
  const startTimeRef = useRef(0);
  const eventRef = useRef<LongPressEvent | null>(null);
  const lastClickTimeRef = useRef(0);
  const isLongPressRef = useRef(false);
  const isDoubleClickRef = useRef(false);

  const start = useCallback(
    (event: LongPressEvent) => {
      eventRef.current = event;
      const currentTime = performance.now();

      if (currentTime - lastClickTimeRef.current < 300) {
        isDoubleClickRef.current = true;
        onDoubleClick(event);
        lastClickTimeRef.current = 0;
        return;
      }

      startTimeRef.current = currentTime;
      lastClickTimeRef.current = currentTime;
      isLongPressRef.current = false;
      isDoubleClickRef.current = false;

      if (inputMode !== 'inhale-slide-up') {
        timerRef.current = requestAnimationFrame(
          function checkLongPress(timestamp) {
            if (
              timestamp - startTimeRef.current >= delay &&
              !isDoubleClickRef.current
            ) {
              isLongPressRef.current = true;
              onLongPress(event);
              eventRef.current = null;
            } else if (timestamp - startTimeRef.current < delay) {
              timerRef.current = requestAnimationFrame(checkLongPress);
            }
          }
        );
      }
    },
    [onLongPress, onDoubleClick, delay, preventDefault, inputMode]
  );

  const end = useCallback(
    (event?: LongPressEvent) => {
      if (preventDefault) {
        event?.preventDefault();
      }

      if (timerRef.current) {
        cancelAnimationFrame(timerRef.current);
      }

      if (!isLongPressRef.current && inputMode !== 'inhale-slide-up') {
        return;
      }

      if (isLongPressRef.current || inputMode === 'inhale-slide-up') {
        onEnd(event ? event : undefined);
      }

      eventRef.current = null;
      isLongPressRef.current = false;
      isDoubleClickRef.current = false;
    },
    [onEnd, delay, preventDefault]
  );

  const onSlideUp = useCallback(() => {
    if (inputMode === 'inhale-slide-up') {
      onLongPress(eventRef.current as LongPressEvent);
    }
  }, [onLongPress, inputMode]);

  const onSlideDown = useCallback(() => {
    if (inputMode === 'inhale-slide-up') {
      end(eventRef.current as LongPressEvent);
    }
  }, [end, inputMode]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        cancelAnimationFrame(timerRef.current);
      }
    };
  }, []);

  return {
    onMouseDown: start,
    onTouchStart: start,
    onMouseUp: end,
    onMouseLeave: (e) => end(e),
    onTouchEnd: end,
    onSlideUp: inputMode === 'inhale-slide-up' ? onSlideUp : undefined,
    onSlideDown: inputMode === 'inhale-slide-up' ? onSlideDown : undefined,
  };
};

export default useLongPress;
export type { LongPressEvent };
