import styled from 'styled-components';

const APP_HEADER_HEIGHT = '44px';

const HeaderContainer = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: APP_HEADER_HEIGHT,
});

const Section = styled.div({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
});

const LeftSection = styled(Section)({
  justifyContent: 'flex-start',
});

const CenterSection = styled(Section)({
  justifyContent: 'center',
});

const RightSection = styled(Section)({
  justifyContent: 'flex-end',
});

export {
  APP_HEADER_HEIGHT,
  HeaderContainer,
  LeftSection,
  CenterSection,
  RightSection,
};
