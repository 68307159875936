const apiUrl = {
  login: () => {
    return `/login`;
  },
  qrLogin: () => {
    return `/qr-login`;
  },
  getUserInfo: () => {
    return '/user/info';
  },
  updateUserInfo: () => {
    return `/user/info`;
  },
  getUserSurvey: (userId: string, from: string, to: string) => {
    return `/user/survey?from=${from}&to=${to}&user_id=${userId}`;
  },
  upsertSurvey: () => {
    return `/user/survey`;
  },
  insertSessionData: () => {
    return `/user/session`;
  },
  increaseAccessCount: () => {
    return `/user/session/increase`;
  },
  insertSessionReview: () => {
    return `/user/session/review`;
  },
  notifySessionEnd: () => {
    return `/user/session/end`;
  },
};
export default apiUrl;
