import { postRequest } from './axios';

const LOGIN_RESULT_CODE = {
  SUCCESS: 1, // 성공
  INVALID_CREDENTIALS: 2, // 환자 데이터 불일치
  NOT_ALLOWED_DATE: 3, // 접속일자 불일치
  ETC: 4, // 기타
} as const;

type LoginSuccessResponse = {
  access_end: string;
  access_start: string;
  op_from: number;
  exc_day: string;
  name: string;
  session_length: number;
  patient_idx: number;
  resultcode: typeof LOGIN_RESULT_CODE.SUCCESS;
  patient_seq: string;
  access_yn: string;
};

type LoginErrorResponse = {
  resultcode:
    | typeof LOGIN_RESULT_CODE.INVALID_CREDENTIALS
    | typeof LOGIN_RESULT_CODE.NOT_ALLOWED_DATE;
};

type LoginRequest = { birth: string; id: string };
type LoginResponse = LoginSuccessResponse | LoginErrorResponse;

const login = ({ birth, id }: LoginRequest) => {
  return postRequest<LoginResponse>('/login', { birth, id });
};

export { login, LOGIN_RESULT_CODE };
export type {
  LoginRequest,
  LoginResponse,
  LoginSuccessResponse,
  LoginErrorResponse,
};
