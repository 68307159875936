import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import theme from '../../../styles/theme';

const DateContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: ' #4a3b76',
  color: 'white',
  padding: '8px 12px',
  fontSize: '14px',
});

const TodayLabel = styled.span({
  backgroundColor: theme.palette.semantic.theme.buttonDefault,
  padding: '0 12px',
  borderRadius: '50px',
  marginRight: '8px',
});

type DateDisplayProps = {
  date?: Date;
  todayLabel?: string;
};

const DateDisplay = ({
  date = new Date(),
  todayLabel = '오늘',
}: DateDisplayProps) => {
  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    };
    return date.toLocaleDateString('ko-KR', options);
  };

  return (
    <DateContainer>
      <TodayLabel>
        <Typography
          variant="caption1Bold"
          color={(theme) => theme.palette.semantic.font.textOnLightSecondary}
        >
          {todayLabel}
        </Typography>
      </TodayLabel>
      <Typography variant="headline2" color="#fff">
        {formatDate(date)}
      </Typography>
    </DateContainer>
  );
};

export default DateDisplay;
