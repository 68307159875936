import React from 'react';
import { Box, styled } from '@mui/material';

const InteractionBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '0',
  right: '0',
  bottom: '0',
  height: '90%',
  overflow: 'hidden',
  pointerEvents: 'none',
}));

const EffectLayer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '-50%',
  right: '-50%',
  bottom: '-15%',
  height: '90%',
  borderRadius: '45%',
  transition:
    'opacity 2.5s cubic-bezier(0.25, 0.1, 0.25, 1), transform 2.5s cubic-bezier(0.25, 0.1, 0.25, 1)',
  willChange: 'transform, opacity',
  transform: 'translateZ(0)',
}));

const BeforeLayer = styled(EffectLayer)({
  background: `radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  )`,
  filter: 'blur(30px)',
});

const AfterLayer = styled(EffectLayer)({
  background: `radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.3) 60%,
    rgba(255, 255, 255, 0) 100%
  )`,
  filter: 'blur(40px)',
});

const InteractiveBox = ({ isActive }: { isActive: boolean }) => {
  const layerStyle = {
    opacity: isActive ? 1 : 0,
    transform: isActive
      ? 'translateY(0) translateZ(0)'
      : 'translateY(50%) translateZ(0)',
  };

  return (
    <InteractionBox>
      <BeforeLayer style={layerStyle} />
      <AfterLayer style={layerStyle} />
    </InteractionBox>
  );
};

export default InteractiveBox;
