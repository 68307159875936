import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContainer } from '../styles/globalStyled/PageContainer';
import { Box, Typography } from '@mui/material';
import { getSearchPram } from '../utils/getSearchPram';

const LoadingTherapyPage = () => {
  const [dots, setDots] = useState(0);
  const navigate = useNavigate();

  const therapyTime = getSearchPram('time');
  const instantSleepMode = getSearchPram('instant');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots < 2) {
          return prevDots + 1;
        } else {
          clearInterval(interval);

          setTimeout(() => {
            if (instantSleepMode === 'true') {
              navigate(`/therapy?time=${therapyTime}&instant=true`, {
                replace: true,
              });
              return;
            }

            navigate(`/therapy?time=${therapyTime}`, { replace: true });
          }, 500);
          return prevDots;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [navigate, therapyTime]);

  return (
    <PageContainer>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {[0, 1, 2].map((index) => (
            <Box
              key={index}
              sx={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor:
                  index <= dots ? '#fff' : 'rgba(255,255,255,0.3)',
                margin: '0 6px',
              }}
            />
          ))}
        </Box>

        <Typography
          mt="46px"
          variant="h4"
          color="#fff"
          sx={{ marginBottom: 2 }}
        >
          수면 소닉테라피를 준비하고 있어요
        </Typography>

        <Typography
          mt="16px"
          variant="body"
          color={(theme) => theme.palette.semantic.font.textSub}
          whiteSpace="pre-line"
        >
          깊고 편안한 수면을 시작해볼게요
        </Typography>

        <Box
          sx={{
            mt: '16px',
            borderRadius: '5px',
            padding: '8px 31px',
            backgroundColor: 'rgba(255,255,255,0.1)',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="button2"
            color={(theme) => theme.palette.semantic.font.textSecondary}
            textAlign="center"
            whiteSpace="pre-line"
          >
            {
              '무음 모드가 해제되어 있는지 확인해 주시고\n 볼륨을 적당히 맞춰주세요'
            }
          </Typography>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default LoadingTherapyPage;
