import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PageContainer } from '../../styles/globalStyled/PageContainer';
import { Box, Tooltip, Typography } from '@mui/material';
import { baseColors } from '../../styles/theme';
import Interaction from 'component/new/InteractionEffect/InteractionEffect';
import Tabs from '../../component/new/Tabs/Tabs';

import Nipple from '../../component/Nipple';
import { TUTORIAL_MESSAGES } from './_constants';
import Button from '../../component/new/Button/Button';
import { getAudioPlayer } from '../../modules/audio/audioPlayer';
import { preloadAudio } from '../../modules/audio/audioLoader';
import { BreathingSession } from '../../modules/breathingSession';
import { AutomaticBreathingController } from '../../modules/AutomaticBreathingController';
import { useUser } from '../../hooks/auth/useUser';
import useLongPress, { LongPressEvent } from '../../hooks/useLongPress';
import { useNavigate } from 'react-router-dom';
import { TouchModeName } from '../../../types/model/inputMode.type';
import { getTouchModeName } from '../../utils/getTouchModeName';
import AppHeaderBar from '../../component/new/AppHeaderBar/AppHeaderBar';
import { LeftArrow } from '../../component/new/icons';
import { getSearchPram } from '../../utils/getSearchPram';
import {
  After10BreathTooltip,
  EnteredUnconsciousTooltip,
  Under10NotificationTooltip,
} from '../therapy/_components/TooltipTitle';

type TutorialSessionProps = {
  onTouchStart: (params?: { isSlide: boolean }) => void;
  onTouchEnd: () => void;
  isActive: boolean;
  inputMode?: TouchModeName;
  handleDoubleClick: (event: LongPressEvent) => void;
};

const TutorialSession = ({
  onTouchStart,
  onTouchEnd,
  isActive,
  inputMode = 'inhale-press',
  handleDoubleClick,
}: TutorialSessionProps) => {
  const { onTouchStart: sanitizedTouchStart, onTouchEnd: sanitizedTouchEnd } =
    useLongPress((event) => onTouchStart(), onTouchEnd, handleDoubleClick, {
      delay: 180,
      preventDefault: true,
      inputMode,
    });

  return (
    <Box
      mt="44px"
      height="100%"
      onTouchStart={sanitizedTouchStart}
      onTouchEnd={
        inputMode !== 'inhale-slide-up'
          ? () => {
              sanitizedTouchEnd();
            }
          : undefined
      }
    >
      <Interaction isActive={isActive} />
      {inputMode === 'inhale-slide-up' ? (
        <Nipple
          onUp={() => onTouchStart()}
          onDown={() => sanitizedTouchEnd()}
        />
      ) : null}
    </Box>
  );
};

const tabItems = [
  { label: '의식모드', content: TutorialSession },
  { label: '무의식모드', content: TutorialSession },
];

const TutorialPage = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [isPressed, setIsPressed] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [inhaleCount, setInhaleCount] = useState(0);
  const [underTenAttempts, setUnderTenAttempts] = useState(false);

  const isConsciousMode = activeTab === 0;
  const isUnconsciousMode = activeTab === 1;

  const audioPlayerRef = useRef<ReturnType<typeof getAudioPlayer> | null>(null);
  const breathingSessionRef = useRef<BreathingSession | null>(null);
  const automaticBreathRef = useRef<AutomaticBreathingController | null>(null);

  const day = 1;

  console.log(getSearchPram('mode'), 11);
  console.log(user.touch_mode, 22);

  const touchModeName =
    getSearchPram('mode') || getTouchModeName(user.touch_mode);

  const handleStart = useCallback(
    (event: LongPressEvent, isSlide?: boolean) => {
      if (activeTab === 1) {
        return;
      }

      setInhaleCount((prev) => prev + 1);
      setIsPressed(true);
      audioPlayerRef.current?.playAllInhaleTracks();
      breathingSessionRef.current?.recordBreath(
        touchModeName === 'exhale-press'
          ? BreathingSession.EXHALE_PHASE
          : BreathingSession.INHALE_PHASE,
        BreathingSession.CONSCIOUS_BREATH
      );
    },
    [touchModeName, activeTab]
  );

  const handleEnd = useCallback(() => {
    if (activeTab === 1) {
      return;
    }

    setIsPressed(false);
    audioPlayerRef.current?.playAllExhaleTracks();

    breathingSessionRef.current?.recordBreath(
      touchModeName === 'exhale-press'
        ? BreathingSession.INHALE_PHASE
        : BreathingSession.EXHALE_PHASE,
      BreathingSession.CONSCIOUS_BREATH
    );
  }, [touchModeName, activeTab]);

  const handleTabChange = (newValue: number) => {
    if (inhaleCount <= 7) {
      setUnderTenAttempts(true);
      return;
    }

    setUnderTenAttempts(false);

    setActiveTab(newValue);

    if (newValue === 1) {
      const { avgInhale, avgExhale } =
        breathingSessionRef.current?.calculateAvg() || {
          avgInhale: 2000,
          avgExhale: 3000,
        };

      if (automaticBreathRef.current) {
        automaticBreathRef.current.updateAverages(avgInhale, avgExhale);
        automaticBreathRef.current.automaticBreathStart((isInhaling) => {
          setIsPressed(isInhaling);
          if (isInhaling) {
            audioPlayerRef.current?.playAllInhaleTracks();
          } else {
            audioPlayerRef.current?.playAllExhaleTracks();
          }
          breathingSessionRef.current?.recordBreath(
            isInhaling
              ? BreathingSession.INHALE_PHASE
              : BreathingSession.EXHALE_PHASE,
            BreathingSession.UNCONSCIOUS_BREATH
          );
        });
      }
    } else {
      automaticBreathRef.current?.stop();
    }
  };

  const handleDoubleClick = (event: LongPressEvent) => {
    if (isConsciousMode && inhaleCount > 7) {
      // 더블 클릭 시 모든 진행 중인 동작을 중지
      setUnderTenAttempts(true);
      setIsPressed(false);

      // 탭 전환
      handleTabChange(activeTab === 0 ? 1 : 0);
    } else {
      setUnderTenAttempts(true);
    }
  };

  const onTutorialEnd = () => {
    navigate(`/tutorial/confirm?mode=${touchModeName}`);
  };

  useEffect(() => {
    const initAudio = async () => {
      await preloadAudio(5, day, {
        isSampleAudio: true,
      });

      audioPlayerRef.current = getAudioPlayer(5);
      audioPlayerRef.current.createSilentTrack();
      audioPlayerRef.current.changeAllTracksVolume(0.7);
      console.log(audioPlayerRef.current);
    };

    initAudio();

    if (user.user_id) {
      breathingSessionRef.current = new BreathingSession(5, user?.user_id);
      automaticBreathRef.current = new AutomaticBreathingController(5);
    }

    return () => {
      audioPlayerRef.current?.reset();
      breathingSessionRef.current?.endSession();
      automaticBreathRef.current?.stop();
    };
  }, [user.user_id]);

  const {
    description1,
    description2,
    repeatMessage,
    howToToggleMode,
    after10Unconscious,
    unConsciousEntered,
    onScreen2,
    onScreen1,
    onScreenUnconscious,
  } = TUTORIAL_MESSAGES[touchModeName as TouchModeName];

  return (
    <PageContainer>
      <AppHeaderBar
        left={<LeftArrow onClick={() => navigate(-1)} />}
        center={
          <Box
            sx={{
              padding: '6px 20px',
              borderRadius: '50px',
              backgroundColor: baseColors.neutral.bw['white-alpha-80'],
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography variant="bodyBold" whiteSpace="nowrap">
              튜토리얼 진행 중
            </Typography>
          </Box>
        }
      />

      <Typography
        variant="title2"
        sx={{
          mt: '20px',
          whiteSpace: 'pre-line',
          color: '#fff',
          textAlign: 'center',
          minHeight: '3em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 0,
          pointerEvents: 'none',
        }}
      >
        {isConsciousMode && inhaleCount <= 2
          ? isPressed
            ? description2
            : description1
          : ''}
        {isConsciousMode && 2 < inhaleCount && inhaleCount < 5
          ? repeatMessage
          : ''}
        {isConsciousMode && 5 <= inhaleCount && inhaleCount <= 7
          ? after10Unconscious
          : ''}
        {isConsciousMode && inhaleCount > 7 ? howToToggleMode : ''}
        {isUnconsciousMode ? unConsciousEntered : ''}
      </Typography>

      <Box
        sx={{
          mt: '44px',
          height: '100%',
          padding: '20px 24px 0',
          borderRadius: '20px',
          boxShadow: '0px 0px 15px 0px rgba(255, 255, 255, 0.70)',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            left: '50%',
            top: '32px',
          }}
        >
          <Tooltip
            arrow
            title={
              underTenAttempts ? (
                <Under10NotificationTooltip breathCount={inhaleCount} />
              ) : isConsciousMode && inhaleCount >= 10 ? (
                <After10BreathTooltip />
              ) : isUnconsciousMode ? (
                <EnteredUnconsciousTooltip />
              ) : (
                ''
              )
            }
            placement="bottom"
            open={underTenAttempts || inhaleCount >= 10}
          >
            <Box sx={{ width: '1px', height: '1px' }}></Box>
          </Tooltip>
        </Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          items={tabItems}
          contentProps={{
            onTouchStart: () => handleStart({} as LongPressEvent),
            onTouchEnd: handleEnd,
            isActive: isPressed,
            inputMode: touchModeName as TouchModeName,
            handleDoubleClick,
          }}
        />
        <Typography
          variant="headline2"
          sx={{
            width: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textShadow: '0px 0px 6px rgba(0, 0, 0, 0.40)',
            whiteSpace: 'pre-line',
            color: '#fff',
            opacity: 0.6,
            textAlign: 'center',
            // zIndex: -1,
          }}
        >
          {isConsciousMode && 2 < inhaleCount && inhaleCount <= 5
            ? isPressed
              ? onScreen1
              : onScreen2
            : ''}

          {isUnconsciousMode ? onScreenUnconscious : ''}
        </Typography>
        {isUnconsciousMode ? (
          <Box
            zIndex={2}
            sx={{
              width: '90%',
              position: 'absolute',
              top: '90%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Button
              onClick={onTutorialEnd}
              buttonShape="rounded"
              buttonSize="medium"
            >
              종료하기
            </Button>
          </Box>
        ) : null}
      </Box>
    </PageContainer>
  );
};

export default TutorialPage;
