import { DialogContent, Theme, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ReactNode } from 'react';
import { ButtonContainer, DialogHeader, StyledDialog } from './Dialog.styles';
import Button from '../Button/Button';
import { Close } from '../icons';
import { SxProps } from '@mui/system/styleFunctionSx';

type DialogProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  headline?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  showCloseIcon?: boolean;
  onCloseIconClick?: () => void;
  children?: ReactNode;
  hasHeader?: boolean;
  sx?: SxProps<Theme>;
};

const DialogHeaderComponent = ({
  showCloseIcon,
  onCloseIconClick,
  onClose,
}: Pick<DialogProps, 'showCloseIcon' | 'onCloseIconClick' | 'onClose'>) => (
  <DialogHeader
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
    }}
  >
    {showCloseIcon && (
      <IconButton onClick={onCloseIconClick || onClose} sx={{ padding: 0 }}>
        <Close />
      </IconButton>
    )}
  </DialogHeader>
);

const DefaultContent = ({
  title,
  headline,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}: Pick<
  DialogProps,
  | 'title'
  | 'headline'
  | 'primaryButtonText'
  | 'secondaryButtonText'
  | 'onPrimaryButtonClick'
  | 'onSecondaryButtonClick'
>) => (
  <>
    <Typography
      mt="31px"
      variant="headline1"
      color="#fff"
      textAlign="center"
      whiteSpace="pre-line"
    >
      {title}
    </Typography>
    <DialogContent sx={{ padding: 0, marginTop: '18px' }}>
      <Typography
        variant="body1"
        color="#fff"
        whiteSpace="pre-line"
        textAlign="center"
      >
        {headline}
      </Typography>
      <ButtonContainer>
        <Button
          onClick={onPrimaryButtonClick}
          buttonShape="round"
          buttonSize="medium-narrow"
          buttonStyle="secondary"
        >
          {primaryButtonText}
        </Button>
        <Button
          onClick={onSecondaryButtonClick}
          buttonShape="round"
          buttonSize="medium-narrow"
        >
          {secondaryButtonText}
        </Button>
      </ButtonContainer>
    </DialogContent>
  </>
);

const Dialog = ({
  open,
  onClose,
  title,
  headline,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  showCloseIcon = false,
  onCloseIconClick,
  hasHeader = true,
  children,
  ...props
}: DialogProps) => {
  return (
    <StyledDialog open={open} onClose={onClose} {...props}>
      {hasHeader && (
        <DialogHeaderComponent
          showCloseIcon={showCloseIcon}
          onCloseIconClick={onCloseIconClick}
          onClose={onClose}
        />
      )}
      {children || (
        <DefaultContent
          title={title}
          headline={headline}
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          onPrimaryButtonClick={onPrimaryButtonClick}
          onSecondaryButtonClick={onSecondaryButtonClick}
        />
      )}
    </StyledDialog>
  );
};

export default Dialog;
