import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Popover,
  List,
  ListItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';

type TimeInputProps = {
  onChange: (value: string) => void;
  value: string | null;
};

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const TimeButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'var(--semantic-theme-container-on-light, #EAEDEE)',
  color: 'var(--basecolor-neutral-light-gray800, #212931)',
  borderRadius: '8px',
  padding: theme.spacing(1, 2),
  minWidth: '60px',
  height: '44px',
  fontFamily: 'Pretendard',
  fontSize: '23px',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.7px',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    backgroundColor: 'var(--semantic-theme-container-on-light, #EAEDEE)',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: 'var(--basecolor-neutral-light-gray800, #212931)',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.6px',
  padding: theme.spacing(1, 2),
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

const TimeInput: React.FC<TimeInputProps> = ({ onChange, value }) => {
  const [period, setPeriod] = useState<'오전' | '오후'>('오전');
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPopover, setOpenPopover] = useState<
    'period' | 'hours' | 'minutes' | null
  >(null);

  useEffect(() => {
    if (value) {
      const [timePeriod, time] = value.split(' ');
      const [hrs, mins] = time.split(':');
      setPeriod(timePeriod as '오전' | '오후');
      setHours(hrs);
      setMinutes(mins);
    }
  }, [value]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    type: 'period' | 'hours' | 'minutes'
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(type);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopover(null);
  };

  const handleSelect = (
    type: 'period' | 'hours' | 'minutes',
    newValue: string
  ) => {
    switch (type) {
      case 'period':
        setPeriod(newValue as '오전' | '오후');
        break;
      case 'hours':
        setHours(newValue.padStart(2, '0'));
        break;
      case 'minutes':
        setMinutes(newValue.padStart(2, '0'));
        break;
    }
    handleClose();
    onChange(
      `${type === 'period' ? newValue : period} ${
        type === 'hours' ? newValue : hours
      }:${type === 'minutes' ? newValue : minutes}`
    );
  };

  const periodOptions = ['오전', '오후'];
  const hoursOptions = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, '0')
  );
  const minutesOptions = Array.from({ length: 12 }, (_, i) =>
    String(i * 5).padStart(2, '0')
  );

  return (
    <Box>
      <InputContainer>
        <TimeButton onClick={(e) => handleClick(e, 'period')}>
          {period}
        </TimeButton>
        <TimeButton onClick={(e) => handleClick(e, 'hours')}>
          {hours}
        </TimeButton>
        <Typography
          variant="h6"
          sx={{
            fontSize: '23px',
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          :
        </Typography>
        <TimeButton onClick={(e) => handleClick(e, 'minutes')}>
          {minutes}
        </TimeButton>
      </InputContainer>
      <StyledPopover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {openPopover === 'period' &&
            periodOptions.map((option) => (
              <StyledListItem
                key={option}
                onClick={() => handleSelect('period', option)}
              >
                {option}
              </StyledListItem>
            ))}
          {openPopover === 'hours' &&
            hoursOptions.map((option) => (
              <StyledListItem
                key={option}
                onClick={() => handleSelect('hours', option)}
              >
                {option}
              </StyledListItem>
            ))}
          {openPopover === 'minutes' &&
            minutesOptions.map((option) => (
              <StyledListItem
                key={option}
                onClick={() => handleSelect('minutes', option)}
              >
                {option}
              </StyledListItem>
            ))}
        </List>
      </StyledPopover>
    </Box>
  );
};

export default TimeInput;
