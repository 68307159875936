import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { eventBus, TOKEN_EXPIRED } from '../../modules/EventBus';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MUSITONIN_API_URL
    ? process.env.REACT_APP_MUSITONIN_API_URL
    : 'https://www.belltherapeutics.net/api/v2',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    console.log({ userId });

    console.log({ userId });
    if (userId) {
      config.headers['Userid'] = userId;
    }

    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401 || error.response?.status === 500) {
      eventBus.emit(TOKEN_EXPIRED);
    }
    return Promise.reject(error);
  }
);

function handleApiError(error: AxiosError) {
  console.error('API call failed. Error:', error);
}

const getRequest = async <T>(url: string, params?: any) => {
  try {
    const response = await axiosInstance.get<T>(url, { params });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleApiError(error);
    }
  }
};

const postRequest = async <T>(url: string, data: any) => {
  try {
    const response = await axiosInstance.post<T>(url, data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleApiError(error);
    }
  }
};

const deleteRequest = async <T>(url: string) => {
  try {
    const response = await axiosInstance.delete<T>(url);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleApiError(error);
    }
  }
};

const putRequest = async <T>(url: string, data: any) => {
  try {
    const response = await axiosInstance.put<T>(url, data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleApiError(error);
    }
  }
};

const patchRequest = async <T>(url: string, data: any) => {
  try {
    const response = await axiosInstance.patch<T>(url, data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleApiError(error);
    }
  }
};

export { getRequest, postRequest, deleteRequest, putRequest, patchRequest };
