import React, { useEffect, useState } from 'react';
import AppHeaderBar from '../../../component/new/AppHeaderBar/AppHeaderBar';
import { ArrowRight3, LeftArrow } from '../../../component/new/icons';
import {
  PAGE_CONTAINER_PADDING_HEIGHT,
  PageContainer,
} from '../../../styles/globalStyled/PageContainer';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import DateDisplay from '../_components/DateDisplay';
import { APP_HEADER_HEIGHT } from 'component/new/AppHeaderBar/AppHeaderBar.styles';
import CheckboxListItem from '../_components/CheckboxList';
import { POST_SURVEY_CONSTANT } from './constants';
import TimeInput from '../_components/TimeInput';
import LikertScale from '../_components/LinkertScale';
import CustomDrawer from '../../../component/new/Drawer/Drawer';
import Button from 'component/new/Button/Button';
import apiManager from '../../../lib/api/apiManager';
import { useUser } from '../../../hooks/auth/useUser';
import moment from 'moment';
import RadioSelect from '../_components/RadioSelect';
import { getSearchPram } from '../../../utils/getSearchPram';

type SurveyDataItem = {
  survey_idx: number;
  time?: string;
  codes?: string;
  scale?: number;
  isNoAwake?: boolean;
};

const PostSurveyPage = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const isFromStatistics = getSearchPram('from') === 'statistics';

  const [userInteracted, setUserInteracted] = useState<Record<string, boolean>>(
    {}
  );

  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(
    POST_SURVEY_CONSTANT[0].id
  );
  const [surveyData, setSurveyData] = useState<Record<string, any>>(
    POST_SURVEY_CONSTANT.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]:
          item.id === 'sleep_recovery'
            ? { checked: false, value: null, isNoAwake: false }
            : { checked: false, value: null },
      }),
      {}
    )
  );

  const handleItemClick = (id: string) => {
    setCurrentItemId(id);
    setOpenDrawer(true);
  };

  const handleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };

  const handleInputChange = (
    id: string,
    value: any,
    isNoAwake: boolean = false
  ) => {
    setUserInteracted((prev) => ({ ...prev, [id]: true }));
    setSurveyData((prevData) => {
      const newData = {
        ...prevData,
        [id]: {
          ...prevData[id],
          value: isNoAwake ? null : value,
          checked: true,
          isNoAwake,
        },
      };

      return newData;
    });
  };

  const handlePrevious = () => {
    const currentIndex = POST_SURVEY_CONSTANT.findIndex(
      (item) => item.id === currentItemId
    );
    if (currentIndex > 0) {
      setCurrentItemId(POST_SURVEY_CONSTANT[currentIndex - 1].id);
    }
  };

  const handleNext = () => {
    const currentIndex = POST_SURVEY_CONSTANT.findIndex(
      (item) => item.id === currentItemId
    );
    if (currentIndex < POST_SURVEY_CONSTANT.length - 1) {
      setCurrentItemId(POST_SURVEY_CONSTANT[currentIndex + 1].id);
    } else {
      handleDrawer(false);
    }
  };

  const formatDataForServer = () => {
    const currentDate = moment().format('YYYY-MM-DD');
    return {
      session_day: currentDate,
      type: 'post',
      data: POST_SURVEY_CONSTANT.reduce<SurveyDataItem[]>(
        (acc, item, index) => {
          const surveyItem = surveyData[item.id];
          console.log({ surveyItem });
          if (
            !surveyItem.isNoAwake &&
            (!surveyItem ||
              surveyItem.value === null ||
              surveyItem.value === undefined)
          ) {
            return acc;
          }

          let formattedItem: SurveyDataItem | null = null;
          switch (item.type) {
            case 'time':
              const [period, time] = surveyItem.value.split(' ');
              const [hours, minutes] = time.split(':');
              let formattedHours = parseInt(hours);
              if (period === '오후' && formattedHours !== 12) {
                formattedHours += 12;
              } else if (period === '오전' && formattedHours === 12) {
                formattedHours = 0;
              }
              const formattedTime = `${formattedHours
                .toString()
                .padStart(2, '0')}:${minutes}:00.000`;

              // 기상시간이 잠든 시간보다 빠를 경우 기상시간의 날짜는 하루 전날로 변경
              if (item.id === 'wake_up_time') {
                const bedTimeIndex = acc.findIndex(
                  (item) => item.survey_idx === 1
                );
                if (bedTimeIndex !== -1) {
                  const bedTime = acc[bedTimeIndex];
                  const wakeUpMoment = moment(
                    `${currentDate} ${formattedTime}`
                  );
                  const bedTimeMoment = moment(bedTime.time);

                  if (wakeUpMoment.isBefore(bedTimeMoment)) {
                    const adjustedBedTimeDate = moment(bedTime.time).subtract(
                      1,
                      'days'
                    );
                    acc[bedTimeIndex] = {
                      ...bedTime,
                      time: adjustedBedTimeDate.format(
                        'YYYY-MM-DD HH:mm:ss.SSS'
                      ),
                    };
                  }
                }
              }

              formattedItem = {
                survey_idx: index + 1,
                time: `${currentDate} ${formattedTime}`,
              };
              break;
            case 'checkbox':
              if (
                Array.isArray(surveyItem.value) &&
                surveyItem.value.length > 0
              ) {
                formattedItem = {
                  survey_idx: index + 1,
                  codes: surveyItem.value.join(','),
                };
              }
              break;
            case 'likert':
              formattedItem = {
                survey_idx: index + 1,
                scale: surveyItem.value,
              };

              if (surveyItem.isNoAwake) {
                formattedItem.isNoAwake = true;
                formattedItem.scale = 5;
              }
              break;
            case 'radio':
              formattedItem = {
                survey_idx: index + 1,
                scale: surveyItem.value,
              };
              break;
          }

          if (formattedItem) {
            acc.push(formattedItem);
          }
          return acc;
        },
        []
      ),
    };
  };

  const renderDrawerContent = () => {
    const currentItem = POST_SURVEY_CONSTANT.find(
      (item) => item.id === currentItemId
    );

    if (!currentItem) return null;

    const currentValue = surveyData[currentItem.id].value;

    switch (currentItem.type) {
      case 'time':
        return (
          <TimeInput
            onChange={(value) => handleInputChange(currentItem.id, value)}
            value={currentValue}
          />
        );
      // case 'checkbox':
      //   return (
      //     <CheckboxGroup
      //       items={currentItem.checkboxItems || []}
      //       onChange={(value) => handleInputChange(currentItem.id, value)}
      //       value={currentValue}
      //     />
      //   );
      case 'radio':
        return (
          <RadioSelect
            items={currentItem.radioItems || []}
            onChange={(value) => handleInputChange(currentItem.id, value)}
            value={currentValue}
          />
        );
      case 'likert':
        const showNoWakeCheckbox = currentItem.id === 'sleep_recovery';
        const isNotWakeDefaultTrue =
          surveyData['sleep_continuity']?.value === 5;

        return (
          <LikertScale
            items={currentItem.likertItems || []}
            onChange={(value, isNoAwake) =>
              handleInputChange(currentItem.id, value, isNoAwake)
            }
            value={currentValue}
            showNoWakeCheckbox={showNoWakeCheckbox}
            isNoAwake={surveyData[currentItem.id].isNoAwake}
            isNotWakeDefaultTrue={isNotWakeDefaultTrue}
          />
        );

      default:
        return null;
    }
  };

  const onSubmit = async () => {
    try {
      if (formatDataForServer().data.length > 0) {
        await apiManager.upsertSurvey({
          session_day: formatDataForServer().session_day,
          session_id: user.session_id || '',
          userId: user.user_id || '',
          type: 'post',
          data: formatDataForServer().data as any,
        });
      }

      if (isFromStatistics) {
        navigate(-1);
        return;
      }

      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (surveyData['sleep_continuity']?.value === 5) {
      setSurveyData((prevData) => ({
        ...prevData,
        sleep_recovery: {
          ...prevData.sleep_recovery,
          isNoAwake: true,
          value: null,
          checked: true,
        },
      }));
    } else {
      setSurveyData((prevData) => ({
        ...prevData,
        sleep_recovery: {
          ...prevData.sleep_recovery,
          isNoAwake: false,
          value: prevData.sleep_recovery.value,
          checked: prevData.sleep_recovery.checked,
        },
      }));
    }
  }, [surveyData['sleep_continuity']?.value]);

  return (
    <>
      <PageContainer sx={{ position: 'relative' }}>
        <AppHeaderBar
          left={<LeftArrow onClick={() => navigate(-1)} />}
          center={
            <Typography variant="button1" color="#fff" minWidth="120px">
              수면 환경 기록하기
            </Typography>
          }
        />

        <Box
          sx={{
            position: 'absolute',
            top: `calc(${PAGE_CONTAINER_PADDING_HEIGHT} + ${APP_HEADER_HEIGHT})`,
            left: '0',
            width: '100%',
          }}
        >
          <DateDisplay />
        </Box>

        <Box
          mt="80px"
          borderTop={(theme) =>
            `1px solid ${theme.palette.semantic.theme.stroke}`
          }
        >
          {POST_SURVEY_CONSTANT.map((item) => (
            <CheckboxListItem
              key={item.id}
              index={
                POST_SURVEY_CONSTANT.findIndex((i) => i.id === item.id) + 1
              }
              text={item.title}
              checked={surveyData[item.id].checked}
              onClick={() => handleItemClick(item.id)}
            />
          ))}
        </Box>

        <Button sx={{ mt: 'auto' }} buttonSize="large" onClick={onSubmit}>
          {isFromStatistics ? '완료' : '종료'}
        </Button>
      </PageContainer>

      <CustomDrawer
        open={openDrawer}
        onClose={() => handleDrawer(false)}
        onOpen={() => handleDrawer(true)}
      >
        <Typography
          variant="subheadline"
          textAlign="center"
          color={(theme) => theme.palette.semantic.font.textOnLight}
        >
          {`${
            POST_SURVEY_CONSTANT.findIndex(
              (item) => item.id === currentItemId
            ) + 1
          }/${POST_SURVEY_CONSTANT.length}`}
        </Typography>
        <Typography
          variant="headline1"
          textAlign="center"
          mt="4px"
          color={(theme) => theme.palette.semantic.font.textOnLight}
        >
          {
            POST_SURVEY_CONSTANT.find((item) => item.id === currentItemId)
              ?.title
          }
        </Typography>

        <Box
          sx={{
            display: 'flex',
            height: '80%',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {renderDrawerContent()}
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            onClick={handlePrevious}
            disabled={currentItemId === POST_SURVEY_CONSTANT[0].id}
            sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
          >
            <ArrowRight3 style={{ transform: 'scaleX(-1)' }} />
            <Typography variant="headline2" color="#41474D">
              이전
            </Typography>
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
          >
            <Typography variant="headline2" color="#41474D">
              {currentItemId ===
              POST_SURVEY_CONSTANT[POST_SURVEY_CONSTANT.length - 1].id
                ? '완료'
                : '다음'}
            </Typography>
            <ArrowRight3 />
          </IconButton>
        </Box>
      </CustomDrawer>
    </>
  );
};

export default PostSurveyPage;
