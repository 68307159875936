import InteractiveBox from './InteractionEffect.styles';

type InteractionProps = {
  isActive: boolean;
};
const Interaction = ({ isActive }: InteractionProps) => {
  return <InteractiveBox isActive={isActive} />;
};

export default Interaction;
export type { InteractionProps };
