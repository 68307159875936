import moment from 'moment';

type TherapyData = {
  access_start: string;
  access_end: string;
  last_session_day: string | undefined;
  access_count: number;
};

export const getTherapyDay = (data: TherapyData): number => {
  const programNow = moment().subtract(12, 'hours');
  const programToday = programNow.format('YYYY-MM-DD');

  // 프로그램의 "오늘" 날짜와 마지막 세션 날짜 비교
  const shouldSubtract = programToday === data.last_session_day;

  // 감소시킬지 여부에 따라 access_count 조정
  const adjustedCount = shouldSubtract
    ? data.access_count - 1
    : data.access_count;

  // 1부터 7까지의 값 반환
  return (adjustedCount % 7) + 1;
};
