import React from 'react';
import { Box, Typography } from '@mui/material';
import AppHeaderBar from 'component/new/AppHeaderBar/AppHeaderBar';
import Button from 'component/new/Button/Button';
import { ReactComponent as LeftArrow } from 'assets/icon/arrow_left.svg';
import { ReactComponent as ArrowDownLong } from 'assets/icon/arrow-down.svg';
import { useNavigate } from 'react-router-dom';
import {
  GuideBox,
  GuideLabel,
  PressGuidePageContainer,
} from './PressGuide.styles';

const PressGuidePage = () => {
  const navigate = useNavigate();

  return (
    <PressGuidePageContainer>
      <AppHeaderBar left={<LeftArrow onClick={() => navigate(-1)} />} />

      <Typography
        margin="16px 0"
        variant="title3"
        whiteSpace="pre-line"
        color="#fff"
      >
        {'의식 모드 → 무의식 모드\n 순서로 진행할거예요'}
      </Typography>

      <Box sx={{ marginTop: '68px' }}>
        <GuideBox>
          <GuideLabel>
            <Typography variant="subheadlineBold">의식 모드</Typography>
          </GuideLabel>
          <Typography variant="body1" color="#fff">
            직접 화면을 누르고 떼며 호흡
          </Typography>
        </GuideBox>

        <Box display="flex" justifyContent="center">
          <ArrowDownLong />
        </Box>

        <GuideBox>
          <GuideLabel>
            <Typography variant="subheadlineBold">무의식 모드</Typography>
          </GuideLabel>
          <Typography
            variant="body1"
            color="#fff"
            whiteSpace="pre-line"
            textAlign="center"
          >
            {'의식모드를 기반으로\n 자동으로 재생되는 사운드에 맞춰 호흡'}
          </Typography>
        </GuideBox>
      </Box>

      <Box mt="auto">
        <Button
          buttonShape="rounded"
          buttonSize="large"
          sx={{ marginTop: '18px' }}
          onClick={() => navigate('/input-mode-select')}
        >
          다음
        </Button>
      </Box>
    </PressGuidePageContainer>
  );
};

export default PressGuidePage;
