import { useState } from 'react';

export const useDrawer = () => {
  const [open, setOpen] = useState(false);
  return {
    open,
    closeDrawer: () => {
      setOpen(false);
    },
    openDrawer: () => {
      setOpen(true);
    },
  };
};
