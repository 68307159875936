import styled from 'styled-components';

type CircleNumProps = {
  width?: string;
  height?: string;
  backgroundColor?: string;
  color?: string;
};

const StyledCircleNum = styled.div<CircleNumProps>`
  display: inline-block;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor || '#fff'};
  color: ${(props) => props.color || '#605a8e'};
  text-align: center;
  line-height: 1.5em;
  font-weight: bold;

  font-size: 12px;
  width: ${(props) => props.width || '1.5em'};
  height: ${(props) => props.height || '1.5em'};
`;

const CircleNumber = ({
  number,
  ...props
}: CircleNumProps & {
  number: number;
}) => {
  return <StyledCircleNum {...props}>{number}</StyledCircleNum>;
};

export default CircleNumber;
