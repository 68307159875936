import { Box, styled, Typography } from '@mui/material';
import { Checked, Plus } from 'component/new/icons';
import { baseColors } from '../../../styles/theme';

const ItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '18px 0',
  borderBottom: `1px solid ${theme.palette.semantic.theme.stroke}`,
}));

const IndexBox = styled(Box)({
  width: '24px',
  height: '24px',
  backgroundColor: baseColors.neutral.bw['white-alpha-30'],
  borderRadius: '6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '12px',
  fontSize: '14px',
  color: 'white',
});

type CheckboxListItemProps = {
  index: number;
  text: string;
  checked?: boolean;
  onClick?: () => void;
};

const CheckboxListItem = ({
  index,
  text,
  checked = false,
  onClick,
}: CheckboxListItemProps) => {
  return (
    <ItemContainer onClick={onClick}>
      <IndexBox>
        <Typography variant="subheadlineBold">{index}</Typography>
      </IndexBox>
      <Typography
        variant="subheadline"
        flexGrow={1}
        color={(theme) => theme.palette.semantic.font.textSecondary}
      >
        {text}
      </Typography>
      {checked ? <Checked /> : <Plus />}
    </ItemContainer>
  );
};

export default CheckboxListItem;
