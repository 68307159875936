import { Outlet, Navigate } from 'react-router-dom';
import { useUser } from 'hooks/auth/useUser';

const RequireAuth = () => {
  const { user } = useUser();

  return user.isAuthorized ? <Outlet /> : <Navigate to="/signin" />;
};

export default RequireAuth;
