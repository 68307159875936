import type { TouchModeName } from '../input-mode-select/InputModeSelect.page';

export const TUTORIAL_MESSAGES: {
  [key in TouchModeName]: {
    description1: string;
    description2: string;
    repeatMessage: string;
    after10Unconscious: string;
    howToToggleMode: string;
    unConsciousEntered: string;
    onScreen1: string;
    onScreen2: string;
    onScreenUnconscious: string;
  };
} = {
  'inhale-press': {
    description1: '화면을 지긋이 누르며\n 숨을 깊게 들이마십니다',
    description2: '손을 떼면서\n 숨을 천천히 내쉽니다',
    repeatMessage: '몇번 더 반복해볼까요?',
    after10Unconscious: '10회를 반복한 이후에\n 무의식모드로 전환할 수 있어요',
    howToToggleMode: '두번 가볍게 터치해\n  무의식 모드로 전환해볼까요?',
    unConsciousEntered: '무의식 모드로 전환되었어요\n 음악과 함께 호흡해보세요',
    onScreen1: '화면을 누르며\n 숨을 깊게 들이마십니다',
    onScreen2: '손을 떼면서\n 숨을 천천히 내쉽니다',
    onScreenUnconscious:
      '무의식 모드에서도\n 언제든 화면을 터치하여\n 호흡 속도를 조절할 수 있어요',
  },
  'exhale-press': {
    description1:
      '먼저 숨을 들이마신 후,\n 화면을 지긋이 누르며\n 숨을 내쉽니다',
    description2: '손을 떼면서\n 숨을 천천히 마십니다',
    repeatMessage: '몇번 더 반복해볼까요?',
    after10Unconscious: '10회를 반복한 이후에\n 무의식모드로 전환할 수 있어요',
    howToToggleMode: '두번 가볍게 터치해\n  무의식 모드로 전환해볼까요?',
    unConsciousEntered: '무의식 모드로 전환되었어요\n 음악과 함께 호흡해보세요',
    onScreen1: '화면을 누르며\n 숨을 천천히 내쉽니다',
    onScreen2: '손을 떼면서\n 숨을 깊게 들이마십니다',
    onScreenUnconscious:
      '무의식 모드에서도\n 언제든 화면을 터치하여\n 호흡 속도를 조절할 수 있어요',
  },
  'inhale-slide-up': {
    description1:
      '화면을 누른 채\n 손가락을 위로 천천히 움직이며\n 숨을 깊게 들이마십니다',
    description2:
      '화면을 누른 채\n 손가락을 아래로 천천히 움직이며\n 숨을 천천히 내쉽니다',
    repeatMessage: '몇번 더 반복해볼까요?',
    after10Unconscious: '10회를 반복한 이후에\n 무의식모드로 전환할 수 있어요',
    howToToggleMode: '두번 가볍게 터치해\n  무의식 모드로 전환해볼까요?',
    unConsciousEntered: '무의식 모드로 전환되었어요\n 음악과 함께 호흡해보세요',
    onScreen1:
      '화면을 누른 채\n 손가락을 위로 천천히 움직이며\n 숨을 깊게 들이마십니다',
    onScreen2:
      '화면을 누른 채\n 손가락을 아래로 천천히 움직이며\n 숨을 천천히 내쉽니다',
    onScreenUnconscious:
      '무의식 모드에서도\n 언제든 화면을 터치하여\n 호흡 속도를 조절할 수 있어요',
  },
};
