import { Box, styled } from '@mui/material';

const PressGuidePageContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '50px 24px 48px',
}));

const GuideBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.semantic.theme.containerFirst,
  borderRadius: '10px',
  padding: '20px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  alignItems: 'center',
}));

const GuideLabel = styled(Box)(({ theme }) => ({
  borderRadius: '50px',
  padding: '4px 10px',
  backgroundColor: '#fff',
  width: 'max-content',
}));

export { PressGuidePageContainer, GuideBox, GuideLabel };
