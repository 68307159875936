import React, { ChangeEvent, useState } from 'react';
import {
  PAGE_CONTAINER_PADDING_HEIGHT,
  PageContainer,
} from '../../styles/globalStyled/PageContainer';
import AppHeaderBar from 'component/new/AppHeaderBar/AppHeaderBar';
import { LeftArrow, Logo } from 'component/new/icons';
import { useNavigate } from 'react-router-dom';
import MyPageLink from './_components/MyPageLink';
import { Box, Divider, List } from '@mui/material';
import theme from '../../styles/theme';
import { useUser } from '../../hooks/auth/useUser';
import PushAgree from './_components/PushAgree';
import apiManager from '../../lib/api/apiManager';
import { expoBridge } from '../../modules/expo/expoBridge';
import TermsModal from '../signin/_components/TermsModal';
import TermsOfService from '../../component/new/terms/TermsOfService';

const MyPage = () => {
  const navigate = useNavigate();
  const { user, logout, setUser } = useUser();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onPushAgreeChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    try {
      if (checked) {
        expoBridge.enablePushNotification({ enabled: true });

        setTimeout(() => {
          const pushToken = expoBridge.getPushToken();
          apiManager.updateUserInfo({
            userId: user?.user_id || '',
            remark: pushToken || ('' as string),
          });
        }, 4000);

        // TODO: 푸시 알림 완성되면 아래 로컬 노티피케이션 지우기
        expoBridge.scheduleNotification({
          title: '오늘 밤 편안한 수면을 위한 준비가 되셨나요?',
          body: '',
          hour: 22,
          minute: 0,
          repeat: true,
        });
      }

      const response = await apiManager.updateUserInfo({
        userId: user?.user_id || '',
        push_yn: checked ? 'Y' : 'N',
      });

      if (response?.result_code === 1) {
        setUser({ ...user, push_yn: checked ? 'Y' : 'N' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PageContainer
        sx={{
          padding: `${PAGE_CONTAINER_PADDING_HEIGHT} 0 48px`,
        }}
      >
        <Box sx={{ padding: '0 24px' }}>
          <AppHeaderBar
            left={<LeftArrow onClick={() => navigate(-1)} />}
            center={<Logo />}
          />
        </Box>
        <List>
          <MyPageLink
            key="내 수면 통계"
            name="내 수면 통계"
            to="/statistics"
            noBorder
          />
          <Divider
            sx={{
              background: theme.palette.semantic.theme.containerFirst,
              height: '10px',
            }}
          />
          <PushAgree
            switchProps={{
              checked: user?.push_yn === 'Y',
              onChange: onPushAgreeChange,
            }}
          />
          <MyPageLink
            key="호흡 입력모드 변경"
            name="호흡 입력모드 변경"
            to="/input-mode-select?from=mypage"
            noBorder
          />
          <Divider
            sx={{
              background: theme.palette.semantic.theme.containerFirst,
              height: '10px',
            }}
          />

          <MyPageLink
            key="이용약관"
            name="이용약관"
            onClick={handleOpenModal}
            typographyVariant="body"
          />

          {/*<MyPageLink*/}
          {/*  key="앱정보"*/}
          {/*  name="앱정보"*/}
          {/*  to="/app-info"*/}
          {/*  typographyVariant="body"*/}
          {/*/>*/}

          <a
            target="_blank"
            href="http://pf.kakao.com/_vJxnRn/chat"
            style={{
              textDecoration: 'none',
            }}
          >
            <MyPageLink
              key="문의하기"
              name="문의하기"
              onClick={() => {}}
              typographyVariant="body"
              noBorder
            />
          </a>

          <Divider
            sx={{
              background: theme.palette.semantic.theme.containerFirst,
              height: '10px',
            }}
          />

          <MyPageLink
            key="로그아웃"
            name="로그아웃"
            typographyVariant="body"
            onClick={() => logout()}
            noBorder
          />
        </List>
      </PageContainer>

      <TermsModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title="이용약관"
        content={<TermsOfService />}
      />
    </>
  );
};

export default MyPage;
