import React from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CheckWithLabelProps } from './CheckWithLabel';

type TermsModalProps = {
  open: boolean;
  onClose: () => void;
  title: CheckWithLabelProps['label'];
  content: CheckWithLabelProps['termsContent'];
};

const TermsModal = ({ open, onClose, title, content }: TermsModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="terms-modal-title"
      aria-describedby="terms-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 3,
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            id="terms-modal-title"
            variant="h6"
            component="h2"
            color="text.primary" // 검은색 글자
            fontWeight="bold"
          >
            {title}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            // pr: 2,
            // '&::-webkit-scrollbar': {
            //   width: '0.4em',
            // },
            // '&::-webkit-scrollbar-track': {
            //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            //   webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            // },
            // '&::-webkit-scrollbar-thumb': {
            //   backgroundColor: 'rgba(0,0,0,.1)',
            //   outline: '1px solid slategrey',
            // },
          }}
        >
          <Typography
            id="terms-modal-description"
            color="text.secondary" // 검은색 글자 (조금 더 연한 색)
            sx={{ mt: 2 }}
          >
            {content}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
            }}
          >
            확인
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsModal;
