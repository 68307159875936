import { Box, styled } from '@mui/material';

const ModeLabel = styled(Box)(({ theme }) => ({
  padding: '4px 15px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '50px',
  width: 'max-content',
  backgroundColor: theme.palette.semantic.theme.buttonDefault,
}));

const CircleNum = styled(Box)(({ theme }) => ({
  width: '22px',
  height: '22px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#373351',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: 1,
  backgroundColor: theme.palette.semantic.theme.buttonDefault,
  verticalAlign: 'baseline',
  padding: 0,
}));

const Description = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}));

export { ModeLabel, CircleNum, Description };
