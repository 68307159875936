import { createTheme, ThemeOptions } from '@mui/material/styles';
import '../assets/fonts';

// Base color
const baseColors = {
  main: {
    violet: {
      900: '#3D3472',
      800: '#473591',
      700: '#4E3F9D',
      600: '#574AA9', // primary
      500: '#5D52B3',
      400: '#726DBF',
      300: '#8988CB',
      200: '#AAAADA',
      100: '#CBCBE8',
      '050': '#EAEAF6',
    },
    teal: {
      main: '#89B7C6',
      light: '#94CBDE',
    },
  },
  neutral: {
    bw: {
      black: '#000000',
      white: '#FFFFFF',
      'black-alpha-85': 'rgba(0, 0, 0, 0.85)',
      'black-alpha-70': 'rgba(0, 0, 0, 0.70)',
      'black-alpha-60': 'rgba(0, 0, 0, 0.60)',
      'black-alpha-50': 'rgba(0, 0, 0, 0.50)',
      'black-alpha-25': 'rgba(0, 0, 0, 0.25)',
      'white-alpha-90': 'rgba(255, 255, 255, 0.90)',
      'white-alpha-80': 'rgba(255, 255, 255, 0.80)',
      'white-alpha-70': 'rgba(255, 255, 255, 0.70)',
      'white-alpha-60': 'rgba(255, 255, 255, 0.60)',
      'white-alpha-50': 'rgba(255, 255, 255, 0.50)',
      'white-alpha-40': 'rgba(255, 255, 255, 0.40)',
      'white-alpha-30': 'rgba(255, 255, 255, 0.30)',
      'white-alpha-20': 'rgba(255, 255, 255, 0.20)',
      'white-alpha-15': 'rgba(255, 255, 255, 0.15)',
      'white-alpha-10': 'rgba(255, 255, 255, 0.10)',
      'white-alpha-4': 'rgba(255, 255, 255, 0.04)',
    },
    gray: {
      '050': '#0F1212',
      100: '#161A1C',
      200: '#1E2427',
      300: '#2F363A',
      400: '#41474D',
      500: '#5D636A',
      600: '#999FA3',
      700: '#D7DCDE',
      800: '#EAEDEE',
      900: '#F7F7F8',
    },
  },
  status: {
    error: '#F17785',
    notification: '#F7FA73',
  },
};

const palette = {
  primary: {
    main: baseColors.main.violet[600],
    light: baseColors.main.violet[400],
    dark: baseColors.main.violet[800],
  },
  secondary: {
    main: baseColors.main.teal.main,
    light: baseColors.main.teal.light,
  },
  error: {
    main: baseColors.status.error,
  },
  warning: {
    main: baseColors.status.notification,
  },

  semantic: {
    font: {
      textPrimary: baseColors.neutral.bw.white,
      textSecondary: baseColors.neutral.bw['white-alpha-80'],
      textSub: baseColors.neutral.bw['white-alpha-60'],
      textMuted: baseColors.neutral.bw['white-alpha-40'],
      textOnLight: baseColors.neutral.gray['050'],
      textOnLightMuted: baseColors.neutral.gray['050'],
      textOnLightSecondary: baseColors.neutral.bw['black-alpha-50'],
      textError: baseColors.status.error,
      textFocused: baseColors.main.teal.light,
    },
    theme: {
      buttonDefault: baseColors.neutral.bw['white-alpha-90'],
      buttonInactive: baseColors.neutral.bw['white-alpha-50'],
      buttonViolet: baseColors.main.violet[100],
      stroke: baseColors.neutral.bw['white-alpha-60'],
      strokeMuted: baseColors.neutral.bw['white-alpha-20'],
      strokeOnLight: baseColors.neutral.gray[700],
      containerFirst: baseColors.neutral.bw['white-alpha-10'],
      containerElevated: baseColors.neutral.bw['white-alpha-20'],
      containerPale: baseColors.neutral.bw['white-alpha-4'],
      containerMain: baseColors.main.teal.light,
      containerMainMuted: baseColors.main.teal.main + '30',
      containerOnLight: baseColors.neutral.gray[800],
      icon: baseColors.neutral.gray[800],
      iconDark: baseColors.neutral.gray[600],
      iconMuted: baseColors.neutral.bw['white-alpha-50'],
      filledGray: baseColors.neutral.gray[600],
      filledWhite: baseColors.neutral.bw.white,
    },
    background: {
      baseElevated: baseColors.neutral.bw['black-alpha-85'],
      baseLight: baseColors.neutral.gray['050'],
      deem: baseColors.neutral.bw['black-alpha-70'],
      deemLight: baseColors.neutral.bw['black-alpha-25'],
    },
  },
};

// 타이포그래피 정의
const typography = {
  fontFamily: 'Pretendard, sans-serif',
  largeTitle: {
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '42px',
    letterSpacing: '0.07px',
  },
  title1: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '38px',
    letterSpacing: '0.07px',
  },
  title2: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0.07px',
  },
  title3: {
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '-0.04px',
  },
  headline1: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '-0.04px',
  },
  headline2: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.04px',
  },
  subheadline: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '-0.1px',
  },
  subheadlineBold: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '19px',
    letterSpacing: '-0.1px',
  },
  body: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '-0.1px',
  },
  bodyBold: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '-0.1px',
  },
  button1: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '-0.1px',
  },
  button2: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '17px',
    letterSpacing: '-0.1px',
  },
  caption1: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '-0.1px',
  },
  caption1Bold: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '-0.1px',
  },
  caption2: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '13px',
    letterSpacing: '-0.15px',
  },
  caption2Bold: {
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '13px',
    letterSpacing: '-0.15px',
  },
};

// 커스텀 테마 옵션
const themeOptions: ThemeOptions = {
  palette,
  typography: {
    ...typography,
    h1: typography.largeTitle,
    h2: typography.title1,
    h3: typography.title2,
    h4: typography.title3,
    h5: typography.headline1,
    h6: typography.headline2,
    subtitle1: typography.subheadline,
    subtitle2: typography.subheadline,
    body: typography.body,
    button: typography.button1,
    caption: typography.caption1,
    overline: typography.caption2,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Pretendard';
          src: url('./fonts/Pretendard-Thin.woff2') format('woff2'),
               url('./fonts/Pretendard-Thin.woff') format('woff');
          font-weight: 100;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url('./fonts/Pretendard-ExtraLight.woff2') format('woff2'),
               url('./fonts/Pretendard-ExtraLight.woff') format('woff');
          font-weight: 200;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url('./fonts/Pretendard-Light.woff2') format('woff2'),
               url('./fonts/Pretendard-Light.woff') format('woff');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url('./fonts/Pretendard-Regular.woff2') format('woff2'),
               url('./fonts/Pretendard-Regular.woff') format('woff');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url('./fonts/Pretendard-Medium.woff2') format('woff2'),
               url('./fonts/Pretendard-Medium.woff') format('woff');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url('./fonts/Pretendard-SemiBold.woff2') format('woff2'),
               url('./fonts/Pretendard-SemiBold.woff') format('woff');
          font-weight: 600;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url('./fonts/Pretendard-Bold.woff2') format('woff2'),
               url('./fonts/Pretendard-Bold.woff') format('woff');
          font-weight: 700;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url('./fonts/Pretendard-ExtraBold.woff2') format('woff2'),
               url('./fonts/Pretendard-ExtraBold.woff') format('woff');
          font-weight: 800;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url('./fonts/Pretendard-Black.woff2') format('woff2'),
               url('./fonts/Pretendard-Black.woff') format('woff');
          font-weight: 900;
          font-style: normal;
          font-display: swap;
        }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          largeTitle: 'h1',
          title1: 'h2',
          title2: 'h3',
          title3: 'h4',
          headline1: 'h5',
          headline2: 'h6',
          subheadline: 'h6',
          subheadlineBold: 'h6',
          body: 'p',
          bodyBold: 'p',
          button1: 'span',
          button2: 'span',
          caption1: 'span',
          caption1Bold: 'span',
          caption2: 'span',
          caption2Bold: 'span',
        },
      },
    },
  },
};

// 테마 생성
const theme = createTheme(themeOptions);

export default theme;
export { baseColors };
