import { Howl } from 'howler';
// import { SoundNameEnum } from 'enums/sound-name.enum';
import { getAudioPlayer } from './audioPlayer';
import { getDayGroup } from '../../utils/getDayGroup';

const audioBuffers: { [key: string]: { howl: Howl; src: string } } = {};

export const fetchAudioBuffer = async (
  soundName: string,
  day: number
): Promise<{ src: string }> => {
  try {
    const importedSound = await import(
      `../../assets/sound/${soundName.toLowerCase()}.mp3`
    );
    const src = importedSound.default;
    return { src };
  } catch (error) {
    console.error(`Error fetching audio for ${soundName}:`, error);
    throw error;
  }
};

export const preloadAudio = async (
  sessionLength: number,
  day: number,
  options?: {
    isSampleAudio?: boolean;
  }
): Promise<void> => {
  const audioPlayer = getAudioPlayer(sessionLength);

  if (options?.isSampleAudio) {
    audioPlayer.isSampleAudio = true;
  }

  const breathTypes = ['INHALE', 'EXHALE'];
  const numbers = [1, 2, 3];

  const loadPromises = breathTypes.flatMap((breathType) =>
    numbers.map(async (num) => {
      let audioFilePath;
      let trackName;

      if (sessionLength <= 10) {
        audioFilePath = `${sessionLength}min/${breathType.toLowerCase()}${num}`;
        trackName = `${breathType}${num}_${sessionLength}MIN`;
      } else {
        const dayGroup = getDayGroup(day);
        audioFilePath = `${sessionLength}min/${dayGroup}/${breathType.toLowerCase()}${num}`;
        trackName = `${breathType}${num}_${sessionLength}MIN`;
      }

      try {
        const { src } = await fetchAudioBuffer(audioFilePath, day);
        console.log({ trackName, src });
        audioPlayer.createTrack(trackName, src);
      } catch (error) {
        console.error(`Error loading sound ${audioFilePath}:`, error);
      }
    })
  );

  await Promise.all(loadPromises);
  audioPlayer.markSessionAsLoaded(sessionLength);
};

export const getAudioBuffer = (soundName: string): Howl | undefined => {
  return audioBuffers[soundName]?.howl;
};
