import React, { useState } from 'react';
import AppHeaderBar from '../../../component/new/AppHeaderBar/AppHeaderBar';
import { ArrowRight3, LeftArrow } from '../../../component/new/icons';
import {
  PAGE_CONTAINER_PADDING_HEIGHT,
  PageContainer,
} from '../../../styles/globalStyled/PageContainer';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import DateDisplay from '../_components/DateDisplay';
import { APP_HEADER_HEIGHT } from 'component/new/AppHeaderBar/AppHeaderBar.styles';
import CheckboxListItem from '../_components/CheckboxList';
import { PRE_SURVEY_CONSTANT } from './constants';
import TimeInput from '../_components/TimeInput';
import CheckboxGroup from '../_components/CheckboxGroup';
import LikertScale from '../_components/LinkertScale';
import CustomDrawer from '../../../component/new/Drawer/Drawer';
import Button from 'component/new/Button/Button';
import apiManager from '../../../lib/api/apiManager';
import { useUser } from '../../../hooks/auth/useUser';
import moment from 'moment';
import { getSearchPram } from '../../../utils/getSearchPram';
import { POST_SURVEY_CONSTANT } from '../post-survey/constants';

type SurveyDataItem = {
  survey_idx: number;
  time?: string;
  codes?: string;
  scale?: number;
};

const PreSurveyPage = () => {
  const { user, generateSessionId } = useUser();
  const navigate = useNavigate();
  const therapyTime = getSearchPram('time');

  const isFromStatistics = getSearchPram('from') === 'statistics';

  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(PRE_SURVEY_CONSTANT[0].id);
  const [surveyData, setSurveyData] = useState<Record<string, any>>(
    PRE_SURVEY_CONSTANT.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: { checked: false, value: null },
      }),
      {}
    )
  );

  const handleItemClick = (id: string) => {
    setCurrentItemId(id);
    setOpenDrawer(true);
  };

  const handleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };

  const handleInputChange = (id: string, value: any) => {
    setSurveyData((prevData) => ({
      ...prevData,
      [id]: { ...prevData[id], value, checked: true },
    }));
  };

  const handlePrevious = () => {
    const currentIndex = PRE_SURVEY_CONSTANT.findIndex(
      (item) => item.id === currentItemId
    );
    if (currentIndex > 0) {
      setCurrentItemId(PRE_SURVEY_CONSTANT[currentIndex - 1].id);
    }
  };

  const handleNext = () => {
    const currentIndex = PRE_SURVEY_CONSTANT.findIndex(
      (item) => item.id === currentItemId
    );
    if (currentIndex < PRE_SURVEY_CONSTANT.length - 1) {
      setCurrentItemId(PRE_SURVEY_CONSTANT[currentIndex + 1].id);
    } else {
      handleDrawer(false);
      console.log(formatDataForServer());
    }
  };

  const formatDataForServer = () => {
    const currentMoment = moment();
    const currentDate = moment().format('YYYY-MM-DD');
    return {
      session_day: currentDate,
      type: 'pre',
      data: PRE_SURVEY_CONSTANT.reduce<SurveyDataItem[]>((acc, item, index) => {
        const surveyItem = surveyData[item.id];
        if (
          !surveyItem ||
          surveyItem.value === null ||
          surveyItem.value === undefined
        ) {
          return acc;
        }

        let formattedItem: SurveyDataItem | null = null;

        switch (item.type) {
          case 'time':
            const [period, time] = surveyItem.value.split(' ');
            const [hours, minutes] = time.split(':');
            let formattedHours = parseInt(hours);
            if (period === '오후' && formattedHours !== 12) {
              formattedHours += 12;
            } else if (period === '오전' && formattedHours === 12) {
              formattedHours = 0;
            }
            const formattedTime = `${formattedHours
              .toString()
              .padStart(2, '0')}:${minutes}:00.000`;

            // 잠든 시간이 현재 시간보다 늦으면 하루 전날로 처리
            const formattedMoment = moment(
              `${currentDate} ${formattedTime}`,
              'YYYY-MM-DD HH:mm:ss.SSS'
            );
            const adjustedDate = formattedMoment.isBefore(currentMoment)
              ? currentDate
              : moment(currentDate).subtract(1, 'days').format('YYYY-MM-DD');

            formattedItem = {
              survey_idx: index + 1,
              time: `${adjustedDate} ${formattedTime}`,
            };
            break;
          case 'checkbox':
            if (
              Array.isArray(surveyItem.value) &&
              surveyItem.value.length > 0
            ) {
              formattedItem = {
                survey_idx: index + 1,
                codes: surveyItem.value.join(','),
              };
            }
            break;
          case 'likert':
            formattedItem = {
              survey_idx: index + 1,
              scale: surveyItem.value,
            };
            break;
        }

        if (formattedItem) {
          acc.push(formattedItem);
        }
        return acc;
      }, []),
    };
  };

  const renderDrawerContent = () => {
    const currentItem = PRE_SURVEY_CONSTANT.find(
      (item) => item.id === currentItemId
    );
    if (!currentItem) return null;

    const currentValue = surveyData[currentItem.id].value;

    switch (currentItem.type) {
      case 'time':
        return (
          <TimeInput
            onChange={(value) => handleInputChange(currentItem.id, value)}
            value={currentValue}
          />
        );
      case 'checkbox':
        return (
          <CheckboxGroup
            items={currentItem.checkboxItems || []}
            onChange={(value) => handleInputChange(currentItem.id, value)}
            value={currentValue}
          />
        );
      case 'likert':
        return (
          <LikertScale
            items={currentItem.radioItems || []}
            onChange={(value) => handleInputChange(currentItem.id, value)}
            value={currentValue}
          />
        );
      default:
        return null;
    }
  };

  const onSubmit = async () => {
    try {
      if (formatDataForServer().data.length > 0) {
        await apiManager.upsertSurvey({
          session_day: formatDataForServer().session_day,
          session_id: user.session_id || '',
          userId: user.user_id || '',
          type: 'pre',
          data: formatDataForServer().data as any,
        });
      }

      generateSessionId();

      if (isFromStatistics) {
        navigate(-1);
        return;
      }

      navigate(`/loading-therapy?time=${therapyTime}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PageContainer sx={{ position: 'relative' }}>
        <AppHeaderBar
          left={<LeftArrow onClick={() => navigate(-1)} />}
          center={
            <Typography variant="button1" color="#fff" minWidth="120px">
              수면 환경 기록하기
            </Typography>
          }
        />

        <Box
          sx={{
            position: 'absolute',
            top: `calc(${PAGE_CONTAINER_PADDING_HEIGHT} + ${APP_HEADER_HEIGHT})`,
            left: '0',
            width: '100%',
          }}
        >
          <DateDisplay />
        </Box>

        <Box
          mt="80px"
          borderTop={(theme) =>
            `1px solid ${theme.palette.semantic.theme.stroke}`
          }
        >
          {PRE_SURVEY_CONSTANT.map((item) => (
            <CheckboxListItem
              key={item.id}
              index={PRE_SURVEY_CONSTANT.findIndex((i) => i.id === item.id) + 1}
              text={item.title}
              checked={surveyData[item.id].checked}
              onClick={() => handleItemClick(item.id)}
            />
          ))}
        </Box>

        <Button sx={{ mt: 'auto' }} buttonSize="large" onClick={onSubmit}>
          {isFromStatistics ? '완료' : '소닉테라피 시작하기'}
        </Button>
      </PageContainer>

      <CustomDrawer
        open={openDrawer}
        onClose={() => handleDrawer(false)}
        onOpen={() => handleDrawer(true)}
      >
        <Typography
          variant="subheadline"
          textAlign="center"
          color={(theme) => theme.palette.semantic.font.textOnLight}
        >
          {`${
            PRE_SURVEY_CONSTANT.findIndex((item) => item.id === currentItemId) +
            1
          }/${PRE_SURVEY_CONSTANT.length}`}
        </Typography>
        <Typography
          variant="headline1"
          textAlign="center"
          mt="4px"
          color={(theme) => theme.palette.semantic.font.textOnLight}
        >
          {PRE_SURVEY_CONSTANT.find((item) => item.id === currentItemId)?.title}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            height: '80%',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {renderDrawerContent()}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            onClick={handlePrevious}
            disabled={currentItemId === PRE_SURVEY_CONSTANT[0].id}
            sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
          >
            <ArrowRight3 style={{ transform: 'scaleX(-1)' }} />

            <Typography variant="headline2" color="#41474D">
              이전
            </Typography>
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
          >
            <Typography variant="headline2" color="#41474D">
              {currentItemId ===
              POST_SURVEY_CONSTANT[POST_SURVEY_CONSTANT.length - 1].id
                ? '완료'
                : '다음'}
            </Typography>
            <ArrowRight3 />
          </IconButton>
        </Box>
      </CustomDrawer>
    </>
  );
};

export default PreSurveyPage;
