import React, { useState } from 'react';
import { PageContainer } from '../../styles/globalStyled/PageContainer';
import AppHeaderBar from 'component/new/AppHeaderBar/AppHeaderBar';
import { ArrowRight2, Logo, Moon, Profile } from 'component/new/icons';
import { useNavigate } from 'react-router-dom';
import { Box, Tooltip, Typography } from '@mui/material';
import theme from '../../styles/theme';
import Button from 'component/new/Button/Button';
import { useUser } from '../../hooks/auth/useUser';
import moment from 'moment';
import StatisticsPart from './_components/StatisticsPart';

const HomePage = () => {
  const { user, generateSessionId } = useUser();
  const navigate = useNavigate();
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);

  const tooltipMessage =
    user.access_count && user.access_count >= 3
      ? '이제 바로 잠들기 모드를 사용할 수 있어요!'
      : '수면 소닉테라피를 3일 완료하면 이용할 수 있어요';

  const currentTherapyDay = moment().diff(user.access_start, 'days');

  const totalTherapyDay =
    moment(user.access_end).diff(user.access_start, 'days') + 1;

  const isAccessDaysAvailable =
    user.access_start !== undefined && user.access_end !== undefined;

  return (
    <PageContainer>
      <AppHeaderBar
        center={<Logo />}
        right={<Profile onClick={() => navigate('/mypage')} />}
      />
      {isAccessDaysAvailable ? (
        <Box
          sx={{
            mt: '20px',
            p: '4px 18px',
            borderRadius: '8px',
            backgroundColor: theme.palette.semantic.theme.containerFirst,
            width: 'max-content',
            display: 'flex',
            alignItems: 'center',
            verticalAlign: 'middle',
            color: theme.palette.semantic.font.textSecondary,
          }}
        >
          <Typography variant="subheadline">{`${totalTherapyDay}일 중`}</Typography>
          &nbsp;
          <Typography variant="subheadlineBold">{`${
            currentTherapyDay + 1
          }`}</Typography>
          &nbsp;
          <Typography variant="subheadline">{`일째 진행중`}</Typography>
        </Box>
      ) : null}

      <Typography mt="16px" color="#fff" whiteSpace="pre-line" variant="title1">
        {user.name
          ? `안녕하세요 ${user.name}님\n 지난 수면은 어떠셨나요?`
          : '안녕하세요\n 지난 수면은 어떠셨나요?'}
      </Typography>

      <StatisticsPart />

      <Box mt="auto" display="flex" flexDirection="column" gap="16px">
        <Tooltip
          title={tooltipMessage}
          placement="top"
          arrow
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
        >
          <span onClick={() => setIsTooltipOpen(true)}>
            <Button
              disabled={(user?.access_count ?? 0) < 3}
              buttonSize="large"
              buttonStyle="secondary"
              onClick={() => {
                generateSessionId();
                navigate('/loading-therapy?time=30&instant=true');
              }}
            >
              바로 잠들기
            </Button>
          </span>
        </Tooltip>
        <Button
          buttonSize="large"
          onClick={() => navigate('/therapy-selection')}
        >
          소닉 테라피 시작하기
        </Button>
      </Box>
    </PageContainer>
  );
};

export default HomePage;
