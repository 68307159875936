export type SurveyType = 'pre' | 'post' | 'both' | undefined;

const getSurveyType = (result: { [x: string]: any }): SurveyType => {
  // 결과가 비어있거나 유효하지 않은 경우
  if (
    !result ||
    typeof result !== 'object' ||
    Object.keys(result).length === 0
  ) {
    return undefined;
  }

  // 결과 객체의 키(날짜)를 가져옴
  const date = Object.keys(result)[0];

  // 해당 날짜의 데이터가 존재하는지 확인
  if (result[date]) {
    const hasPre = 'pre' in result[date];
    const hasPost = 'post' in result[date];

    if (hasPre && hasPost) {
      return 'both';
    } else if (hasPre) {
      return 'pre';
    } else if (hasPost) {
      return 'post';
    }
  }

  // 'pre'나 'post' 둘 다 없으면 undefined 반환
  return undefined;
};

export default getSurveyType;
