import React, { ReactNode, useState } from 'react';
import Checkbox, { CheckboxProps } from 'component/new/Checkbox';
import { Box, Typography } from '@mui/material';
import { ArrowRight3 } from '../../../component/new/icons';
import TermsModal from './TermsModal'; // Import the new TermsModal component

type CheckWithLabelProps = {
  label: string | ReactNode;
  checkboxProps?: CheckboxProps;
  checkboxName: string;
  termsContent?: ReactNode;
};

const CheckWithLabel = ({
  label,
  checkboxName,
  checkboxProps,
  termsContent,
}: CheckWithLabelProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap="13px"
        component="label"
        htmlFor={checkboxName}
      >
        <Checkbox iconColor="default" {...checkboxProps} />

        {termsContent ? (
          <Box
            onClick={handleOpenModal}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="body1"
              id={checkboxName}
              style={{ marginLeft: '8px' }}
            >
              {label}
            </Typography>
            <ArrowRight3 />
          </Box>
        ) : (
          <Typography
            variant="body1"
            id={checkboxName}
            style={{ marginLeft: '8px' }}
          >
            {label}
          </Typography>
        )}
      </Box>

      <TermsModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={label}
        content={termsContent}
      />
    </>
  );
};

export default CheckWithLabel;
export type { CheckWithLabelProps };
