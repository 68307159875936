import { useEffect, useRef } from 'react';
import nipplejs from 'nipplejs';

interface NippleProps {
  onUp: () => void;
  onDown: () => void;
  threshold?: number;
}

export default function Nipple({ onUp, onDown, threshold = 10 }: NippleProps) {
  const nippleContainerRef = useRef<HTMLDivElement>(null);
  const managerRef = useRef<nipplejs.JoystickManager | null>(null);
  const onUpRef = useRef(onUp);
  const onDownRef = useRef(onDown);
  const lastYRef = useRef<number | null>(null);
  const movementDirectionRef = useRef<'up' | 'down' | null>(null);
  const thresholdRef = useRef(threshold);

  useEffect(() => {
    onUpRef.current = onUp;
    onDownRef.current = onDown;
    thresholdRef.current = threshold;
  }, [onUp, onDown, threshold]);

  useEffect(() => {
    const handleStart = () => {
      lastYRef.current = null;
      movementDirectionRef.current = null;
    };

    const handleMove = (
      evt: nipplejs.EventData,
      data: nipplejs.JoystickOutputData
    ) => {
      const currentY = data.position.y;

      if (lastYRef.current === null) {
        lastYRef.current = currentY;
        return;
      }

      const difference = currentY - lastYRef.current;
      const absDifference = Math.abs(difference);

      if (absDifference > thresholdRef.current) {
        const newDirection = difference < 0 ? 'up' : 'down';

        if (newDirection !== movementDirectionRef.current) {
          movementDirectionRef.current = newDirection;
          if (newDirection === 'up') {
            onUpRef.current();
          } else {
            onDownRef.current();
          }
        }

        lastYRef.current = currentY;
      }
    };

    const handleEnd = () => {
      lastYRef.current = null;
      movementDirectionRef.current = null;
    };

    if (nippleContainerRef.current && !managerRef.current) {
      const options: nipplejs.JoystickManagerOptions = {
        zone: nippleContainerRef.current,
        mode: 'dynamic',
        color: 'transparent',
        size: 600,
        threshold: 0.1,
      };

      managerRef.current = nipplejs.create(options);
      managerRef.current.on('start', handleStart);
      managerRef.current.on('move', handleMove);
      managerRef.current.on('end', handleEnd);

      return () => {
        managerRef.current?.destroy();
        managerRef.current = null;
      };
    }
  }, []);

  return (
    <div
      ref={nippleContainerRef}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 1,
      }}
    />
  );
}
