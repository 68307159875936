import { styled } from '@mui/material/styles';
import { Box, Theme } from '@mui/material';

const StyledBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: '#FCFCFC',
  padding: '24px 24px 34px 24px',
  height: '100%',
  overflow: 'auto',
  borderRadius: '20px 20px 0 0',
}));

export { StyledBox };
