import React, { ComponentType } from 'react';
import { Box, styled, Typography } from '@mui/material';
import {
  Alcohol,
  Coffee,
  Nap,
  SleepingPill,
  Smoke,
} from '../../../component/new/icons';

const GroupContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const ItemsContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
});

type CheckboxItem = {
  id: string;
  value: number;
  name: string;
};

type CheckboxGroupProps = {
  items: CheckboxItem[];
  onChange: (value: number[]) => void;
  value: number[] | null;
};

const DefaultIcon: ComponentType = () => <div>?</div>;

const ICON_MAP: { [key: string]: ComponentType } = {
  alcohol: Alcohol,
  caffeine: Coffee,
  nap: Nap,
  smoking: Smoke,
  sleeping_pill: SleepingPill,
};

const CheckboxGroup = ({ items, onChange, value }: CheckboxGroupProps) => {
  const handleChange = (changedValue: number) => {
    const newValue = value ? [...value] : [];
    const index = newValue.indexOf(changedValue);
    if (index > -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(changedValue);
    }
    onChange(newValue);
  };

  return (
    <GroupContainer>
      <ItemsContainer>
        {items.map((item) => {
          const IconComponent = ICON_MAP[item.id] || DefaultIcon;
          return (
            <CheckboxImageList
              key={item.id}
              id={item.id}
              value={item.value}
              name={item.name}
              isSelected={value ? value.includes(item.value) : false}
              onChange={handleChange}
              Icon={IconComponent}
            />
          );
        })}
      </ItemsContainer>
    </GroupContainer>
  );
};

export default CheckboxGroup;

const ItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '12px',
  borderRadius: '12px',
}));

const ImageContainer = styled(Box)<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    width: '60px',
    height: '60px',
    padding: '12px',
    backgroundColor: theme.palette.grey[200],
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',

    ...(isSelected
      ? {
          backgroundColor: '#DCEDF3',
          outline: `1px solid #89B7C6`,
        }
      : {}),
  })
);

type CheckboxListItemProps = {
  id: string;
  value: number;
  name: string;
  isSelected: boolean;
  onChange: (value: number) => void;
  Icon: ComponentType;
};

const CheckboxImageList = ({
  id,
  value,
  name,
  isSelected,
  onChange,
  Icon,
}: CheckboxListItemProps) => {
  return (
    <ItemContainer
      onClick={() => {
        onChange(value);
      }}
    >
      <ImageContainer isSelected={isSelected}>
        <Icon />
      </ImageContainer>
      <Typography
        mt="6px"
        variant={isSelected ? 'bodyBold' : 'body'}
        align="center"
        color={isSelected ? '#89B7C6' : '#212931'}
      >
        {name}
      </Typography>
    </ItemContainer>
  );
};
