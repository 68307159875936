import { Dialog as MuiDialog, styled } from '@mui/material';

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.60)',

  '& .MuiDialog-paper': {
    minWidth: '327px',
    width: '80%',
    borderRadius: '20px',
    // backgroundColor: '#393939', // TODO: 색상 물어보기. 기존 디자인 색깔이 너무 연하여 임의로 지정해 놓음
    background: 'rgba(255, 255, 255, 0.10)',
    backdropFilter: 'blur(20px)',
    padding: '24px',
  },
}));

const DialogHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginTop: '48px',
}));

export { StyledDialog, DialogHeader, ButtonContainer };
