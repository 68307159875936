export type NotificationPrams = {
  title: string;
  body: string;
  color?: string;
  hour: number;
  minute: number;
  repeat?: boolean;
};

export type PushNotificationParams = {
  enabled: boolean;
};

export type ReactNativeWebView = {
  postMessage: (message: string) => void;
};

export const LAST_SESSION_DATE = 'last_session_date';
export const PUSH_TOKEN = 'pushToken';
export const ACCESS_TOKEN = 'accessToken';
export const USER_ID = 'userId';

class ExpoBridge {
  private static instance: ExpoBridge;
  private constructor() {}

  public static getInstance(): ExpoBridge {
    if (!ExpoBridge.instance) {
      ExpoBridge.instance = new ExpoBridge();
    }
    return ExpoBridge.instance;
  }

  private postMessage(message: object) {
    if (
      window.ReactNativeWebView &&
      'postMessage' in window.ReactNativeWebView
    ) {
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } else {
      console.error('ReactNativeWebView is not available');
    }
  }

  public scheduleNotification(params: NotificationPrams) {
    this.postMessage({
      type: 'scheduleNotification',
      params,
    });
  }

  public cancelAllNotifications() {
    this.postMessage({
      type: 'cancelAllNotifications',
    });
  }

  public setItem(
    key: typeof LAST_SESSION_DATE | typeof ACCESS_TOKEN | typeof USER_ID,
    value: string
  ) {
    this.postMessage({
      type: 'setItem',
      key,
      value,
    });
  }

  public enablePushNotification(pushParams: PushNotificationParams) {
    this.postMessage({
      type: 'enablePushNotification',
      pushParams,
    });
  }

  public getPushToken() {
    return localStorage.getItem(PUSH_TOKEN) || undefined;
  }

  public isPushNotificationEnabled(): boolean {
    return !!this.getPushToken();
  }
}

export const expoBridge = ExpoBridge.getInstance();
