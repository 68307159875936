import { Box, CircularProgress, Typography } from '@mui/material';

const Loader = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="background.default"
    >
      <CircularProgress size={60} thickness={4} color="primary" />
      <Typography variant="h6" style={{ marginTop: 16 }}>
        Loading...
      </Typography>
    </Box>
  );
};

export default Loader;
