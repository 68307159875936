let audioContext: AudioContext | null = null;

export const getAudioContext = (): AudioContext => {
  if (!audioContext) {
    audioContext = new (AudioContext || (window as any).webkitAudioContext)();
  }

  return audioContext;
};

export const resumeAudioContext = async (): Promise<void> => {
  const audioContext = getAudioContext();
  if (audioContext.state === 'suspended') {
    await audioContext.resume();
  }
};

export const createAudioContextManager = () => {
  return {
    getAudioContext,
    resumeAudioContext,
  };
};

export default createAudioContextManager();
