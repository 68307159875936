import { ChangeEvent, useEffect, useState } from 'react';

export const useLoginForm = () => {
  const [{ id, password, autoLogin }, setForm] = useState({
    id: '',
    password: '',
    autoLogin: false,
  });
  const [error, setError] = useState('');

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;

    if (name === 'autoLogin') {
      localStorage.setItem('autoLogin', checked.toString());
      setForm((prev) => ({
        ...prev,
        autoLogin: checked,
      }));
      return;
    }

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const isAutoLogin = localStorage.getItem('autoLogin') === 'true';
    setForm((prev) => ({
      ...prev,
      autoLogin: isAutoLogin,
    }));
  }, []);

  return {
    id,
    password,
    autoLogin,
    error,
    setError,
    onInputChange,
  };
};
