import React, { useEffect, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { ArrowRight2, Moon } from '../../../component/new/icons';
import getSurveyType, { SurveyType } from '../../../utils/getSurveyType';
import { LAST_SESSION_DATE } from '../../../modules/expo/expoBridge';
import { LAST_THERAPY_TIME } from '../../_constants';
import moment from 'moment/moment';
import apiManager from '../../../lib/api/apiManager';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../hooks/auth/useUser';

const isWithin24Hours = (dateString: string | null): boolean => {
  if (!dateString) return false;
  const lastSession = moment(dateString, 'YYYY-MM-DD HH:mm:ss');
  const now = moment();
  const diffHours = now.diff(lastSession, 'hours');
  return diffHours < 24;
};

const StatisticsPart = () => {
  const [yesterdaySurveyType, setYesterdaySurveyType] = useState<SurveyType>();
  const [lastTherapyTime, setLastTherapyTime] = useState('');
  const [isTherapyDone, setIsTherapyDone] = useState(false);
  const [under3SessionMessage, setUnder3SessionMessage] = useState('');
  const { user } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    const lastSessionDate = localStorage.getItem(LAST_SESSION_DATE);
    const lastTherapyTime = localStorage.getItem(LAST_THERAPY_TIME);
    const isTherapyDone = isWithin24Hours(lastSessionDate);

    const yesterday = moment().subtract(1, 'days');

    const checkSurvey = async () => {
      try {
        const result = await apiManager.getUserSurvey(
          user.user_id || '',
          yesterday,
          moment()
        );

        const surveyType = getSurveyType(result as any);

        setYesterdaySurveyType(surveyType);
        setIsTherapyDone(isTherapyDone);
        setLastTherapyTime(lastTherapyTime ?? '');

        const isSleepTherapy =
          lastTherapyTime === '30' || lastTherapyTime === '60';

        if (!user?.st_score && isSleepTherapy) {
          setUnder3SessionMessage('3일 이상 기록을 입력하면 확인할 수 있어요!');
        }
      } catch (error) {
        console.log(error);
      }
    };

    checkSurvey();
  }, []);

  return !!lastTherapyTime && isTherapyDone ? (
    <Box
      sx={{
        mt: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Moon />
        <Typography
          variant="body"
          color={({ palette }) => palette.semantic.font.textSecondary}
          lineHeight={1}
          display="flex"
          alignItems="center"
        >
          {user.name ? `${user.name}님의 수면 점수` : '수면 점수'}
        </Typography>
      </Box>

      <Box
        sx={{
          padding: '6px 10px',
          borderRadius: '50px',
          backgroundColor: ({ palette }) =>
            palette.semantic.theme.containerFirst,
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        {user.st_score ? (
          <Typography
            onClick={() => navigate('/statistics')}
            variant="subheadline"
            color={({ palette }) => palette.semantic.font.textSecondary}
            lineHeight="15px"
          >{`${user.st_score} 점`}</Typography>
        ) : (
          <Tooltip
            arrow
            title={under3SessionMessage}
            open={!!under3SessionMessage}
            placement="bottom-start"
          >
            <Typography
              onClick={() => {
                const isSleepTherapy =
                  lastTherapyTime === '30' || lastTherapyTime === '60';
                switch (yesterdaySurveyType) {
                  case undefined:
                    if (isSleepTherapy) {
                      navigate('/pre-survey');
                    } else {
                      navigate('/statistics');
                    }
                    break;
                  case 'post':
                    if (isSleepTherapy) {
                      navigate('/pre-survey');
                    } else {
                      navigate('/statistics');
                    }
                    break;
                  case 'both':
                    navigate('/statistics');
                    break;
                  default:
                    navigate('/statistics');
                    break;
                }
              }}
              variant="subheadline"
              color={({ palette }) => palette.semantic.font.textSecondary}
              lineHeight="15px"
            >
              기록하고 점수보기
            </Typography>
          </Tooltip>
        )}
        <ArrowRight2 />
      </Box>
    </Box>
  ) : null;
};

export default StatisticsPart;
