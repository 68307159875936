import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

const TermsOfService = () => {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {}
  );

  const toggleSection = (sectionId: string | number) => {
    setOpenSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  const renderSection = (
    sectionId: string,
    title: string,
    content: ReactNode
  ) => (
    <div className="section" key={sectionId}>
      <h2 onClick={() => toggleSection(sectionId)} className="section-title">
        {title}
        <span className="toggle-icon">
          {openSections[sectionId] ? '▼' : '▶'}
        </span>
      </h2>
      {openSections[sectionId] && (
        <div className="section-content">{content}</div>
      )}
    </div>
  );

  return (
    <StyledTermsOfService>
      <div className="terms-of-service">
        {renderSection(
          'purpose',
          '제1조 (목적)',
          <p>
            본 약관은 주식회사 벨테라퓨틱스(이하 "회사"라 함)가 제공하는 불면증
            디지털치료기기 소프트웨어 "뮤지토닌" 및 이에 부수하는 제반 서비스의
            이용과 관련하여 회사와 회원의 권리, 의무 및 책임사항, 기타 필요한
            사항을 규정함을 목적으로 합니다.
          </p>
        )}

        {renderSection(
          'definitions',
          '제2조 (용어의 정의)',
          <>
            <p>➀ 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
            <ul>
              <li>
                "회원"은 이 약관에 따라 이용 계약을 체결하고, 회사가 제공하는
                서비스를 이용하는 자를 의미합니다.
              </li>
              <li>"의료진"은 ~~~ </li>
              <li>
                "서비스"는 이용중인 장치와 상관없이 회사가 회원에게 불면증
                디지털치료제로써 제공하는 "뮤지토닌" 모바일 어플리케이션(이하
                "앱"이라 함)및 이에 부수하는 제반 서비스로, "뮤지토닌"과 관련된
                서비스 일체를 의미합니다.
              </li>
              <li>
                "모바일 기기"는 앱, 콘텐츠를 다운로드 받거나 설치하여 사용할 수
                있는 기기로서 스마트폰, 태블릿 등을 의미합니다.
              </li>
              <li>
                "아이디"는 회원의 식별과 서비스 이용을 위하여 의료회원가입시
                회원이 기입하는 고유 문자열을 의미합니다.
              </li>
              <li>
                "비밀번호"는 회원의 동일성 확인과 회원의 권익 및 비밀 보호를
                위하여 서비스에 등록한 문자나 숫자 혹은 그 조합을 의미합니다.
              </li>
              <li>
                "콘텐츠"는 회사가 회원에게 제공하는 부호, 문자, 음성, 음향,
                화상, 영상, 도형, 색체, 이미지 등(이들의 복합체도 포함) 회사가
                서비스 제공과 관련하여 디지털 방식으로 제작한 내용물 일체를
                의미합니다.
              </li>
            </ul>
            <p>
              ➁ 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을
              제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에
              정하지 아니한 것은 일반적인 상관례에 따릅니다.
            </p>
          </>
        )}

        {renderSection(
          'effect',
          '제3조 (약관의 효력 및 변경)',
          <>
            <p>
              ➀ 회사가 본 약관의 내용을 회사 홈페이지 또는 앱 화면에 게재하거나
              기타 방법(이메일, 로그인 시 동의창 등)으로 이용자에게 통지하고, 그
              내용에 동의한 이용자가 본 서비스에 가입하여 회원이 되었을 때 본
              약관의 효력이 발생합니다.
            </p>
            <p>
              ➁ 회사는 필요한 경우 관계 법령을 위반하지 않는 범위에서 본 약관을
              변경할 수 있으며, 약관 변경 시에는 적용일자 및 개정사유를 명시하여
              현행약관과 함께 제1항의 방법으로 그 적용일자 7일 전부터 적용일자
              전일까지 공지 또는 통지합니다. 단, 회원에게 불리한 내용으로 변경
              시에는 적용일자 30일 전에 위 방법으로 공지 또는 통지하고 제 18조
              제1항의 방법으로 회원에게 통지합니다.
            </p>
            <p>
              ➂ 회사는 제2항에 따라 변경된 약관을 공지하거나 통지하면서,
              회원에게 약관 변경 적용 일까지 거부 의사를 표시하지 않으면 약관의
              변겨에 동의한 것으로 간주했다는 내용을 공지 또는 통지하였음에도
              회원이 명시적으로 약관 변경에 대한 거부 의사를 표시하지 않았을 때
              회원이 변경 약관에 동의한 것으로 간주합니다. 변경 약관에 동의하지
              않는 회원에 대하여 서비스 이용이 불가할 수 있으며, 회원은 서비스
              이용을 중단하고 이용 계약을 해지할 수 있습니다.
            </p>
          </>
        )}

        {renderSection(
          'contract',
          '제4조 (이용계약의 체결 및 적용)',
          <>
            <p>
              ➀ 본 서비스를 이용하고자 하는 자(이하 "가입신청자"라 함)가 이
              약관의 내용에 대하여 동의를 한 다음 서비스 이용 신청을 하고,
              회사가 그 신청에 대해서 승낙하면 본 서비스의 이용계약이
              체결됩니다.
            </p>
            <p>
              ➁ 회사는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는
              승낙을 거절 또는 유보할 수 있습니다.
            </p>
            <ul>
              <li>
                신청 목적이 서비스(불면증 디지털 치료제) 사용 목적이 아닌 경우
              </li>
              <li>
                회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인
                방법을 통해 서비스를 이용하는 경우
              </li>
              <li>
                관련 법령에서 금지하는 행위 또는 사회의 안녕과 질서 또는
                미풍양속을 저해할 목적으로 서비스 이용을 신청하는 경우
              </li>
              <li>영리를 추구할 목적으로 서비스를 이용하고자 하는 경우</li>
              <li>이용약관 위반으로 이용 계약이 해지된 이후 재가입하는 경우</li>
              <li>가입신청자가 만 19세 미만인 경우</li>
              <li>
                회사의 인적, 물적 설비에 여유가 없거나 서비스 제공에 기술상,
                업무상 지장이 있는 경우
              </li>
              <li>이미 이용계약이 체결된 이용자가 이용신청을 하는 경우</li>
              <li>
                기타 이 약관에 위배되거나 위법 또는 부당한 이용신청인 경우 및
                회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우
              </li>
            </ul>
          </>
        )}

        {renderSection(
          'external',
          '제5조 (약관 외 준칙)',
          <>
            <p>
              ➀ 회사는 필요한 경우 특정 서비스에 관하여 적용될 개별약관을 정하여
              제18조 제1항 또는 제2항에 따라 공지할 수 있으며, 개별약관에 대한
              동의는 회원이 특정 서비스를 최초로 이용할 경우 별도의 동의 절차를
              거치게 됩니다. 이 경우 개별 서비스에 대한 이용약관이 본 약관에
              우선합니다.
            </p>
            <p>
              ➁ 본 약관 또는 개별약관에서 정하지 않은 사항과 이 약관의 해석에
              관해서는 관계 법령 또는 일반적인 상관례에 따릅니다.
            </p>
          </>
        )}

        {renderSection(
          'privacy',
          '제6조 (개인정보의 보호)',
          <p>
            회사는 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
            노력하며, 개인정보의 보호 및 사용에 대해서는 관계 법령 및 회사의
            개인정보처리방침에 따릅니다. 다만, 회사가 제공하는 서비스 이외의
            링크된 서비스에 대해서는 회사의 개인정보처리방침이 적용되지
            않습니다.
          </p>
        )}

        {renderSection(
          'memberDuty',
          '제7조(회원의 의무)',
          <>
            <p>➀ 회원은 다음 각 호의 행위를 하여서는 안 됩니다.</p>
            <ul>
              <li>
                서비스의 신청 또는 변경 시 허위정보, 또는 타인의 정보를 사용하는
                행위
              </li>
              <li>회사가 게시한 정보의 변경 또는 서비스에 장애를 주는 행위</li>
              <li>
                리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를
                통하여 서비스를 복재, 분해 또는 모방 기타 변형하는 행위
              </li>
              <li>
                자동 접속 프로그램 등을 사용하는 등 정상적인 용법과는 다른
                방법으로 서비스를 이용하여 회사의 서버에 부하를 일으켜 회사의
                정상적인 서비스를 방해하는 행위
              </li>
              <li>
                본인 아닌 제3자에게 계정(아이디, 비밀번호)을 대여, 양도하는 등
                접속 권한을 부여하는 행위
              </li>
              <li>회사외 기타 제3자의 저각권 등 지적재산권에 대한 침해</li>
              <li>
                회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
              </li>
              <li>
                도박 등 사행행위를 하거나 유도하는 행위, 음란/저속한 정보를 교류
                및 게재하고나 음란 사이트를 연결(링크)하는 행위, 수치침/혐오감
                또는 공포심을 일으키는 말/소리/글/그림/사진 또는 영상을 타인에게
                전송 또는 유포하는 행위 등 서비스를 불건전하게 이용하는 행위
              </li>
              <li>
                회사의 동의 없이 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등
                본래의 용도 이외의 용도로 서비스를 이용하는 행위
              </li>
              <li>
                회사의 서비스를 이용하여 얻은 정보를 무단으로
                복제/유통/조장하거나 상업적으로 이용하는 행위, 알려지가나
                알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
              </li>
              <li>기타 불법적이거나 부당한 수단과 목적에 의한 행위</li>
            </ul>
            <p>
              ➁ 회원은 사이트에 등록된 본인의 회원 계정에 관한 접근 권한 및 접근
              수단을 안전하게 관리하여야 하며, 회원의 귀책 사유로 인하여 발생한
              계정의 도용과 서비스의 이용에 대한 책임을 부담합니다.
            </p>
            <p>
              ➂ 회원은 본 약관 및 회사가 서비스와 관련하여 고지하는 내용을
              준수하여야 하며, 약관 및 고지 내용을 위반하거나 이행하지 아니하여
              발생하는 손실, 손해에 대하여 책임을 부담합니다.
            </p>
          </>
        )}

        {renderSection(
          'serviceProvision',
          '제8조 (서비스의 제공)',
          <>
            <p>
              ➀ 회사는 제4조의 규정에 따라 이용계약이 체결된 회원에게 그 즉시
              서비스를 이용할 수 있도록 합니다. 다만, 일부 서비스의 경우 회사의
              필요 혹은 의료진의 권장에 따라 지정된 일자부터 서비스를 개시할 수
              있습니다.
            </p>
            <p>
              ➁ 회사는 회원에게 서비스를 제공할 때 이 약관이 정하고 있는
              서비스를 포함하여 기타 부가적인 서비스를 함꼐 제공할 수 있습니다.
            </p>
          </>
        )}

        {renderSection(
          'serviceUsage',
          '제9조 (서비스의 이용)',
          <>
            <p>
              ➀ 서비스 제공은 회서의 업무상 또는 기술상 특별한 지장이 없는 한
              연중무휴 1일 24시간을 원칙으로 합니다.
            </p>
            <p>
              ➁ 제1항에도 불구하고 회사는 다음 각 호의 경우에는 서비스의 전부
              또는 일부를 중단하거나 일시 정지할 수 있습니다. 이 경우 회사는
              사전에 그 정지의 사유와 기간을 앱 초기화면이나 서비스 공지사항
              등에 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는
              경우 사후에 공지할 수 있습니다.
            </p>
            <ul>
              <li>
                시스템 정기점검, 서버의 증설 및 교체서비스용 설비의 교체 등의
                시스템 운영상 필요한 경우
              </li>
              <li>
                정전, 서비스 설비의 장애, 서비스 이용 폭주, 기간통신사업자의
                설비 또는 보수 또는 점검, 네트워크 불안정 등으로 인하여 정상적인
                서비스 제공이 불가능한 경우
              </li>
              <li>
                전시, 사변, 천재지변, 역병, 화재, 홍수, 정부의 조치 또는 이에
                준하는 불가항력 등 회사가 통제할 수 없는 상황이 발생한 경우
              </li>
            </ul>
            <p>
              ➂ 회사는 앱을 이용하여 서비스를 제공합니다. 회원은 앱 다운로드를
              통해 설치하여 서비스를 이용할 수 있습니다.
            </p>
            <p>
              ④ 다운로드하여 살치한 앱 또는 네트워크를 통해 이용하는 서비스의
              경우에는 모바일 기기 또는 이동통신사의 특성에 맞도록 제공됩니다.
              모바일 기기 또는 번호 변경 또는 해외 로밍의 경우에는 콘텐츠의 전부
              또는 일부의 이용이 불가능할 수 있으며, 이 경우 회사는 책임을 지지
              않습니다.
            </p>
            <p>
              ⑤ 다운로드하여 설치한 앱 또는 네트워크를 통해 이용하는 서비스의
              경우에는 백그라운드 작업이 진행될 수 있습니다. 이 경우 모바일 기기
              또는 이동통신사의 특성에 맞도록 추가요금이 발생할 수 있으며 이와
              관련하여 회사는 책임을 지지 않습니다.
            </p>
          </>
        )}

        {renderSection(
          'serviceChange',
          '제10조 (서비스의 변경 및 중단)',
          <>
            <p>
              ➀ 회사는 원활한 서비스 제공을 위해 운영상 또는 기술상의 필요에
              따라 서비스를 변경할 수 있으며, 변경 전에 해당 내용을 서비스 내에
              공지합니다. 다만, 버그나 오류 등의 수정이나 긴급 업데이트 등
              부득이하게 변경할 필요가 있는 경우 또는 중대한 변경에 해당하지
              않는 경우에는 사후에 공지할 수 있습니다.
            </p>
            <p>
              ➁ 회사는 영업양도, 분할, 합병 등에 따른 영업의 폐지, 콘텐츠 제공의
              계약 만료, 당해 서비스의 현저한 수익 악화 등 경영상의 중대한
              사유로 인해 서비스를 지속하기 어려운 경우에는 서비스를 중단할 수
              있습니다. 이 경우 중단일자 30일 이전까지 중단일자 및 사유를 앱
              초기화면 또는 그 연결화면, 회사 홈페이지 등 기타 서비스 제공
              화면을 통해 공지하고 제18조 제1항 또는 제2항의 방법으로 회원에게
              통지합니다.
            </p>
          </>
        )}

        {renderSection(
          'advertising',
          '제11조 (광고의 제공)',
          <>
            <p>
              ➀ 회사는 서비스의 운영과 관련하여 서비스 내에 광고를 게재할 수
              있습니다. 또한 수신에 동의한 회원에 한하여 이메일, 푸시메시지(Push
              Notification)등의 방법으로 광고성 정보를 전송할 수 있습니다. 이
              경우 회원은 언제든지 수신을 거절할 수 있으며, 회사는 회원의 수신
              거절 시 광고성 정보를 발송하지 아니합니다.
            </p>
            <p>
              ➁ 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이
              제공하는 광고나 서비스에 연결될 수 있습니다.
            </p>
            <p>
              ➂ 제2항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우 해당
              영역에서 제공하는 서비스는 회사의 서비스 영역이 아니므로 회사가
              신뢰성, 안정성 등을 보장하지 않으며, 그로 인한 회원의 손해에
              대하여도 회사는 책임을 지지 않습니다.
            </p>
          </>
        )}

        {renderSection(
          'copyright',
          '제12조 (저작권 등의 귀속 등)',
          <>
            <p>
              ➀ 회사가 제작한 일체의 콘텐츠에 대한 저작권과 기타 지적재산권은
              회사에 귀속합니다.
            </p>
            <p>
              ➁ 회원은 회사가 제공하는 서비스를 이용하여 얻은 정보를 서비스 이용
              외의 목적으로 회사의 사전 동의 없이 복제, 전송, 편집, 공표, 공연,
              배포, 방송, 2차적 저작물 작성 등의 방법으로 이용하거나 타인에게
              이용하게 하여서는 안 됩니다.
            </p>
            <p>
              ➂ 회원이 타인의 저작권 등 지적 재산권을 침해하여 회사가
              타인으로부터 손해배상 청구 등 이의제기를 받는다면 회원은 회사의
              면책을 위하여 노력해야 하며, 회사가 면책되지 못할 때에는 회사에
              발생한 모든 손해를 부담해야 합니다.
            </p>
            <p>
              ⓸ 회사는 회원이 서비스 내에 게시한 게시물을 서비스 내 노출, 서비스
              홍보를 위한 활용, 서비스 운영, 개선 및 새로운 서비스 개발을 위한
              연구 목적을 위하여 저장, 복제, 수정, 공중 송신, 전시, 배포, 2차적
              저작물 작성의 방식으로 이용할 수 있습니다.
            </p>
            <p>
              ⓹ 회사는 회원이 게시하거나 등록하는 서비스 내의 게시물에 대해
              제7조 제1항 각 호에 규정된 금지행위 중 어느 하나에 해당한다고
              판단되는 경우에는 사전 통지 없이 이를 삭제 또는 이동하거나 그
              등록을 거절할 수 있습니다.
            </p>
            <p>
              ⑥ 회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이
              침해된 회원은 회사에 이메일(~~@arirang.com)로 해당 정보의 삭제
              또는 반박 내용의 게재를 요청할 수 있습니다. 이 경우 회사는 관련
              법령에 따라 필요한 조치를 취하고 이를 신청인에게 통지합니다.
            </p>
            <p>
              ⑦ 이 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도
              지속적으로 적용됩니다.
            </p>
          </>
        )}

        {renderSection(
          'serviceRestriction',
          '제13조 (회원에 대한 서비스 이용제한)',
          <>
            <p>
              ➀ 회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인
              운영을 방해한 경우 서비스 이용을 제한할 수 있습니다.
            </p>
            <p>
              ➁ 회사가 제1항에서 정한 이용제한 조치를 하는 경우에는 다음 각 호의
              사항을 회원에게 통지합니다.
            </p>
            <ul>
              <li>이용제한 조치의 사유</li>
              <li>이용제한 조치의 유형 및 기간</li>
              <li>이용제한 조치에 대한 이의신청 방법</li>
            </ul>
            <p>
              ➂ 회사는 다음 각 호의 사유에 대한 조사가 완료될 때까지 해당 계정의
              서비스 이용을 정지할 수 있습니다.
            </p>
            <ul>
              <li>
                계정이 해킹 또는 도용당했거나, 범죄에 이용되었다는 정당한 신고가
                접수된 경우
              </li>
              <li>
                그 밖에 이에 준하는 사유로 서비스 이용의 잠정조치가 필요한 경우
              </li>
            </ul>
          </>
        )}

        {renderSection(
          'objectionProcedure',
          '제14조 (이용제한 조치에 대한 이의신청 절차)',
          <>
            <p>
              ➀ 회원이 회사의 이용제한 조치에 불복하고자 할 때에는 이 조치의
              통지를 받은 날부터 14일 이내에 불복 이유를 기재한 이의 신청서를
              서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.
            </p>
            <p>
              ➁ 회사는 제1항의 이의신청서를 접수한 날부터 30일 이내에 불복
              이유에 대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변합니다.
              다만 회사는 이 기간 내에 답변이 어려운 경우에는 그 사유와
              처리일정을 통지합니다.
            </p>
            <p>
              ➂ 회사는 불복 이유가 타당한 경우에는 이에 따른 조치를 취합니다.
            </p>
          </>
        )}

        {renderSection(
          'contractTermination',
          '제15조 (계약 해지 등)',
          <>
            <p>
              ➀ 회원은 언제든지 서비스 이용을 원하지 않는 경우 회원 탈퇴를 통해
              이용계약을 해지할 수 있습니다.
            </p>
            <p>
              ➁ 회사는 회원이 이 약관에서 금지하는 행위를 하거나 법령을 위반하는
              등 본 계약을 유지할 수 없는 중대한 사유가 있는 경우에는 서비스
              이용을 중지하거나 이용계약을 해지할 수 있습니다.
            </p>
          </>
        )}

        {renderSection(
          'compensation',
          '제16조 (손해배상)',
          <>
            <p>
              ➀ 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌
              경우에는 그 손해를 배상할 책임이 있습니다. 다만 고의 또는 과실이
              없는 경우에는 그러하지 아니합니다.
            </p>
            <p>
              ➁ 회사가 개별서비스 제공자와 제휴 계약을 맺고 회원에게
              개별서비스를 제공하는 경우에 회원이 해당 개별서비스 이용약관에
              동의를 한 뒤 개별서비스 제공자의 고의 또는 과실로 인해 회원에게
              손해가 발생한 경우에 그 손해에 대해서는 개별서비스 제공자가 책임을
              집니다. 단 회사에게 고의 또는 과실이 있는 경우는 그러하지
              아니합니다.
            </p>
          </>
        )}

        {renderSection(
          'disclaimer',
          '제17조 (회사의 면책)',
          <>
            <p>
              ➀ 회사는 제9조 제2항의 사유에 의하여 서비스를 제공할 수 없는
              경우에는 서비스 제공에 관하여 책임을 지지 않습니다.
            </p>
            <p>
              ➁ 회사는 아래 각 호의 사유로 인하여 회원에게 발생한 손해에 대하여
              책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는
              그러하지 아니합니다.
            </p>
            <ul>
              <li>회원의 고의 또는 과실로 인한 서비스 이용의 장애</li>
              <li>다른 회원이 게시한 게시글에 신뢰성, 정확성이 부족한 경우</li>
              <li>
                회원간 또는 회원과 타인간 서비스를 매개로 발생한 거래나 분쟁
              </li>
              <li>무료로 제공되는 서비스 이용</li>
              <li>
                회원이 서비스 이용을 통하여 기대하는 유익이 실현되지 않은 경우
              </li>
              <li>
                회원이 계정 비밀번호, 모바일 기기 비밀번호 등을 관리하지 않아
                회원정보의 유출이 발생한 경우
              </li>
              <li>
                회원이 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS)
                버전의 변경, 해외 로밍, 통신사 변경 등으로 인해 콘텐츠 전부나
                일부의 기능을 이용할 수 없는 경우
              </li>
              <li>회원이 회사가 제공하는 콘텐츠나 계정정보를 삭제한 경우</li>
              <li>
                서버에 대한 제3자의 불법적인 접속 또는 서버의 불법적인 이용 및
                프로그램을 이용한 비정상적인 접속 방해
              </li>
              <li>
                기타 회사의 귀책사유가 인정되지 않는 사유로써 각 호에 준하는
                사유
              </li>
            </ul>
            <p>
              ➂ 회원이 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도
              또는 정확성에 대하여는 해당 회원이 책임을 지지 않습니다.
            </p>
            <p>
              ⓸ 회사는 앱 또는 서비스 이용과 관련하여 회원의 고의 또는 과실로
              인하여 회원 자신 또는 제3자에게 발생한 손해에 대하여는 아무런
              책임을 부담하지 않습니다.
            </p>
            <p>
              ⓹ 회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써,
              발생하는 피해에 대해서 회사는 일체의 책임을 지지 않습니다.
            </p>
            <p>
              ⑥ 회사는 회원이 서비스를 이용함으로써 기대되는 결과를 얻지
              못하거나 서비스를 통해 얻은 자료를 이용하여 발생한 손해에 대해서는
              책임을 부담하지 않습니다. 다만, 회사의 고의 또는 과실에 의한
              경우에는 그러하지 아니합니다.
            </p>
            <p>
              ⑦ 회사는 이용자가 의사와의 상담, 진단, 처방, 지시, 조언 등 없이
              서비스를 이용하거나 이에 부합하지 않는 방법으로 서비스를 이용할
              경우 발생하는 질병 또는 건강의 악화에 대하여 책임을 지지 않습니다.
            </p>
          </>
        )}

        {renderSection(
          'notifications',
          '제18조 (회원에 대한 통지)',
          <>
            <p>
              ➀ 회사가 회원에게 통지를 하는 경우 회원의 이메일주소, 푸쉬알람
              등으로 할 수 있습니다.
            </p>
            <p>
              ➁ 회사는 회원 전체에게 통지를 하는 경우 7일 이상 앱 또는 회사
              홈페이지 내에 게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에
              갈음할 수 있습니다.
            </p>
          </>
        )}

        {renderSection(
          'jurisdiction',
          '제19조 (재판권 및 준거법)',
          <>
            <p>
              ➀ 회사가 회원에게 통지를 하는 경우 회원의 이메일 주소, 푸쉬알람
              등으로 할 수 있습니다.
            </p>
            <p>
              ➁ 회사는 회원 전체에게 통지를 하는 경우 7일 이상 앱 또는 회사
              홈페이지 내에 게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에
              갈음할 수 있습니다.
            </p>
          </>
        )}

        {renderSection(
          'disputeResolution',
          '제20조 (회원의 고충처리 및 분쟁해결)',
          <>
            <p>
              ➀ 이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 회원
              간에 발생한 분쟁으로 소송이 제기되는 경우에는 민사소송법에 따라
              관할권을 가지는 법원을 관할 법원으로 합니다.
            </p>
            <p>
              ➁ 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고
              객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게
              처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게
              장기간이 소요되는 사유와 처리일정을 서비스 내 공지하거나 제4조
              제1항에 따라 통지합니다.
            </p>
          </>
        )}

        {renderSection(
          'appendix',
          '부칙',
          <p>이 약관은 2024년 00월 00일부터 시행합니다.</p>
        )}
      </div>
    </StyledTermsOfService>
  );
};

export default TermsOfService;

const StyledTermsOfService = styled.div`
  font-family: 'Pretendard', sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h1 {
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .section {
    margin-bottom: 15px;
    background-color: #ffffff;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .section-title {
    cursor: pointer;
    color: #2980b9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    //background-color: #e9f2f9;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease;

    //&:hover {
    //  background-color: #d4e6f1;
    //}
  }

  .toggle-icon {
    font-size: 0.8em;
    transition: transform 0.3s ease;
  }

  .section-content {
    padding: 15px;
    background-color: #ffffff;
    font-size: 14px;
  }

  ul,
  ol {
    padding-left: 20px;
    margin-bottom: 10px;
  }

  li {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 10px;
  }
`;
