import React, { useState } from 'react';
import { PageContainer } from '../../styles/globalStyled/PageContainer';
import { Box, Tooltip, Typography } from '@mui/material';
import { Headphone } from 'component/new/icons';
import EmotionCard from './_components/EmotionCard';
import Button from 'component/new/Button/Button';
import { useNavigate } from 'react-router-dom';
import apiManager from 'lib/api/apiManager';
import { useUser } from '../../hooks/auth/useUser';
import { getSearchPram } from '../../utils/getSearchPram';

const REVIEW_QUESTION = '사운드는 어떠셨나요?';
const REVIEW_ANSWERS = {
  good: '잠에 도움되었어요',
  bad: '잘 모르겠어요',
} as const;

const SoundReviewPage = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const sessionTime = getSearchPram('time');
  const [selectedReview, setSelectedReview] = useState<'good' | 'bad'>();

  const submitReview = async (type: 'good' | 'bad') => {
    await apiManager.insertSessionReview({
      userId: user.user_id || '',
      session_id: user.session_id || '',
      review_question: REVIEW_QUESTION,
      review_answer: REVIEW_ANSWERS[type],
    });
  };

  const handleReviewChange = async (type: 'good' | 'bad') => {
    setSelectedReview(type);
    await submitReview(type);
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.semantic.background.deem,
        width: '100%',
        height: '100%',
      }}
    >
      <PageContainer>
        <Box
          sx={{
            mt: '48px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Headphone />
          <Typography mt="29px" variant="title2" color="#fff">
            {REVIEW_QUESTION}
          </Typography>

          <Typography
            mt="29px"
            variant="caption1"
            color={(theme) => theme.palette.semantic.font.textSub}
            whiteSpace="pre-line"
            textAlign="center"
          >
            {
              '사운드에 대해 의견을 남겨주시면\n 더 나은 수면을 도와드리는 데에 큰 힘이 돼요'
            }
          </Typography>
        </Box>

        <Box mt="48px" display="flex" gap="16px" justifyContent="center">
          <EmotionCard
            key="good"
            type="good"
            selected={selectedReview === 'good'}
            onChange={handleReviewChange}
          />
          <EmotionCard
            key="bad"
            type="bad"
            selected={selectedReview === 'bad'}
            onChange={handleReviewChange}
          />
        </Box>

        <Box mt="auto" display="flex" flexDirection="column" gap="16px">
          {sessionTime === '5' || sessionTime === '10' ? null : (
            <Tooltip
              title="지난밤을 기록하면 더 나은 통계를 제공해 드릴 수 있어요"
              placement="top"
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={!!selectedReview}
            >
              <span>
                <Button
                  buttonSize="large"
                  buttonStyle="secondary"
                  disabled={!selectedReview}
                  onClick={() => navigate('/post-survey', { replace: true })}
                >
                  수면의 질 기록하기
                </Button>
              </span>
            </Tooltip>
          )}
          <Button
            buttonSize="large"
            disabled={!selectedReview}
            onClick={() => navigate('/', { replace: true })}
          >
            종료하기
          </Button>
        </Box>
      </PageContainer>
    </Box>
  );
};

export default SoundReviewPage;
