import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Checked } from 'component/new/icons';
import { baseColors } from '../../../styles/theme';

const ItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '14px 16px',
  borderRadius: '8px',
  backgroundColor: theme.palette.semantic.theme.containerOnLight,
  marginBottom: '8px',
  cursor: 'pointer',
}));

const RadioCircle = styled(Box)<{ isSelected: boolean }>(
  ({ isSelected, theme }) => ({
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    border: '0.692px solid #999FA3',
    marginRight: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isSelected ? '#89B7C6' : 'transparent',
  })
);

type RadioItem = {
  id: string;
  name: string;
  value: string | number;
};

type RadioSelectProps = {
  items: RadioItem[];
  onChange: (value: string | number) => void;
  value: string | number | null;
};

const RadioSelect = ({ items, onChange, value }: RadioSelectProps) => {
  return (
    <Box>
      {items.map((item) => (
        <ItemContainer key={item.id} onClick={() => onChange(item.value)}>
          <RadioCircle isSelected={item.value === value}>
            {item.value === value && (
              <Checked color={baseColors.neutral.bw.white} />
            )}
          </RadioCircle>
          <Typography variant="body" color="#374553">
            {item.name}
          </Typography>
        </ItemContainer>
      ))}
    </Box>
  );
};

export default RadioSelect;
