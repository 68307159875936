import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './context/AuthProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from 'styles/theme';
import PageRouter from 'component/CustomRouter/PageRouter';
import './global.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AuthProvider>
          <PageRouter />
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
