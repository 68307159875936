import React from 'react';
import { Box, Typography } from '@mui/material';
import { PageContainer } from '../../../styles/globalStyled/PageContainer';
import Button from 'component/new/Button/Button';
import { MoonStarReverse } from 'component/new/icons';
import { useNavigate } from 'react-router-dom';

const TutorialDonePage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.semantic.background.deem,
        width: '100%',
        height: '100%',
      }}
    >
      <PageContainer
        sx={{
          display: 'grid',
          gridTemplateRows: '1fr auto auto',
          alignItems: 'center',
        }}
      >
        <Box
          gridRow="1/2"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <MoonStarReverse />
          <Typography
            mt="15px"
            variant="title2"
            color="#fff"
            textAlign="center"
          >
            튜토리얼이 종료되었어요
          </Typography>
          <Typography
            mt="16px"
            variant="body"
            textAlign="center"
            color={(theme) => theme.palette.semantic.font.textSub}
            whiteSpace="pre-line"
          >
            {'뮤지토닌과 함께 깊고 편안한 수면을\n 시작할 준비가 되었어요'}
          </Typography>
        </Box>

        <Box gridRow="2/3">
          <Button buttonSize="large" onClick={() => navigate('/')}>
            확인
          </Button>
        </Box>
      </PageContainer>
    </Box>
  );
};

export default TutorialDonePage;
